import { FC, useState, useMemo } from "react";
import { isEmpty } from "lodash";
import { Formik, FormikHelpers } from "formik";
import { FormikSelectField, Modal, toast, types } from "@vilocnv/allsetra-core";
import { ModalProps, Stack, useTheme } from "@mui/material";

// Data
import { IRideBulkUpdateMode } from "app/data/types";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getRidesBulkUpdateModeInitialValues,
  OBJECT_RIDE_MODES_OPTIONS,
  ridesBulkUpdateModeValidationSchema
} from "app/data/helpers";
import { updateRideBulkUploadModeThunk } from "app/features";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

type Props = Omit<ModalProps, "title" | "children"> & {
  rides: string[];
  afterSubmitActionHandler: () => void;
};

const BulkRidesUpdateOrderForm: FC<Props> = ({
  open,
  onClose,
  rides,
  afterSubmitActionHandler
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(selectDrawerSelectedAccountId);

  const [customIsSubmitting, setCustomIsSubmitting] = useState<boolean>(false);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const initialValues = useMemo(
    () => (!isEmpty(rides) ? getRidesBulkUpdateModeInitialValues(rides) : {}),
    [rides]
  );

  const rideModes = useMemo(() => OBJECT_RIDE_MODES_OPTIONS(t), [t]);

  const onFulfilledRequest = (
    formikHelpers: FormikHelpers<IRideBulkUpdateMode>
  ) => {
    formikHelpers.setSubmitting(false);
    setCustomIsSubmitting(false);

    toast.success("Bulk update for the object type has been completed.");

    if (afterSubmitActionHandler) afterSubmitActionHandler();

    // @ts-ignore
    onClose();
  };

  const saveChangesHandler = async (
    values: IRideBulkUpdateMode,
    formikHelpers: FormikHelpers<IRideBulkUpdateMode>
  ) => {
    formikHelpers.setSubmitting(true);
    setCustomIsSubmitting(true);

    const { type } = await dispatch(
      updateRideBulkUploadModeThunk({
        accountId: accountId ?? "",
        data: { ...values, rideMode: Number(values.rideMode) }
      })
    );

    if (type === "objects/updateRideBulkUploadModeThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onFulfilledRequest(formikHelpers);
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.RideModeBulkUpdatedEvent
        ) {
          onFulfilledRequest(formikHelpers);

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setCustomIsSubmitting(false);
    }
  };

  return (
    <Formik
      // @ts-ignore
      initialValues={initialValues}
      validationSchema={ridesBulkUpdateModeValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      {({ resetForm, isValid, isSubmitting, handleSubmit }) => (
        <Modal
          open={open}
          onClose={() => {
            // @ts-ignore
            onClose();
            resetForm();
          }}
          title={t("common.changeRideType")}
          primaryBtnProps={{
            type: "submit",
            text: t("buttonsText.saveChanges"),
            loading: isSubmitting || customIsSubmitting,
            disabled: !isValid,
            // @ts-ignore
            onClick: handleSubmit
          }}
          secondaryBtnProps={{
            text: t("buttonsText.cancel"),
            onClick: () => {
              // @ts-ignore
              onClose();
              resetForm();
            }
          }}
          theme={theme}
        >
          <Stack spacing={2}>
            <FormikSelectField
              theme={theme}
              label={t("rideType.label", { ns: "formFieldsTranslation" })}
              placeholder={t("rideType.placeholder", {
                ns: "formFieldsTranslation"
              })}
              name="rideMode"
              options={rideModes ?? []}
              optionLabelKey={"label"}
              optionValueKey={"value"}
              required
            />
          </Stack>
        </Modal>
      )}
    </Formik>
  );
};

export default BulkRidesUpdateOrderForm;
