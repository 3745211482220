import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { types, Badge, KeyValueTable, utils } from "@vilocnv/allsetra-core";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditIcon from "@mui/icons-material/Edit";
import UpdateObjectRideForm from "components/forms/objects/UpdateObjectRideForm/UpdateObjectRideForm";
import LocationTimeline from "components/ui/LocationTimeline/LocationTimeline";
import {
  SpeedIcon,
  Clock,
  LocationIcon,
  MileageIcon,
  SpeedIconRed
} from "assets/icons";
import {
  IconBox,
  RecordKey,
  RecordValue,
  RecordsWrapper,
  RideBox,
  RideMessage,
  RidesContainer
} from "./ObjectRideDetailsDrawer.styled";
import { Warning } from "@mui/icons-material";
// Data
import moment from "moment";
import { round } from "lodash";
import {
  transformObjectRideLocationsForTimeline,
  OBJECT_RIDE_MODES,
  OBJECT_RIDE_MODES_COLOR,
  getObjectRideTotalTimeInMinutes
} from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

interface Props {
  showDrawer?: boolean;
  objectRide: types.IObjectRide | null;
  objectRideLocations: Array<types.IObjectRideLocation>;
  onLocationItemClick: (data: types.IObjectRideLocation) => void;
}

const ObjectRideDetailsDrawer: FC<Props> = ({
  objectRide,
  objectRideLocations,
  onLocationItemClick
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation"
  ]);

  const [openUpdateRide, setOpenUpdateRide] = useState<boolean>(false);

  const timeLineItems = useMemo(() => {
    const items = transformObjectRideLocationsForTimeline(
      objectRideLocations,
      objectRide
    );
    return items.length > 2 ? [items[0], items[items.length - 1]] : items;
  }, [objectRideLocations, objectRide]);

  const getParsedSpeed = (speed: any) => `${parseFloat(speed.toFixed(2))} km/h`;

  return (
    <RidesContainer>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant={"h6"}>
            {objectRide
              ? // @ts-ignore
                `${t("common.ride")} № ${objectRide?.id}`
              : "Ride not found"}
          </Typography>
        </Stack>
        {objectRide && (
          <IconButton onClick={() => setOpenUpdateRide(true)}>
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      <hr />
      {objectRide && (
        <Box mx={1}>
          <Stack mt={2} direction={"row"} justifyContent={"space-between"}>
            <Stack spacing={2}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <IconBox>
                  <LocationIcon />
                </IconBox>
                <Typography fontSize={16} variant={"h6"}>
                  {`${utils.formatDate({ date: objectRide?.startDate })}, ${utils.formatDate(
                    {
                      date: objectRide?.startDate,
                      dateFormat: "HH:mm"
                    }
                  )} - ${utils.formatDate({
                    date: objectRide?.endDate,
                    dateFormat: "HH:mm"
                  })}`}
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              {objectRide && (
                <Badge
                  colorScheme={
                    objectRide.status === 1
                      ? "purple"
                      : OBJECT_RIDE_MODES_COLOR[objectRide?.rideMode] || "error"
                  }
                >
                  <div>
                    {objectRide.status === 1
                      ? t("typography.rideInProgress")
                      : OBJECT_RIDE_MODES(t)[objectRide?.rideMode]}
                  </div>
                </Badge>
              )}
            </Stack>
          </Stack>
          <Stack mt={3}>
            <LocationTimeline timeLineItems={timeLineItems} />
          </Stack>
          <Stack spacing={2}>
            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"space-between"}
            >
              {objectRide.status !== 1 && (
                <Stack
                  flex={1}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant={"subtitle2"}>
                    {t("typography.average")}:
                  </Typography>
                  <Badge
                    colorScheme={"info"}
                    icon={<SpeedIcon />}
                    textVariant={"small"}
                  >
                    {round(objectRide?.averageSpeed || 0, 1)} {"km/h"}
                  </Badge>
                </Stack>
              )}
              <Stack
                flex={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant={"subtitle2"}>
                  {t("typography.distance")}:
                </Typography>
                <Badge
                  colorScheme={"info"}
                  icon={<MileageIcon />}
                  textVariant={"small"}
                >
                  {round(objectRide?.mileage || 0, 1)} {"km"}
                </Badge>
              </Stack>
            </Stack>

            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Stack
                flex={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant={"subtitle2"}>
                  {t("typography.duration")}:
                </Typography>
                <Badge
                  colorScheme={"info"}
                  icon={<Clock />}
                  textVariant={"small"}
                >
                  {objectRide
                    ? getObjectRideTotalTimeInMinutes(objectRide)
                    : "0"}{" "}
                  {"min"}
                </Badge>
              </Stack>
              {objectRide.status !== 1 && (
                <Stack
                  flex={1}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {objectRide?.maxSpeed && (
                    <>
                      <Typography variant={"subtitle2"}>
                        {t("typography.max")}:
                      </Typography>
                      <Badge
                        colorScheme={"error"}
                        icon={<SpeedIconRed />}
                        textVariant={"small"}
                      >
                        {round(objectRide?.maxSpeed || 0, 1)} {"km/h"}
                      </Badge>
                    </>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
          {/* @ts-ignore */}
          {!objectRide.isMigrated ? (
            <Box mt={5} mr={2}>
              <KeyValueTable title={t("typography.details")} records={{}} />
              {/* mapping data bcz of same dates override issue in object */}
              {objectRideLocations?.map((item, index) => (
                <RecordsWrapper
                  onClick={() => onLocationItemClick(item)}
                  key={`${index}`}
                >
                  <RecordKey>
                    {DateTime.fromISO(item?.date).toFormat(
                      "dd MMM yyyy, HH:mm"
                    )}
                  </RecordKey>
                  {/* @ts-ignore */}
                  <RecordValue>{getParsedSpeed(item?.speed)}</RecordValue>
                </RecordsWrapper>
              ))}
            </Box>
          ) : (
            <RideBox mt={6} px={2}>
              <Warning style={{ color: "#E28E10" }} />
              <RideMessage ml={2}>{t("typography.migratedRide")}</RideMessage>
            </RideBox>
          )}
        </Box>
      )}
      {objectRide && (
        <UpdateObjectRideForm
          open={openUpdateRide}
          onClose={() => setOpenUpdateRide(false)}
          objectRide={objectRide}
        />
      )}
    </RidesContainer>
  );
};

export default ObjectRideDetailsDrawer;
