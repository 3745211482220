import { styled, Box } from "@mui/material";

export const CtaText = styled("p")(({ theme }) => ({
  color: "#76848F",
  textAlign: "start",
  fontSize: "14px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "116%",
  marginTop: "24px",

  "& span": {
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

export const CtaLoginText = styled("p")(({ theme }) => ({
  color: "#76848F",
  width: "150px",
  textAlign: "center",
  justifyContent: "center",
  fontSize: "14px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "116%",
  marginRight: "15px",

  [theme.breakpoints.down("lg")]: {
    width: "100%",
    textAlign: "start",
    justifyContent: "start",
    paddingTop: "4px"
  },

  "& button": {
    all: "unset",
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

export const ActivationCodeContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "32px",

  "& div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});
