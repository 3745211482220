import { Box, styled } from "@mui/material";
import { Field } from "formik";

export const Label = styled("label")({
  display: "flex",
  fontSize: "14px",
  fontWeight: 500,
  margin: "0px",
  flexDirection: "row",
  alignItems: "center",
  color: "#212B33"
});

export const Checkbox = styled(Field)({
  width: "16px",
  height: "16px",
  marginRight: "6px"
});

export const DaysTitle = styled("span")({
  marginBottom: "15px"
});

export const ButtonsRow = styled(Box)({
  display: "grid",
  gridTemplateColumns: "repeat(7, auto)"
});
