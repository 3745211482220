import fietsgestolenAxiosInstance from "app/fietsgestolenAxiosInstance";

class FietsgestolenService {
  // Fietsgestolen Endpoints
  static verifyEmail = async (data: any) => {
    return await fietsgestolenAxiosInstance.post("/verify-email", data);
  };

  static verifyBarcode = async (data: any) => {
    return await fietsgestolenAxiosInstance.post("/verify-barcode", data);
  };

  static registerFiets = async (data: any) => {
    return await fietsgestolenAxiosInstance.post("/register", data);
  };

  static listAllObjects = async (data: any) => {
    return await fietsgestolenAxiosInstance.post("/list-objects", data);
  };

  static registerTheft = async (data: any) => {
    return await fietsgestolenAxiosInstance.post("/register-theft", data);
  };

  static checkVerificationCode = async (data: any) => {
    return await fietsgestolenAxiosInstance.post(
      "/check-verification-code",
      data
    );
  };
}

export default FietsgestolenService;
