import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUserRolesThunk,
  getAllUsersThunk,
  getSpecificUserThunk,
  getUsersByQueryThunk,
  getUsersWithRoleDriverThunk
} from "./usersActions";

export interface IUsersState {
  loading: boolean;
  users: Array<any>;
  totalUsers: number | null;
  specificUserLoading: boolean;
  specificUser: object | null;
  usersWithRoleDriver: Array<any>;
  userRoles: Array<any>;
  userRolesLoading: boolean;
}

const initialState: IUsersState = {
  loading: false,
  users: [],
  totalUsers: null,
  specificUserLoading: false,
  specificUser: null,
  usersWithRoleDriver: [],
  userRoles: [],
  userRolesLoading: false
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetSpecificUser: (state) => {
      state.specificUser = null;
    }
  },
  extraReducers: (builder) => {
    // Get All Users Action Cases
    builder.addCase(getAllUsersThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllUsersThunk.fulfilled, (state, action) => {
      state.users = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllUsersThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Users By Querying Action Cases
    builder.addCase(getUsersByQueryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUsersByQueryThunk.fulfilled, (state, action) => {
      state.users = action.payload?.results || [];
      state.totalUsers = action.payload?.rowCount || 0;
      state.loading = false;
    });

    builder.addCase(getUsersByQueryThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specific User Action Cases
    builder.addCase(getSpecificUserThunk.pending, (state) => {
      state.specificUserLoading = true;
    });

    builder.addCase(getSpecificUserThunk.fulfilled, (state, action) => {
      state.specificUser = action.payload;
      state.specificUserLoading = false;
    });

    builder.addCase(getSpecificUserThunk.rejected, (state) => {
      state.specificUserLoading = false;
    });

    // Get Users With Role Driver Action Cases
    builder.addCase(getUsersWithRoleDriverThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUsersWithRoleDriverThunk.fulfilled, (state, action) => {
      state.usersWithRoleDriver = action.payload;
      state.loading = false;
    });

    builder.addCase(getUsersWithRoleDriverThunk.rejected, (state) => {
      state.loading = false;
    });

    //Get user roles cases
    builder.addCase(getAllUserRolesThunk.pending, (state) => {
      state.userRolesLoading = true;
    });

    builder.addCase(getAllUserRolesThunk.fulfilled, (state, action) => {
      state.userRoles = action.payload;
      state.userRolesLoading = false;
    });

    builder.addCase(getAllUserRolesThunk.rejected, (state) => {
      state.userRolesLoading = false;
    });
  }
});

export * from "./usersActions";
export const { resetSpecificUser } = usersSlice.actions;

export default usersSlice.reducer;
