import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import {
  FilterButton,
  SearchField,
  SelectInputField
} from "@vilocnv/allsetra-core";
import { SearchFieldWrapper, TopLeftSection } from "../../Map.styled";
import { FilterIcon } from "assets/icons";
import { useAppSelector } from "hooks";
import { selectDrawerSelectedAccountState } from "app/data/selectors";

interface Props {
  markers: any;
  showSearch?: boolean;
  setIsSelectOpen?: (value: boolean) => void;
  onObjectChange: (value: any) => void;
  showFilter?: boolean;
  onFilterClick?: () => void;
  showCustomSearchField?: boolean;
  isLoading?: boolean;
}

const MapLeftTop: FC<Props> = ({
  markers,
  showSearch,
  setIsSelectOpen,
  onObjectChange,
  showFilter,
  onFilterClick,
  showCustomSearchField = false,
  isLoading = false
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { drawerSelectedAccountId } = useAppSelector(
    selectDrawerSelectedAccountState
  );

  const [inputKey, setInputKey] = useState(0);

  useEffect(() => {
    setInputKey((prev) => prev + 1);
  }, [drawerSelectedAccountId]);

  return (
    <TopLeftSection>
      {showSearch && (
        <SearchFieldWrapper>
          {showCustomSearchField ? (
            <SearchField placeholder={"Search Objects"} isLoading={isLoading} />
          ) : (
            <SelectInputField
              // @ts-ignore
              id="searchbar-map"
              name="object"
              placeholder={t("common.search")}
              options={markers ?? []}
              optionLabelKey={"name"}
              optionValueKey={"uniqueId"}
              onChange={onObjectChange}
              searchable
              //@ts-ignore
              onOpen={() => setIsSelectOpen && setIsSelectOpen(true)}
              onClose={() => setIsSelectOpen && setIsSelectOpen(false)}
              key={inputKey}
            />
          )}
        </SearchFieldWrapper>
      )}
      {showFilter && (
        <FilterButton
          id="filter-button"
          theme={theme}
          onClick={onFilterClick}
          icon={<FilterIcon color={theme.palette.primary.main} />}
        />
      )}
    </TopLeftSection>
  );
};

export default MapLeftTop;
