import { formatTimeWithDay } from "../reportCommonHelpers";
import { DateTime } from "luxon";
import { calculateCO2AndMilageTimeDiff } from "app/data/constants";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";

interface Group {
  uniqueId: string;
  objects: any[];
}

interface Values {
  group: string[];
  object: string[];
}

//Milage Registrations
export const reportsMilageRegistrationFormInitialValues = {
  objectType: [],
  group: [],
  object: [],
  key: [],
  daysOfWeek: ["0", "1", "2", "3", "4", "5", "6"],
  startTime: null,
  endTime: null
};

export const calculateTimePeriodDates = (report: any, index: number) => {
  const innerItems = report[index].items;
  const periodStartDate = DateTime.fromISO(report[index].items[0].startDate);
  const periodEndDate = DateTime.fromISO(
    report[index].items[innerItems.length - 1].endDate
  );

  const formattedStartDate = periodStartDate.toFormat("dd/MM/yyyy");
  const formattedEndDate = periodEndDate.toFormat("dd/MM/yyyy");

  return { formattedStartDate, formattedEndDate };
};

export const calculateTotalKilometersByMode = (
  rides: any,
  rideMode: number
) => {
  return rides
    .filter((ride: any) => ride.rideMode === rideMode)
    .reduce((totalKilometers: number, ride: any) => {
      return totalKilometers + ride.kilometersDriven;
    }, 0);
};

const extractRows = (items: any) => {
  return items.map((entry: any) => {
    const endTime = formatTimeWithDay(entry.endDate);
    const startTime = formatTimeWithDay(entry.startDate);

    return [
      entry?.rideId || "-",
      moment(entry?.startDate).format("DD/MM/YY HH:mm:ss") || "-",
      moment(entry?.endDate).format("DD/MM/YY HH:mm:ss") || "-",
      Math.round(entry?.startMileage) + " km" || "-",
      Math.round(entry?.endMileage) + " km" || "-",
      entry?.startAddress || "-",
      entry?.endAddress || "-",
      entry?.key?.label || "-",
      renderRideMode(entry?.rideMode) || "-",
      Math.round(entry?.kilometersDriven) + " km" || "-",
      endTime !== "Invalid DateTime"
        ? calculateCO2AndMilageTimeDiff(endTime, startTime)
        : "Ongoing",
      entry.comments || "-"
    ];
  });
};

export const renderRideMode = (rideId: number) => {
  switch (rideId) {
    case 1:
      return "Business Ride";
    case 2:
      return "Private Ride";
    case 3:
      return "Commuting Ride";
    default:
      return "None";
  }
};

export const generateFormattedMilageRefPdf = (
  doc: jsPDF,
  report: any,
  columns: string[]
) => {
  report.forEach((object: any, index: number) => {
    const { formattedStartDate, formattedEndDate } = calculateTimePeriodDates(
      report,
      index
    );
    const itemsLength = object.items;

    const totalKms = object.items.reduce(
      (totalKilometers: number, ride: any) => {
        return totalKilometers + ride.kilometersDriven;
      },
      0
    );

    const totalKilometersMode1 = calculateTotalKilometersByMode(
      object.items,
      1
    );
    const totalKilometersMode2 = calculateTotalKilometersByMode(
      object.items,
      2
    );
    const totalKilometersMode3 = calculateTotalKilometersByMode(
      object.items,
      3
    );

    if (index > 0) {
      doc.addPage();
    }

    doc.setFontSize(10);
    doc.text(`Object Name: ${object.name}`, 10, 20);
    doc.text(`Period: ${formattedStartDate} - ${formattedEndDate}`, 10, 25);
    doc.text(`A-Number: ${object.aNumber}`, 10, 30);
    doc.text(`Number of Trips: ${itemsLength.length}`, 10, 35);
    doc.text(`Total: ${Math.round(totalKms)} km`, 10, 45);
    doc.text(`Business: ${Math.round(totalKilometersMode1)} km`, 10, 50);
    doc.text(`Private: ${Math.round(totalKilometersMode2)} km`, 10, 55);
    doc.text(`Commute: ${Math.round(totalKilometersMode3)} km`, 10, 60);

    autoTable(doc, {
      head: [columns],
      body: extractRows(object?.items),
      startY: 70
    });
  });
};

export const filterObjectsByGroup = (
  values: Values,
  objects: any[],
  groups: Group[]
): any[] => {
  if (values?.group?.length === 0) return objects;

  const filteredGroups = groups.filter((group) =>
    values.group.includes(group.uniqueId)
  );

  const filteredObjects = filteredGroups.map((group) => group.objects);

  const flattenedObjects = filteredObjects.flat();

  return flattenedObjects;
};

export function removeDuplicateObjects(array: Array<any>) {
  const seen = new Set();
  return array.filter((item: { uniqueId: string }) => {
    if (seen.has(item.uniqueId)) {
      return false;
    } else {
      seen.add(item.uniqueId);
      return true;
    }
  });
}

export const daysOfWeekOptions: any[] = [
  { label: "Mon", value: "1" },
  { label: "Tue", value: "2" },
  { label: "Wed", value: "3" },
  { label: "Thu", value: "4" },
  { label: "Fri", value: "5" },
  { label: "Sat", value: "6" },
  { label: "Sun", value: "0" }
];
