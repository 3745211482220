import React, { FC, useMemo, useState } from "react";
import { OverlayView, OverlayViewF } from "@react-google-maps/api";
import { MarkerData } from "./types";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  ClusterHeading,
  ClusterListing
} from "components/maps/common/MarkerClusterer/MarkerClusterer.styled";
import { Grid, IconButton, Typography } from "@mui/material";
import ObjectList from "components/maps/common/MarkerClusterer/ObjectList";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

interface MarkerClustererInfoWindowProps {
  markers: MarkerData[];
  cluster: any;
  onClose?: () => void;
}

const MarkerClustererInfoWindow: FC<MarkerClustererInfoWindowProps> = ({
  markers,
  cluster,
  onClose
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslation();
  const objectsPerPage = 7;

  const clusterObjects = useMemo(() => {
    const clickedMarkers = cluster.getMarkers();
    const _clusterObjects: any = [];
    clickedMarkers.map((item: any) => {
      const foundObject = markers.find(
        (marker) =>
          marker.lat === item.position.lat() &&
          marker.lng === item.position.lng()
      );
      _clusterObjects.push(foundObject);
    });
    return _clusterObjects;
  }, [cluster]);

  const totalPages = Math.ceil(clusterObjects.length / objectsPerPage);

  const paginatedObjects = clusterObjects.slice(
    (currentPage - 1) * objectsPerPage,
    currentPage * objectsPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <OverlayViewF
      position={cluster.getCenter()}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={() => ({ x: 20, y: -33 })}
    >
      <ClusterListing>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs={4}>
            <ClusterHeading>
              {`${t("drawerMenuLinks.objects")} (${clusterObjects?.length || 0})`}
            </ClusterHeading>
          </Grid>
          <Grid mr={1} item xs={1}>
            <IconButton onClick={() => onClose && onClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <ObjectList paginatedObjects={paginatedObjects} />

        <Grid container alignItems={"center"} justifyContent={"flex-end"}>
          <Grid m={0.5} item>
            <IconButton disabled={currentPage === 1} onClick={handlePrevPage}>
              <NavigateBefore />
            </IconButton>
          </Grid>
          <Grid>
            <Typography variant="body2">
              {currentPage} {t("typography.of")} {totalPages}
            </Typography>
          </Grid>
          <Grid m={0.5} item>
            <IconButton
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <NavigateNext />
            </IconButton>
          </Grid>
        </Grid>
      </ClusterListing>
    </OverlayViewF>
  );
};

export default React.memo(MarkerClustererInfoWindow);
