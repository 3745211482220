import React from "react";

interface IconProps {
  size?: number; // Size in pixels
}

export const EndLocation: React.FC<IconProps> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#CC1010" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#D5D9E8" />
      <path
        d="M17.5 7.45438V16.5456C17.4997 16.7986 17.399 17.0412 17.2201 17.2201C17.0412 17.399 16.7986 17.4997 16.5456 17.5H7.45438C7.20136 17.4997 6.9588 17.399 6.7799 17.2201C6.60099 17.0412 6.50033 16.7986 6.5 16.5456V7.45438C6.50033 7.20136 6.60099 6.9588 6.7799 6.7799C6.9588 6.60099 7.20136 6.50033 7.45438 6.5H16.5456C16.7986 6.50033 17.0412 6.60099 17.2201 6.7799C17.399 6.9588 17.4997 7.20136 17.5 7.45438Z"
        fill="#F9FBFF"
      />
    </svg>
  );
};
