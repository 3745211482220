import axiosInstance from "app/axiosInstance";

class PointsOfInterest {
  static getAllPointsOfInterest = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/pointsofinterest`);
  };

  static createPointOfInterest = async (accountId: string, data: any) => {
    return await axiosInstance.post(
      `/accounts/${accountId}/pointsofinterest`,
      data
    );
  };

  static updatePointOfInterest = async (accountId: string, data: any) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/pointsofinterest/${data.uniqueId}`,
      data
    );
  };

  static deletePointOfInterest = async (accountId: string, itemId: string) => {
    return await axiosInstance.delete(
      `/accounts/${accountId}/pointsofinterest/${itemId}`
    );
  };
}

export default PointsOfInterest;
