import axiosInstance from "app/axiosInstance";

class ScheduleReportsService {
  static createReportSchedule = async (accountId: string, data: any) => {
    return await axiosInstance.post(`/reports/${accountId}/schedules`, data);
  };

  static editReportSchedule = async (
    accountId: string,
    uniqueId: string,
    data: any
  ) => {
    return await axiosInstance.patch(
      `/reports/${accountId}/schedules/${uniqueId}`,
      data
    );
  };

  static getAllReportSchedule = async (accountId: string) => {
    return await axiosInstance.get(`/reports/${accountId}/schedules`);
  };

  static getSpecificReportSchedule = async (
    accountId: string,
    uniqueId: string
  ) => {
    return await axiosInstance.get(
      `/reports/${accountId}/schedules/${uniqueId}`
    );
  };

  static searchReportSchedule = async (accountId: string, data: any) => {
    return await axiosInstance.post(
      `/reports/${accountId}/schedules/search`,
      data
    );
  };

  static deleteReportSchedule = async (accountId: string, uniqueId: string) => {
    return await axiosInstance.delete(
      `/reports/${accountId}/schedules/${uniqueId}`
    );
  };

  static getReportFrequency = async () => {
    return await axiosInstance.get(`/frequency`);
  };

  static getReportExportTypes = async () => {
    return await axiosInstance.get(`/ReportExportType`);
  };
}

export default ScheduleReportsService;
