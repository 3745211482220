import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveStepIcon: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5V0.5L4.66667 4.30952L0 8.5Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M4.66667 8.5V0.5L9.33333 4.30952L4.66667 8.5Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M9.33333 8.5V0.5L14 4.30952L9.33333 8.5Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

export default ActiveStepIcon;
