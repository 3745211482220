import { Table } from "@vilocnv/allsetra-core";
import { TAG_POSITION_INNER_TABLE_COLUMNS } from "app/data/constants";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface TagPositionExpendableRowCardProps {
  data: any;
}

const TagPositionExpendableRowCard: FC<TagPositionExpendableRowCardProps> = ({
  data
}) => {
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  return (
    <Table
      data={data.records}
      columns={TAG_POSITION_INNER_TABLE_COLUMNS}
      noTableHead={true}
      pagination={false}
      translator={t}
    />
  );
};

export default TagPositionExpendableRowCard;
