import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { toast, types } from "@vilocnv/allsetra-core";
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getObjectsLocationsThunk,
  getObjectTypesByAccountThunk,
  getAllKeysByAccountThunk,
  getAllAccountGroupsThunk,
  resetAllObjects,
  setFilters,
  setActiveObjectId
} from "app/features";
import {
  selectAccountKeysState,
  selectDrawerSelectedAccountId,
  selectObjectTypesState,
  selectObjectsState,
  selectAccountGroups,
  selectMapFilterState,
  selectSpecificObjectState
} from "app/data/selectors";
import { useTranslation } from "react-i18next";

const useDashboardMap = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );
  const { objectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );
  const { accountGroups, loading: accountGroupsLoading } =
    useAppSelector(selectAccountGroups);
  const { allObjects, loading: allObjectsLoading } =
    useAppSelector(selectObjectsState);
  const { mapFilters } = useAppSelector(selectMapFilterState);

  // Local State
  const [filterOpen, setFilterOpen] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { activeObjectId } = useAppSelector(selectSpecificObjectState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const activeAccountKeys = useMemo(() => {
    return Array.isArray(accountKeys)
      ? accountKeys.filter((key) => key.isActive)
      : [];
  }, [accountKeys]);

  const mapFiltersSubmitHanlder = async (
    values: types.IMapFilter,
    formikHelpers: FormikHelpers<types.IMapFilter>
  ) => {
    if (!drawerSelectedAccountId) {
      toast.info("Kindly select an account from the navigation drawer.");
      return;
    }

    formikHelpers.setSubmitting(true);
    setSubmitting(true);

    const payload = {
      accountId: drawerSelectedAccountId ?? "",
      values: {
        ...values
      }
    };

    const { type } = await dispatch(getObjectsLocationsThunk(payload));

    if (type === "objects/getObjectsLocationsThunk/fulfilled") {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
      setFilterOpen(false);
      dispatch(setFilters(values));
    }
  };

  useEffect(() => {
    if (!isEmpty(drawerSelectedAccountId)) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId || ""));
      dispatch(getObjectTypesByAccountThunk(drawerSelectedAccountId || ""));
      dispatch(getAllAccountGroupsThunk(drawerSelectedAccountId || ""));
      // dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
      // dispatch(getAllObjectsThunk(drawerSelectedAccountId || ""));

      dispatch(
        getObjectsLocationsThunk({
          accountId: drawerSelectedAccountId || "",
          values: mapFilters
        })
      );
    } else {
      dispatch(resetAllObjects());
    }

    return () => {
      dispatch(setActiveObjectId(null));
      dispatch(resetAllObjects());
    };
  }, [drawerSelectedAccountId]);

  return {
    filterOpen,
    setFilterOpen,
    mapFiltersSubmitHanlder,
    accountGroups,
    objectTypes,
    activeAccountKeys,
    accountKeysLoading,
    objectTypesLoading,
    accountGroupsLoading,
    theme,
    mapFilters,
    t,
    submitting,
    allObjectsLoading,
    allObjects,
    activeObjectId
  };
};

export default useDashboardMap;
