import React, { FC } from "react";
import { OverlayView, OverlayViewF } from "@react-google-maps/api";
import { MarkerData } from "./types";
import { MarkerBlip, MarkerLabel } from "../../Map.styled";

interface MarkerTooltipProps {
  marker: MarkerData;
}

const MarkerTooltip: FC<MarkerTooltipProps> = ({ marker }) => {
  return (
    <OverlayViewF
      position={{ lat: marker.lat, lng: marker.lng }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={() => ({ x: 20, y: -33 })}
    >
      <>
        <MarkerLabel>{marker?.name || "N/A"}</MarkerLabel>
        <MarkerBlip></MarkerBlip>
      </>
    </OverlayViewF>
  );
};

export default React.memo(MarkerTooltip);
