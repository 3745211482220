import * as Yup from "yup";
import { types, Badge, helpers, utils } from "@vilocnv/allsetra-core";
import { isEmpty, omit } from "lodash";
import { DateTime } from "luxon";
import { sanitizeObjectsMetadataForDynamicFields } from "./objectsDynamicFieldsHelpers";

//
// OBJECT  PAGE HELPERS
//

export const getTimeDifference = (lastUpdated: string) => {
  const lastUpdatedDate = new Date(lastUpdated);
  const currentTime = new Date();

  const timeDifference = currentTime.getTime() - lastUpdatedDate.getTime();

  const seconds = Math.floor(timeDifference / 1000) % 60;
  const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
  const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) % 7;
  const weeks = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7)) % 4;
  const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));

  let result;

  if (months > 0) {
    result = `${months} month${months !== 1 ? "s" : ""}`;
  } else if (weeks > 0) {
    result = `${weeks} week${weeks !== 1 ? "s" : ""}`;
  } else if (days > 0) {
    result = `${days} day${days !== 1 ? "s" : ""}`;
  } else if (hours > 0) {
    result = `${hours} hour${hours !== 1 ? "s" : ""}`;
  } else if (minutes > 0) {
    result = `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  } else {
    result = `${seconds} second${seconds !== 1 ? "s" : ""}`;
  }

  return result;
};

//
// OBJECT DETAILS PAGE HELPERS
//

export const objectDetailsCardDateFormmater = (date: string) =>
  date
    ? utils.formatDate({
        date: DateTime.fromISO(date).setZone("local").toJSDate()
      })
    : "N/A";

export const objectDevicesCardDateFormmater = (date: string) =>
  date ? DateTime.fromISO(date).toFormat("yyyy-MM-dd HH:mm") : "N/A";

export const transformObjectForObjectInfoTable = (
  object: types.IObject | null
): any => {
  if (!object) return {};

  const data: any = {
    ID: object.aNumber
  };

  const metadata = sanitizeObjectsMetadataForDynamicFields(object);

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  metadata.length
    ? metadata.map(
        (meta: types.IObjectMetadata) => (data[meta.field?.label] = meta.value)
      )
    : (data["data"] = "No metadata available");

  return data;
};

const getTimeForWorkingHoursTable = (time: string) => {
  const hasSeconds = time.split(":").length === 3;

  const formatString = hasSeconds ? "HH:mm:ss" : "HH:mm";
  const parsedTime = DateTime.fromFormat(time, formatString);

  const formattedTime = parsedTime.toFormat("HH:mm");

  return formattedTime;
};

const getHoursForWorkingHoursTable = (data: any, workingHours: any) => {
  workingHours.map(
    (hours: any) =>
      (data[
        DateTime.fromObject({
          weekday: hours.dayOfWeek == 0 ? "7" : hours.dayOfWeek
        }).toFormat("cccc")
      ] =
        `${getTimeForWorkingHoursTable(hours.startTime)} - ${getTimeForWorkingHoursTable(hours.endTime)}`)
  );

  return data;
};

export const transformObjectForWorkingHoursTable = (
  object: any | null
): any => {
  if (!object) return {};

  const data: any = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  object?.isWorkingHoursOverriden
    ? getHoursForWorkingHoursTable(data, object.workingHours)
    : object?.alarmOwner?.workingHours?.length
      ? getHoursForWorkingHoursTable(data, object.alarmOwner?.workingHours)
      : (data["Hours"] = "No Hours available");

  const orderedData: Record<string, string> = {};
  const daysOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  daysOrder.forEach((day) => {
    if (data[day]) {
      orderedData[day] = data[day];
    }
  });

  return orderedData;
};

const getFormattedWorkingHoursForAssignedUsers = (user: any) => {
  let data = "";

  const daysOfWeekNames = user.workingHours.map((hours: any) =>
    DateTime.fromObject({
      weekday: hours.dayOfWeek == 0 ? "7" : hours.dayOfWeek
    }).toFormat("cccc")
  );

  const startTime = DateTime.fromFormat(
    user.workingHours[0].startTime,
    "HH:mm"
  ).toFormat("h a");

  const endTime = DateTime.fromFormat(
    user.workingHours[0].endTime,
    "HH:mm"
  ).toFormat("h a");

  data = `${daysOfWeekNames[0]}-${daysOfWeekNames[daysOfWeekNames.length - 1]}, ${startTime} - ${endTime}`;

  return data;
};

export const getHoursForAssignedUsers = (user: any | null): any => {
  if (!user) return ``;

  const data = user.workingHours?.length
    ? getFormattedWorkingHoursForAssignedUsers(user)
    : "No Hours available";

  return data;
};

export const transformObjectForCorrectionTable = (
  object: any | null,
  translator: any
): any => {
  if (!object) return {};

  return {
    [translator("newMileage.label", { ns: "formFieldsTranslation" })]: `-`,
    [translator("correctionDate.label", { ns: "formFieldsTranslation" })]: `-`
  };
};

export const transformObjectForReminderTable = (
  object: any | null,
  translator: any
): any => {
  if (!object) return {};

  return {
    [translator("sendRemindersFrom.label", {
      ns: "formFieldsTranslation"
    })]: `${object.remindersFrom ?? "-"} km`,
    [translator("reminderForEvery.label", { ns: "formFieldsTranslation" })]:
      `${object.remindersForEvery ?? "-"} km`,
    [translator("email.label", { ns: "formFieldsTranslation" })]:
      `${object.reminderEmail ?? "-"}`,
    [translator("name.label", { ns: "formFieldsTranslation" })]:
      `${object.reminderName ?? "-"}`
  };
};

const getBadgeForAlarmConfiguration = (enabled: boolean) => {
  return (
    <Badge colorScheme={enabled ? "success" : "error"}>
      {enabled ? "Enabled" : "Disabled"}
    </Badge>
  );
};

export const transformObjectForAlarmConfigTable = (object: any): any => {
  // @ts-ignore
  if (isEmpty(object?.effectiveConfiguration)) return {};

  const result: any = {};

  object?.effectiveConfiguration
    .filter((item: any) => item?.isSupported)
    .map((item: any) => {
      result[item?.alarmType?.name] = getBadgeForAlarmConfiguration(
        item?.isEnabled
      );
    });

  return result;
};

export const transformObjectMetaDataForDynamicFields = (
  object: types.IObject | null
): any => {
  if (!object) return {};
  const dynamicFields = object.metadata.filter(
    (item) => item.informationType === "OBJECT"
  );

  const data: any = {};

  dynamicFields.forEach((item: any) => {
    data[item.field.label] = item.value;
  });

  return data;
};

export const transformObjectOwnersDataForTable = (
  object: types.IObject | null
) => {
  return {
    "Alarm Owner": object && object.alarmOwner ? object.alarmOwner.name : "N/A",
    "Invoice Owner":
      object && object.invoiceOwner ? object.invoiceOwner.name : "N/A"
  };
};

//
// OBJECT SETTINGS PAGE HELPERS
//

export const flattenAccounts = (accounts: Array<any>) => {
  return accounts.reduce((acc, account) => {
    const children: any = account.children
      ? flattenAccounts(account.children)
      : [];
    return [...acc, account, ...children];
  }, []);
};

export const objectDetailsFormatterForSettingsForm = (object: any | null) => {
  if (isEmpty(object)) return {};

  const removedUnwantedKeys = omit(object, [
    "created",
    "createdBy",
    "deleted",
    "deletedBy",
    "installations",
    "isDeleted",
    "lastUpdated",
    "location",
    "status",
    "updatedBy",
    "objectType",
    "devices"
  ]);

  const formattedObject = {
    ...removedUnwantedKeys,
    licensePlate:
      object.metadata?.find((item: any) => item.field.label === "License Plate")
        ?.value ?? "",
    vin:
      object.metadata?.find(
        (item: any) => item.field.label === "VIN / Frame Number"
      )?.value ?? "",
    emission:
      object.metadata?.find((item: any) => item.field.label === "Emission")
        ?.value ?? [],
    peddle:
      object.metadata?.find((item: any) => item.field.label === "Peddle")
        ?.value ?? [],
    ecodrive:
      object.metadata?.find((item: any) => item.field.label === "Ecodrive")
        ?.value ?? "",
    maintenance:
      object.metadata?.find((item: any) => item.field.label === "Maintenance")
        ?.value ?? "",
    alarmOwner: object?.alarmOwner?.name ?? "",
    invoiceOwner: object?.invoiceOwner?.name ?? "",
    newMileage: "",
    correctionDate: "",
    accounts: object.accounts?.map((item: any) => item?.uniqueId),
    groups: object.groups?.map((item: any) => item.uniqueId),
    users: object.users?.map((item: any) => item.user.uniqueId),
    objectTypeId: object.objectType?.uniqueId || "",
    isWorkingHoursOverriden: object?.isWorkingHoursOverriden,
    workingHoursType: object?.workingHoursType,
    workingHours: {
      // @ts-ignore
      workingHoursSchedule: object?.isWorkingHoursOverriden
        ? object.workingHours
        : object.alarmOwner?.workingHours
    }
  };

  return formattedObject;
};

export const workingHoursFormatterForSettingsForm = (
  object: any | null,
  activeObject: any | null
) => {
  const formattedObject = {
    ...object,
    workingHoursType: object?.isWorkingHoursOverriden
      ? activeObject.workingHoursType
      : activeObject.alarmOwner?.workingHoursType,
    workingHours: {
      workingHoursSchedule: object?.isWorkingHoursOverriden
        ? activeObject.workingHours
        : activeObject.alarmOwner?.workingHours
    }
  };

  return formattedObject;
};

export const objectDetailsValidationSchema = Yup.object({
  // name: Yup.string().required().label("Object Name"),
  aNumber: Yup.string().nullable().label("A-Number"),
  licensePlate: Yup.string().nullable().label("License Plate"),
  mileage: Yup.number().nullable().label("Milage"),
  vin: Yup.string().nullable().label("Vin"),
  accounts: Yup.array().of(Yup.string()).label("Assigned accounts"),
  groups: Yup.array().of(Yup.string()).label("Groups"),
  alarmOwner: Yup.string().required().label("Alarm Owner"),
  invoiceOwner: Yup.string().required().label("Invoice Owner"),
  emission: Yup.array().of(Yup.string()).label("Emission"),
  peddle: Yup.array().of(Yup.string()).label("Emission"),
  ecodrive: Yup.string().nullable().label("A-Number"),
  maintenance: Yup.string().nullable().label("A-Number"),
  isWorkingHoursOverriden: Yup.boolean().label(
    "Custom working hours configuration"
  ),
  workingHoursType: Yup.string()
    .nullable()
    .required()
    .label("Working Hours Type"),
  workingHours: Yup.object({
    workingHoursSchedule: helpers.workingHoursValidationSchema
  }),
  newMileage: Yup.string().nullable().label("New milage"),
  correctionDate: Yup.string().nullable().label("Correction Date"),
  users: Yup.array().of(Yup.string()).label("Assigned users"),
  remindersFrom: Yup.number().nullable().label("Reminders From"),
  remindersForEvery: Yup.number().nullable().label("Reminders For Every"),
  reminderEmail: Yup.string().nullable().label("Reminder Email"),
  reminderName: Yup.string().nullable().label("Reminder Name"),
  notifications: helpers.notificationsConfigurationValidationSchema,
  metadata: Yup.array().nullable(),
  uniqueId: Yup.string()
});

export const objectMileageCorrectionSchema = Yup.object().shape({
  newMileage: Yup.number()
    .min(1, "Mileage cannot be negative")
    .required("Mileage is required"),
  correctionDate: Yup.string().required("Correction Date is required")
});

export const objectMileageCorrectionInitialValues = {
  newMileage: 0,
  correctionDate: ""
};

export const getRideAddress = (index: number, item: any, array: any[]) =>
  index === 0
    ? item?.startAddress
    : index + 1 === array.length
      ? item?.endAddress
      : item.resolvedAddress;

export const getSplittedAddress = (index: number, resolvedAddress: string) => {
  if (!resolvedAddress) return index === 0 ? "N/A" : "";

  const splittedAddress = resolvedAddress.split(",");
  return splittedAddress[index] !== undefined
    ? `${splittedAddress[index]}${index !== 2 ? ", " : ""}`
    : index === 0
      ? "N/A"
      : "";
};

export const getParsedMetaDataForSpeed = (value: any) => {
  return {
    metadata: [
      {
        field: {
          customerPortal: 4,
          label: "Speed",
          showTagLabel: true,
          valueUnit: "km/h",
          iconUrl:
            "https://westeuropeidhanddev.blob.core.windows.net/internal-files/icons/speed.png"
        },
        value: `${value}`
      }
    ]
  };
};
