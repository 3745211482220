import { isEmpty } from "lodash";
import React, { Fragment } from "react";

interface WhiteLabel {
  favIconUrl?: string;
}

interface FaviconManagerProps {
  whiteLabel: WhiteLabel | null;
}

const FaviconManager: React.FC<FaviconManagerProps> = ({ whiteLabel }) => {
  const version = "1.0.1";
  const faviconUrl = !isEmpty(whiteLabel)
    ? `${whiteLabel.favIconUrl}?v=${version}`
    : `/favicon.ico?v=${version}`;

  return (
    <Fragment>
      <link rel="icon" href="data:image/x-icon;base64,AA" />

      <link rel="icon" href={faviconUrl} type="image/x-icon" />

      <link
        rel="icon"
        type="image/svg+xml"
        href={whiteLabel?.favIconUrl || "/favicon.svg"}
      />

      <link
        rel="icon"
        type="image/png"
        href={whiteLabel?.favIconUrl || "/favicon-96x96.png"}
        sizes="96x96"
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={whiteLabel?.favIconUrl || "/apple-touch-icon.png"}
      />

      <link rel="shortcut icon" href={faviconUrl} />
    </Fragment>
  );
};

export default FaviconManager;
