import { FC, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  SupportingDrawerLayout,
  GeozoneDetails as GeozoneDetailsSection
} from "@vilocnv/allsetra-core";
import { Box, useTheme } from "@mui/material";
import GeozonesMap from "components/maps/GeozonesMap/GeozonesMap";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectGeozonesState,
  selectDrawerSelectedAccountId
} from "app/data/selectors";
import { getGeozoneObjectsThunk, setActiveGeozoneId } from "app/features";
import { useTranslation } from "react-i18next";

const GeozoneDetails: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const { t } = useTranslation();

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const { activeGeozone, activeGeozoneObjects } =
    useAppSelector(selectGeozonesState);

  useEffect(() => {
    dispatch(
      getGeozoneObjectsThunk({
        accountId: drawerSelectedAccountId ?? "",
        geozoneId: params.id ?? ""
      })
    );
  }, [drawerSelectedAccountId, params]);

  const backClickHandler = () => {
    navigate("/dashboard/geozones");
  };

  const onEditGeozone = (geozoneId: string) => {
    if (geozoneId) {
      dispatch(setActiveGeozoneId(geozoneId));
      navigate("/dashboard/geozones", {
        state: {
          geozoneId
        }
      });
    }
  };

  return (
    <main>
      <SupportingDrawerLayout open={true}>
        <GeozoneDetailsSection
          geozone={activeGeozone}
          objects={activeGeozoneObjects}
          onBack={backClickHandler}
          //@ts-ignore
          onEditGeozone={onEditGeozone}
          translator={t}
          theme={theme}
        />
      </SupportingDrawerLayout>
      <Box sx={{ marginLeft: "400px" }}>
        <GeozonesMap
          geozones={activeGeozone ? [activeGeozone] : []}
          objects={activeGeozoneObjects}
        />
      </Box>
    </main>
  );
};

export default GeozoneDetails;
