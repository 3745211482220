import { Box, Skeleton as MuiSkeleton, styled } from "@mui/material";

export const SkeletonWrapper = styled(Box)({
  width: "456px"
});

export const Skeleton = styled(MuiSkeleton)({
  width: "90%",
  height: 40,
  mb: 3,
  backgroundColor: "#545454"
});
