import { FC } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { FormikInputField } from "@vilocnv/allsetra-core";

const InnerForm: FC = () => {
  const theme = useTheme();
  const isDownSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack spacing={4}>
      <FormikInputField
        theme={theme}
        label="Verification Code"
        name="verificationCode"
        fullWidth={isDownSmall}
      />
    </Stack>
  );
};

export default InnerForm;
