import { types } from "@vilocnv/allsetra-core";

export const getFilteredUsers = (
  users: Array<types.IUser>,
  roles: Array<any>
) => {
  try {
    const roleIds = roles?.map((item) => item?.id);
    const _users: Array<types.IUser> = users?.filter((user: types.IUser) =>
      roleIds.includes(user?.role)
    );
    return _users;
  } catch (error: any) {
    console.log(error.message);
    return [];
  }
};
