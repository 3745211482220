import { styled, Box } from "@mui/material";

export const ChangePasswordContatiner = styled(Box)({
  fontFamily: "Gilroy",
  fontSize: "16px",
  fontWeight: "500",
  cursor: "pointer",
  marginTop: "30px",
  display: "flex",
  gap: "8px",
  color: "#39169E",
  "&:hover": {
    color: "#835BFC",
    border: "none"
  }
});
