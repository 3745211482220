import { FC, useMemo } from "react";
import { Table } from "@vilocnv/allsetra-core";
import { WORKING_HOURS_SECOND_LAYER_COLUMNS } from "app/data/constants";
import WorkingHoursExpendableRowCard from "../WorkingHoursExpendableRowCard/WorkingHoursExpendableRowCard";
import { useTranslation } from "react-i18next";

interface WorkingHoursDateRowCardProps {
  data: any;
}

const WorkingHoursDateRowCard: FC<WorkingHoursDateRowCardProps> = ({
  data
}) => {
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(
    () => WORKING_HOURS_SECOND_LAYER_COLUMNS(t),
    [t]
  );

  const customStyles = {
    headRow: {
      style: {
        ".sc-fqkvVR.sc-fHjqPf.dKzMcW.cSyyNR": {
          display: "none"
        }
      }
    }
  };
  return (
    <Table
      data={data.details}
      columns={tableColumns}
      expandableRows
      expandOnRowClicked
      pagination={false}
      expandableRowsComponent={WorkingHoursExpendableRowCard}
      customStyles={customStyles}
      translator={t}
    />
  );
};

export default WorkingHoursDateRowCard;
