import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveGeozoneIcon: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16667 3.33333H15.8333M4.16667 16.6667H15.8333M16.6667 4.16667V15.8333M3.33333 4.16667V15.8333M15.8333 4.16667H17.5V2.5H15.8333V4.16667ZM9.16667 10.8333H10.8333V9.16667H9.16667V10.8333ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM15.8333 17.5H17.5V15.8333H15.8333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5Z"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActiveGeozoneIcon;
