import { createAsyncThunk } from "@reduxjs/toolkit";
import { types, toast } from "@vilocnv/allsetra-core";
import { ObjectsService } from "app/data/services";
import { IRideBulkUpdateKeys, IRideBulkUpdateMode } from "app/data/types";
import { objectAlarmConfigInitialData } from "./objectsSlice";

export const getAllObjectsThunk = createAsyncThunk(
  "objects/getAllObjectsThunk",
  async (accountId: string) => {
    try {
      const response = await ObjectsService.getAllObjects(accountId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllBasicInfoObjectsThunk = createAsyncThunk(
  "objects/getAllBasicInfoObjectsThunk",
  async (accountId: string) => {
    try {
      const response = await ObjectsService.getAllBasicInfoObjects(accountId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getObjectsByQueryThunk = createAsyncThunk(
  "objects/getObjectsByQueryThunk",
  async ({
    params,
    accountId,
    minimal
  }: {
    params: types.IRecordsAggregationBody;
    accountId: string;
    minimal?: boolean;
  }) => {
    try {
      const response = minimal
        ? await ObjectsService.getMinimalObjectsByQuery(accountId, params)
        : await ObjectsService.getObjectsByQuery(accountId, params);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSideMenuObjectsByQueryThunk = createAsyncThunk(
  "objects/getSideMenuObjectsByQueryThunk",
  async ({
    params,
    accountId,
    minimal
  }: {
    params: types.IRecordsAggregationBody;
    accountId: string;
    minimal?: boolean;
  }) => {
    try {
      const response = minimal
        ? await ObjectsService.getMinimalObjectsByQuery(accountId, params)
        : await ObjectsService.getObjectsByQuery(accountId, params);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getObjectsLocationsThunk = createAsyncThunk(
  "objects/getObjectsLocationsThunk",
  async ({ accountId, values }: { accountId: string; values: any }) => {
    try {
      const response = await ObjectsService.getObjectsLocations(
        accountId,
        values
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificObjectByIdThunk = createAsyncThunk(
  "objects/getSpecificObjectByIdThunk",
  async ({
    accountId,
    objectId,
    shouldFetchAlarmConfig = true
  }: {
    accountId: string;
    objectId: string;
    shouldFetchAlarmConfig?: boolean;
  }) => {
    try {
      const { data: specificObject } =
        await ObjectsService.getSpecificObjectById(accountId, objectId);

      let objectAlarmConfig = objectAlarmConfigInitialData;

      if (shouldFetchAlarmConfig) {
        const { data } = await ObjectsService.getObjectAlarmConfig(
          accountId,
          objectId
        );

        objectAlarmConfig = data;
      }

      return {
        specificObject,
        objectAlarmConfig
      };
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const getObjectDataForMapThunk = createAsyncThunk(
  "objects/getObjectDataForMapThunk",
  async ({ accountId, objectId }: { accountId: string; objectId: string }) => {
    try {
      const { data } = await ObjectsService.getObjectDataForMap(
        accountId,
        objectId
      );

      return { ...data, uniqueId: objectId };
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const postUpdateObjectThunk = createAsyncThunk(
  "objects/postUpdateObjectThunk",
  async ({ accountId, data }: { accountId: string; data: any }) => {
    try {
      const response = await ObjectsService.postUpdateObject(accountId, data);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const patchObjectMilageCorrectionThunk = createAsyncThunk(
  "objects/patchObjectMilageCorrectionThunk",
  async ({
    accountId,
    objectId,
    data
  }: {
    accountId: string;
    objectId: string;
    data: {
      newMileage: number;
      correctionDate: string;
    };
  }) => {
    try {
      const response = await ObjectsService.patchObjectMilageCorrection({
        accountId,
        objectId,
        data
      });

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

//
// Actions => Object Rides
//
export const getObjectRidesByQueryThunk = createAsyncThunk(
  "objects/getObjectRidesByQueryThunk",
  async ({
    params,
    accountId,
    objectId
  }: {
    params: types.IRecordsAggregationBody;
    accountId: string;
    objectId: string;
  }) => {
    try {
      const response = await ObjectsService.getObjectRidesByQuery(
        { accountId, objectId },
        params
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getObjectSpecificRideInfoThunk = createAsyncThunk(
  "objects/getObjectSpecificRideInfoThunk",
  async (data: { accountId: string; objectId: string; rideId: string }) => {
    try {
      const response = await ObjectsService.getObjectSpecificRideInfo(data);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getObjectRideLocationsThunk = createAsyncThunk(
  "objects/getObjectRideLocationsThunk",
  async (data: { accountId: string; objectId: string; rideId: string }) => {
    try {
      const response = await ObjectsService.getObjectRideLocations(data);

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const updateObjectRideThunk = createAsyncThunk(
  "objects/updateObjectRideThunk",
  async ({
    accountId,
    objectId,
    rideId,
    data
  }: {
    accountId: string;
    objectId: string;
    rideId: string;
    data: any;
  }) => {
    try {
      const response = await ObjectsService.updateObjectRide(
        { accountId, objectId, rideId },
        data
      );

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const updateRideBulkUploadKeysThunk = createAsyncThunk(
  "objects/updateRideBulkUploadKeysThunk",
  async ({
    accountId,
    data
  }: {
    accountId: string;
    data: IRideBulkUpdateKeys;
  }) => {
    try {
      const response = await ObjectsService.updateRideBulkUploadKeys(
        accountId,
        data
      );

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const updateRideBulkUploadModeThunk = createAsyncThunk(
  "objects/updateRideBulkUploadModeThunk",
  async ({
    accountId,
    data
  }: {
    accountId: string;
    data: IRideBulkUpdateMode;
  }) => {
    try {
      const response = await ObjectsService.updateRideBulkUploadMode(
        accountId,
        data
      );

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const updateAccountMileageThunk = createAsyncThunk(
  "objects/updateAccountMileageThunk",
  async ({
    accountId,
    objectId,
    data
  }: {
    accountId: string;
    objectId: string;
    data: any;
  }) => {
    try {
      const response = await ObjectsService.updateAccountMileage({
        accountId,
        objectId,
        data
      });

      if (response.status === 202) {
        toast.success("Mileage correction request has been sent");
      }
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

// Object Features Actions
export const getObjectFeaturesThunk = createAsyncThunk(
  "objects/getObjectFeaturesThunk",
  async ({ accountId, objectId }: { accountId: string; objectId: string }) => {
    try {
      const response = await ObjectsService.getObjectFeatures(
        accountId,
        objectId
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (e: any) {
      console.log(e);
      console.error(e);
      throw new Error(e);
    }
  }
);
