import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import {
  ContentSectionLayout,
  FormikInputField,
  FormikSelectField,
  TwoColsLayout
} from "@vilocnv/allsetra-core";

// Data
import {
  yesNoOptionsForDropdown,
  paymentTermInDaysOptions
} from "app/data/constants";
import { useAppSelector, useDispatchOnMount } from "hooks";
import { selectDashboardPaymentMethodsState } from "app/data/selectors";
import { getAllPaymentMethodsThunk } from "app/features";
import { useTranslation } from "react-i18next";
import AddressFormSection from "./VisitingAddressForm";

interface Props {
  countries: Array<any>;
  countriesLoading: boolean;
}

const FinancialInformationForm: FC<Props> = ({
  countries,
  countriesLoading
}) => {
  const theme = useTheme();

  const { paymentMethods, loading: paymentMethodsLoading } = useAppSelector(
    selectDashboardPaymentMethodsState
  );

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  useDispatchOnMount(
    getAllPaymentMethodsThunk,
    paymentMethods.length ? undefined : true
  );

  return (
    <ContentSectionLayout
      title={t("titles.financialInfo")}
      subTitle={t("descriptions.financialInfo")}
    >
      <Box
        sx={{
          marginTop: {
            xs: "32px",
            md: "0px"
          }
        }}
      >
        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label={t("afasDebitNumber.label", { ns: "formFieldsTranslation" })}
            placeholder={t("afasDebitNumber.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="afasDebitNumber"
            fullWidth
          />
        </TwoColsLayout>

        <AddressFormSection
          title={t("titles.billingAddress")}
          parentKey="billingAddress"
          countries={countries ?? []}
          countriesLoading={countriesLoading}
          translator={t}
        />
        <TwoColsLayout>
          <FormikSelectField
            theme={theme}
            label={t("paymentMethod.label", { ns: "formFieldsTranslation" })}
            placeholder={t("paymentMethod.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="paymentMethod"
            options={paymentMethods || []}
            optionLabelKey={"name"}
            optionValueKey={"name"}
            loading={paymentMethodsLoading}
            fullWidth
          />
          <FormikSelectField
            theme={theme}
            label={t("paymentTerm.label", { ns: "formFieldsTranslation" })}
            placeholder={t("paymentTerm.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="paymentTermInDays"
            options={paymentTermInDaysOptions(t)}
            optionLabelKey={"name"}
            optionValueKey={"days"}
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout>
          <FormikInputField
            theme={theme}
            label={t("vatNumber.label", { ns: "formFieldsTranslation" })}
            placeholder={t("vatNumber.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="vatNumber"
            fullWidth
          />
          <FormikSelectField
            theme={theme}
            label={t("vatShifted.label", { ns: "formFieldsTranslation" })}
            placeholder={t("vatShifted.placeholder", {
              ns: "formFieldsTranslation"
            })}
            options={yesNoOptionsForDropdown(t)}
            optionLabelKey={"label"}
            optionValueKey={"value"}
            name="vatShifted"
            fullWidth
          />
        </TwoColsLayout>
        <TwoColsLayout hideDivider>
          <FormikInputField
            theme={theme}
            label={t("bankAccount.label", { ns: "formFieldsTranslation" })}
            placeholder={t("bankAccount.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="bankAccount"
            fullWidth
          />
        </TwoColsLayout>
      </Box>
    </ContentSectionLayout>
  );
};
export default FinancialInformationForm;
