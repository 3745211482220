import { FC } from "react";
import { Badge } from "@vilocnv/allsetra-core";

const StatusBadge: FC<{ status: number }> = ({ status }) => (
  <Badge colorScheme={status === 0 ? "success" : "error"}>
    {status === 0 ? "Successful" : "Failed"}
  </Badge>
);

export default StatusBadge;
