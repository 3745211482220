import axiosInstance from "app/axiosInstance";

class AuthService {
  static authVerifyEmail = async (data: {
    email: string;
    whitelabelId: string | null;
  }) => {
    return await axiosInstance.post(`/identity/verify-email`, data, {
      headers: { isNotProtected: true }
    });
  };

  static authResetPassword = async (data: {
    confirmationCode: string;
    password: string;
  }) => {
    return await axiosInstance.post(`/identity/reset-password`, data, {
      headers: { isNotProtected: true }
    });
  };
}

export default AuthService;
