import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveAccountIcon: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9435 10.7256L7.686 11.7564C7.56766 12.2281 7.24933 12.6256 6.81433 12.8431L5.53766 13.4814C4.66766 13.9164 4.031 14.7097 3.79433 15.6539L3.3335 17.4997H16.6668L16.2052 15.6539C15.9693 14.7097 15.3327 13.9164 14.4618 13.4814L13.186 12.8431C12.751 12.6256 12.4318 12.2281 12.3143 11.7564L12.0568 10.7256M11.0002 11.7502C10.4077 12.1943 9.59266 12.1943 9.00016 11.7502C7.531 10.6485 6.66683 8.91933 6.66683 7.0835V6.66683C6.66683 4.826 8.15933 3.3335 10.0002 3.3335C11.841 3.3335 13.3335 4.826 13.3335 6.66683V7.0835C13.3335 8.91933 12.4685 10.6485 11.0002 11.7502Z"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActiveAccountIcon;
