import React, { FC } from "react";
import RenderGeozones from "../../v1/children/RenderGeozones";
import { useMapState } from "./MapStateContext";

const Geozones: FC = () => {
  const { isLoaded, showGeozones, allGeozones, isDashboardMap } = useMapState();
  return (
    <>
      {showGeozones && isDashboardMap && isLoaded && (
        <RenderGeozones allGeozones={allGeozones} />
      )}
    </>
  );
};

export default React.memo(Geozones);
