import axiosInstance from "app/axiosInstance";
import { types } from "@vilocnv/allsetra-core";
import { IAddGeozone } from "../types";

class GeozonesService {
  static getAllGeozones = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/geozones`);
  };

  static getSpecificGeozone = async (
    accountId: string,
    accountGeofenceId: string
  ) => {
    return await axiosInstance.get(
      `/accounts/${accountId}/geozones/${accountGeofenceId}`
    );
  };

  static createGeozone = async (accountId: string, data: IAddGeozone) => {
    return await axiosInstance.post(`/accounts/${accountId}/geozones`, data);
  };

  static updateGeozone = async (accountId: string, data: types.IGeozone) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/geozones/${data.uniqueId}`,
      data
    );
  };

  static deleteGeozone = async (
    accountId: string,
    accountGeofenceId: string
  ) => {
    return await axiosInstance.delete(
      `/accounts/${accountId}/geozones/${accountGeofenceId}`
    );
  };

  static getGeozoneObjects = async (accountId: string, geozoneId: string) => {
    return await axiosInstance.get(
      `/accounts/${accountId}/geozones/${geozoneId}/objects`
    );
  };

  static getGeozoneAssignedObjects = async (
    accountId: string,
    geozoneId: string
  ) => {
    return await axiosInstance.get(
      `/accounts/${accountId}/geozones/${geozoneId}/assigned-objects`
    );
  };

  static getAccountGeozones = async (accountId: string) => {
    return await axiosInstance.get(`accounts/${accountId}/geozones`);
  };
}

export default GeozonesService;
