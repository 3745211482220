import React, { Fragment, useEffect, useState } from "react";
import {
  MarkerF,
  OverlayViewF,
  OverlayView,
  InfoWindowF
} from "@react-google-maps/api";
import { MarkerBlip, MarkerLabel } from "./styled";
import { MapObjectCard } from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";

interface CustomMarkerClustererProps {
  markers: Array<any>;
  handleMarkerClick: (marker: any) => void;
  map: any;
  selectedMarker: number | null;
}

function createKey(location: any) {
  return location.lat + location.lng;
}

const getPixelPositionOffset = () => ({
  x: 20,
  y: -29
});

export const MapViewport: React.FC<CustomMarkerClustererProps> = ({
  markers,
  handleMarkerClick,
  map,
  selectedMarker
}) => {
  // Local State
  const [visibleMarkers, setVisibleMarkers] = useState(markers);
  const { t } = useTranslation();

  useEffect(() => {
    if (map) {
      const updateVisibleMarkers = () => {
        let newVisibleMarkers = markers;
        const mapBounds = map.getBounds();

        if (map.zoom > 13) {
          newVisibleMarkers = markers.filter((marker) => {
            return mapBounds.contains(
              new window.google.maps.LatLng(marker.lat, marker.lng)
            );
          });
        }
        setVisibleMarkers(newVisibleMarkers);
      };

      window.google.maps.event.addListener(map, "idle", updateVisibleMarkers);

      updateVisibleMarkers();
    }
  }, [map, markers]);

  return (
    <Fragment>
      {visibleMarkers.map((marker, index) => (
        <Fragment key={index}>
          <MarkerF
            key={createKey(marker)}
            position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
            onClick={() => handleMarkerClick(marker.uniqueId)}
            icon={
              marker.iconUrl
                ? {
                    url: marker.iconUrl,
                    scaledSize: new window.google.maps.Size(28, 28)
                  }
                : undefined
            }
          >
            {selectedMarker === marker.uniqueId && (
              <InfoWindowF
                position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
                onCloseClick={() => handleMarkerClick(null)}
              >
                <MapObjectCard
                  label={marker.name || t("typography.na")}
                  id={marker.aNumber || t("typography.na")}
                  location={{
                    isLoading: false,
                    address: marker?.location?.resolvedAddress,
                    latitude: marker?.location?.latitude,
                    longitude: marker?.location?.longitude
                  }}
                  accuracy={marker?.accuracy || t("typography.na")}
                  time={marker?.formattedDate || t("typography.na")}
                  translator={t}
                ></MapObjectCard>
              </InfoWindowF>
            )}
          </MarkerF>
          {map.zoom > 14 && (
            <OverlayViewF
              position={{ lat: marker.lat, lng: marker.lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <MarkerLabel>{marker.name || "N/A"}</MarkerLabel>
              <MarkerBlip></MarkerBlip>
            </OverlayViewF>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};
