import { FC, useEffect, useState } from "react";
import { Stack, useTheme } from "@mui/material";
import {
  AccordionLayout,
  FormikInputField,
  TwoColsLayout
} from "@vilocnv/allsetra-core";
import {
  ButtonContainer,
  CustomButton,
  ExtensionWrapper,
  ExternalLinkBox,
  InnerTitle,
  InsuranceButtonBox,
  PersonalInfoFormContainer
} from "../../FietsgestolenForms.styled";
import { useAppSelector } from "hooks";
import { selectUserDataState } from "app/data/selectors";
import { useFormikContext } from "formik";
import { ExternalArrow } from "assets/icons";
import { Link } from "react-router-dom";
import { FIETSGESTOLEN_EXTERNAL_LINK } from "app/data/constants";

const InnerForm: FC = () => {
  const [isInsured, setIsInsured] = useState(true);
  const [isSelected, setIsSelected] = useState(true);
  const { userEmail } = useAppSelector(selectUserDataState);
  const formik = useFormikContext();
  const theme = useTheme();
  useEffect(() => {
    // Set the userEmail to email field when the component mounts
    formik.setFieldValue("email", userEmail);
  }, []);

  const handleInsuranceButtonClick = (value: boolean) => {
    formik.setFieldValue("insuranceCompany", "");
    formik.setFieldValue("policyNumber", "");
    setIsInsured(value);
    setIsSelected(value);
  };

  return (
    <PersonalInfoFormContainer>
      <Stack spacing={3}>
        <AccordionLayout title="Persoonlijke informatie" expand={true}>
          <Stack spacing={3}>
            <TwoColsLayout hideDivider>
              <FormikInputField
                theme={theme}
                label="Voornaam"
                name="firstName"
                fullWidth
                required
              />
              <FormikInputField
                theme={theme}
                label="Achternaam"
                name="lastName"
                fullWidth
                required
              />
            </TwoColsLayout>

            <TwoColsLayout hideDivider>
              <FormikInputField
                theme={theme}
                label="Telefoonnummer"
                name="phone"
                fullWidth
                required
              />
              <FormikInputField
                theme={theme}
                label="Email"
                name="email"
                fullWidth
                required
                disabled
              />
            </TwoColsLayout>
          </Stack>
        </AccordionLayout>

        <AccordionLayout title="Adres">
          <Stack spacing={3}>
            <TwoColsLayout hideDivider>
              <FormikInputField
                theme={theme}
                label="Straat"
                name="street"
                fullWidth
                required
              />
              <ExtensionWrapper>
                <FormikInputField
                  theme={theme}
                  label="Huisnummer"
                  name="number"
                  fullWidth
                  required
                />
                <FormikInputField
                  theme={theme}
                  label="Extensie"
                  name="extension"
                  fullWidth
                />
              </ExtensionWrapper>
            </TwoColsLayout>

            <TwoColsLayout hideDivider>
              <FormikInputField
                theme={theme}
                label="Stad"
                name="city"
                fullWidth
                required
              />
              <FormikInputField
                theme={theme}
                label="Postcode"
                name="zipCode"
                fullWidth
                required
              />
            </TwoColsLayout>
          </Stack>
        </AccordionLayout>

        <AccordionLayout title="Details van de fiets">
          <Stack spacing={3}>
            <TwoColsLayout hideDivider>
              <FormikInputField
                theme={theme}
                label="Merk"
                name="brand"
                fullWidth
                required
              />
              <FormikInputField
                theme={theme}
                label="Model"
                name="model"
                fullWidth
                required
              />
            </TwoColsLayout>

            <TwoColsLayout hideDivider>
              <FormikInputField
                theme={theme}
                label="Kleur"
                name="color"
                fullWidth
                required
              />
              <FormikInputField
                theme={theme}
                label="Framenummer fiets"
                name="frameNumber"
                fullWidth
                required
                tooltip="Bekijk je aankoopfactuur voor het framenummer van je fiets."
              />
            </TwoColsLayout>
          </Stack>
        </AccordionLayout>

        <AccordionLayout title="Extra informatie">
          <Stack spacing={3}>
            <TwoColsLayout hideDivider>
              <FormikInputField
                theme={theme}
                label="Motornummer"
                name="motorNumber"
                fullWidth
              />
              <FormikInputField
                theme={theme}
                label="Winkel / Dealer"
                name="dealer"
                fullWidth
              />
            </TwoColsLayout>

            <TwoColsLayout hideDivider>
              <FormikInputField
                theme={theme}
                label="Slotnummer 1"
                name="lockNumber1"
                required
                fullWidth
              />
              <FormikInputField
                theme={theme}
                label="Slotnummer 2"
                name="lockNumber2"
                required
                fullWidth
              />
            </TwoColsLayout>

            <ExternalLinkBox>
              <Link target="_blank" to={FIETSGESTOLEN_EXTERNAL_LINK}>
                <span>Lees hier meer over erkende sloten</span>
                <ExternalArrow />
              </Link>
            </ExternalLinkBox>

            <InsuranceButtonBox>
              <InnerTitle>Is je fiets verzekerd?</InnerTitle>
              <ButtonContainer>
                <CustomButton
                  variant="outlined"
                  onClick={() => handleInsuranceButtonClick(true)}
                  isSelected={isSelected === true}
                >
                  Ja
                </CustomButton>
                <CustomButton
                  variant="outlined"
                  onClick={() => handleInsuranceButtonClick(false)}
                  isSelected={isSelected === false}
                >
                  Nee
                </CustomButton>
              </ButtonContainer>
            </InsuranceButtonBox>

            {isInsured && (
              <TwoColsLayout hideDivider>
                <FormikInputField
                  theme={theme}
                  label="Verzekeringsmaatschappij"
                  name="insuranceCompany"
                  fullWidth
                />
                <FormikInputField
                  theme={theme}
                  label="Polisnummer"
                  name="policyNumber"
                  fullWidth
                />
              </TwoColsLayout>
            )}
          </Stack>
        </AccordionLayout>
      </Stack>
    </PersonalInfoFormContainer>
  );
};

export default InnerForm;
