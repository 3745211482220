import { FC } from "react";
import {
  getFileExtension,
  getBadgeColor,
  getIconColor,
  fileType
} from "app/data/constants";
import { Box, Stack } from "@mui/material";
import { Badge } from "@vilocnv/allsetra-core";

const DownloadFileButton: FC<{
  row: any;
  file: { id: string; type: fileType };
  handleDownloadClick: (row: any, id: string, type: string) => void;
}> = ({ row, file, handleDownloadClick }) => {
  const { id, type } = file;

  return (
    <Box
      key={id}
      role="file-download-button"
      onClick={() => handleDownloadClick(row, id, getFileExtension(type))}
    >
      <Badge colorScheme={getBadgeColor(type)}>
        <Stack gap={1} direction={"row"}>
          {getFileExtension(type)}
          {getIconColor(type)}
        </Stack>
      </Badge>
    </Box>
  );
};

export default DownloadFileButton;
