import React, { FC, Fragment, useMemo } from "react";
import Marker from "./Marker";
import { useMapState } from "./MapStateContext";

const Markers: FC = () => {
  const { markers, showClusters } = useMapState();

  const renderMarkers = useMemo(() => {
    return markers.map((marker, index) => (
      <Fragment key={index}>
        <Marker marker={marker} />
      </Fragment>
    ));
  }, [markers]);

  return <>{!showClusters && markers.length > 0 && <>{renderMarkers}</>}</>;
};

export default React.memo(Markers);
