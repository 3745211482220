import { FC, memo, useMemo } from "react";
import { Table, TableColumn } from "@vilocnv/allsetra-core";
import { MILAGE_REG_INNER_TABLE_COLUMNS } from "app/data/constants";
import { useResolveAddress } from "hooks";
import { useTranslation } from "react-i18next";

const customStyles = {
  cells: {
    style: {
      justifyContent: "center"
    }
  }
};
interface MilageRegFinalDataProps {
  data: any;
}

const MilageRegFinalData: FC<MilageRegFinalDataProps> = ({ data }) => {
  const { resolvedAddresses } = useResolveAddress(data?.data || []);
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns: TableColumn<any>[] = useMemo(
    () => MILAGE_REG_INNER_TABLE_COLUMNS(resolvedAddresses),
    [resolvedAddresses]
  );

  return (
    <Table
      columns={tableColumns}
      data={data.data}
      noTableHead={true}
      pagination={false}
      customStyles={customStyles}
      translator={t}
    />
  );
};

export default memo(MilageRegFinalData);
