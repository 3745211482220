import { styled, Box } from "@mui/material";

export const InfoDetailContainer = styled(Box)<{ hideDivider?: boolean }>(
  ({ hideDivider, theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 0px",
    borderBottom: hideDivider ? "none" : "1px solid #EFF4FF",
    width: "100%",

    "& h6": {
      color: "var(--gray-900, #1D1F2B)",
      fontFamily: "Gilroy",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "112%"
    },

    "& p": {
      color: "var(--gray-900, #1D1F2B)",
      fontFamily: "Gilroy",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "300",
      lineHeight: "116%"
    },

    [theme.breakpoints.down("sm")]: {
      "& p": {
        fontSize: "10px"
      },

      "& h6": {
        fontSize: "10px"
      }
    }
  })
);

export const SubscriptionInfoContainer = styled(Box)({
  "& .MuiTypography-root": {
    fontWeight: "bold",
    color: "var(--gray-900, #1D1F2B)",
    borderBottom: "1px solid #EFF4FF",
    paddingBottom: "8px",
    marginBottom: "12px"
  }
});
