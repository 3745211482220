import { FC } from "react";
import { Grid, Stack, useTheme } from "@mui/material";
import { FormikCheckbox, FormikInputField } from "@vilocnv/allsetra-core";
import {
  CheckBoxContainer,
  LabelLinkText,
  LabelText,
  MainHeading,
  PaymentFormContainer,
  TermsHeading
} from "../../activationPortalForms.styled";
import { selectWhiteLabelsState } from "app/data/selectors";
import { useAppSelector } from "hooks";
import { useFormikContext } from "formik";

interface Props {
  translator: any;
}

const InnerForm: FC<Props> = ({ translator }) => {
  const { whiteLabel } = useAppSelector(selectWhiteLabelsState);
  const theme = useTheme();

  const termsAndConditionsLink = whiteLabel
    ? whiteLabel.termsAndConditions
    : "https://allsetra.nl/wp-content/uploads/2022/06/Allsetra-2022-Website-Beelden-Algemene-Voorwaarden.pdf";

  const privacyPolicyLink = whiteLabel
    ? whiteLabel.privacy
    : "https://allsetra.nl/privacy-policy/";
  const { setFieldValue, values } = useFormikContext();

  const changeToggleValue = (key: string, value: boolean) => {
    setFieldValue(key, value);
  };

  return (
    <PaymentFormContainer container>
      <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
        <Stack spacing={3}>
          <MainHeading>
            {translator("titles.paymentDetails", {
              ns: "activationPortal"
            })}
          </MainHeading>
          <Stack spacing={3} direction={{ xs: "column", sm: "row" }}>
            <FormikInputField
              theme={theme}
              label={translator("accountHolderName.label", {
                ns: "formFieldsTranslation"
              })}
              name="accountHolderName"
              fullWidth
              autoComplete="off"
              debounceDelay={0}
            />
            <FormikInputField
              theme={theme}
              label={translator("bankAccountNumber.label", {
                ns: "formFieldsTranslation"
              })}
              name="bankAccountNumber"
              fullWidth
              autoComplete="off"
              debounceDelay={0}
            />
          </Stack>
        </Stack>
        <Stack mt={5} spacing={2}>
          <TermsHeading>
            {translator("titles.termsAndConditions", {
              ns: "activationPortal"
            })}
          </TermsHeading>
          <Stack spacing={-1}>
            <CheckBoxContainer
              onClick={() =>
                changeToggleValue(
                  "authorizeAllsetra",
                  //@ts-ignore
                  !values?.authorizeAllsetra
                )
              }
            >
              <FormikCheckbox
                theme={theme}
                name="authorizeAllsetra"
                label={""}
              />
              <LabelText>
                {translator("authorizeAllsetra.label", {
                  ns: "formFieldsTranslation"
                })}
              </LabelText>
            </CheckBoxContainer>

            <CheckBoxContainer
              onClick={() =>
                changeToggleValue(
                  "directDebit",
                  //@ts-ignore
                  !values?.directDebit
                )
              }
            >
              <FormikCheckbox theme={theme} name="directDebit" label={""} />
              <LabelText>
                {translator("directDebit.label", {
                  ns: "formFieldsTranslation"
                })}
              </LabelText>
            </CheckBoxContainer>

            <CheckBoxContainer
              onClick={() =>
                changeToggleValue(
                  "termsAndConditions",
                  //@ts-ignore
                  !values?.termsAndConditions
                )
              }
            >
              <FormikCheckbox
                theme={theme}
                name="termsAndConditions"
                label={""}
              />
              <LabelText>
                {translator("titles.generalTermsStartingText", {
                  ns: "activationPortal"
                })}
                <LabelLinkText href={termsAndConditionsLink} target="_blank">
                  {translator("titles.generaltermsAndConditions", {
                    ns: "activationPortal"
                  })}
                </LabelLinkText>
                {translator("titles.generalTermsEndingText", {
                  ns: "activationPortal"
                })}
              </LabelText>
            </CheckBoxContainer>
            <CheckBoxContainer
              onClick={() =>
                changeToggleValue(
                  "privacyPolicy",
                  //@ts-ignore
                  !values?.privacyPolicy
                )
              }
            >
              <FormikCheckbox theme={theme} name="privacyPolicy" label={""} />
              <LabelText>
                {translator("titles.privacyStartingText", {
                  ns: "activationPortal"
                })}
                <LabelLinkText href={privacyPolicyLink} target="_blank">
                  {translator("titles.privacyPolicy", {
                    ns: "activationPortal"
                  })}
                </LabelLinkText>
                {translator("titles.privacyEndingText", {
                  ns: "activationPortal"
                })}
              </LabelText>
            </CheckBoxContainer>
          </Stack>
        </Stack>
      </Grid>
    </PaymentFormContainer>
  );
};

export default InnerForm;
