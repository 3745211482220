import { getDateKey } from "../reportCommonHelpers";
import * as Yup from "yup";

export type GroupBy = "day" | "week" | "month" | "year";

export const reportsHistoryFormInitialValues = {
  objectType: [],
  group: [],
  object: [],
  daysOfWeek: [],
  startTime: null,
  endTime: null
};

export const reportsHistoryFormValidationSchema: Yup.Schema = Yup.object({
  objectType: Yup.array().of(Yup.string()).label("Object Types"),
  group: Yup.array().of(Yup.string()).label("Groups"),
  object: Yup.array().of(Yup.string()).label("Objects")
});

export const organizeReportHistoryData = (
  data: any,
  groupBy: GroupBy
): { label: string; records: any }[] => {
  const organizedData: { [key: string]: any } = {};

  data.forEach((entry: any) => {
    const dateKey = getDateKey(entry.created, groupBy);

    if (!organizedData[dateKey]) {
      organizedData[dateKey] = [];
    }

    organizedData[dateKey].push(entry);
  });

  const result: { label: string; records: any }[] = Object.keys(
    organizedData
  ).map((dateKey) => {
    return {
      label: dateKey,
      records: organizedData[dateKey]
    };
  });

  return result;
};
