import { setSubscribeFormData } from "app/features";
import { useFormikContext } from "formik";
import { useAppDispatch } from "hooks";
import { useEffect, useRef } from "react";

const ActivationPortalDraftFormObserver = () => {
  const { values } = useFormikContext();
  const dispatch = useAppDispatch();
  const draftValuesRef = useRef<any>(values);

  useEffect(() => {
    draftValuesRef.current = values;
  }, [values]);

  const saveDraft = () => {
    dispatch(setSubscribeFormData(draftValuesRef.current));
  };

  useEffect(() => {
    return () => {
      saveDraft();
    };
  }, [dispatch]);

  return null;
};

export default ActivationPortalDraftFormObserver;
