import axiosInstance from "app/axiosInstance";

class UsersService {
  static getAllUsers = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/users`);
  };

  static getUsersByQuery = async (data: any, accountId: string) => {
    return await axiosInstance.post(
      `/accounts/${accountId}/users/search`,
      data
    );
  };

  static getSpecificUser = async (accountId: string, userId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/users/${userId}`);
  };

  static getUsersWithRoleDriver = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/users/drivers`);
  };

  static createUser = async (accountId: string, data: any) => {
    return await axiosInstance.post(`/accounts/${accountId}/users`, data);
  };

  static updateUser = async (accountId: string, data: any) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/users/${data.uniqueId}`,
      data
    );
  };

  static deactivateUser = async (userId: string, accountId: string) => {
    return await axiosInstance.delete(`/accounts/${accountId}/users/${userId}`);
  };

  static activateUser = async (userId: string) => {
    return await axiosInstance.patch(`/users/${userId}/activate`);
  };

  static getAllUserRoles = async () => {
    return await axiosInstance.get(`/roles`);
  };
}

export default UsersService;
