import { createSlice } from "@reduxjs/toolkit";
import {
  getAllExportedReportsHistoryThunk,
  getSpecificExportedReportThunk,
  searchExportedReportThunk,
  deleteExportedReportThunk
} from "./exportedReportsHistoryActions";

export interface IExportedReportsHistoryState {
  loading: boolean;
  allExportedReportsHistory: Array<any>;
  specificExportedReport: any;
  totalRecords: number;
}

const initialState: IExportedReportsHistoryState = {
  loading: false,
  allExportedReportsHistory: [],
  specificExportedReport: null,
  totalRecords: 0
};

const exportedReportsHistorySlice = createSlice({
  name: "exportedReportsHistory",
  initialState,
  reducers: {
    resetExportedReportsHistory: (state) => {
      state.allExportedReportsHistory = [];
      state.loading = false;
    },
    resetSpecificExportedReport: (state) => {
      state.specificExportedReport = null;
      state.loading = false;
    }
  },
  extraReducers: (builder) => {
    // Get Reports Action Cases
    builder.addCase(getAllExportedReportsHistoryThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllExportedReportsHistoryThunk.fulfilled,
      (state, action: any) => {
        state.allExportedReportsHistory = action.payload || [];
        state.loading = false;
      }
    );
    builder.addCase(getAllExportedReportsHistoryThunk.rejected, (state) => {
      state.loading = false;
    });

    // get Specific Schedule report
    builder.addCase(getSpecificExportedReportThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSpecificExportedReportThunk.fulfilled,
      (state, action: any) => {
        state.specificExportedReport = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getSpecificExportedReportThunk.rejected, (state) => {
      state.loading = false;
    });

    // search reports schedule
    builder.addCase(searchExportedReportThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      searchExportedReportThunk.fulfilled,
      (state, action: any) => {
        state.allExportedReportsHistory = action.payload.results;
        state.totalRecords = action.payload.rowCount || 0;
        state.loading = false;
      }
    );
    builder.addCase(searchExportedReportThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteExportedReportThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteExportedReportThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteExportedReportThunk.rejected, (state) => {
      state.loading = false;
    });
  }
});

export * from "./exportedReportsHistoryActions";
export const { resetExportedReportsHistory, resetSpecificExportedReport } =
  exportedReportsHistorySlice.actions;
export default exportedReportsHistorySlice.reducer;
