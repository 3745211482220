import { Box, styled } from "@mui/material";

export const VerificationInputWrapper = styled(Box)(({ theme }) => ({
  "& .container": {
    gap: "8px",
    height: "48px",
    width: "490px",
    [theme.breakpoints.down("sm")]: {
      height: "42px",
      width: "380px"
    },
    "div:nth-child(3), div:nth-child(6)": {
      marginRight: "8px"
    },
    "& .character": {
      width: "54px",
      borderRadius: "4px",
      border: "1px solid var(--admin-300, #E2E5F8)",
      background: "var(--admin-100, #F9FAFF)",
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px",
        lineHeight: "38px"
      }
    }
  }
}));
