import { styled, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

export const TextItem = styled(Typography)({
  display: "flex",
  alignItems: "center",
  gap: "8px"
});

export const CheckMarkIcon = styled(DoneIcon)({
  color: "green",
  fontSize: "18px"
});

export const CrossIcon = styled(CloseIcon)({
  fontSize: "18px"
});
