import React, { FC, Fragment } from "react";
import {
  CircleF,
  OverlayView,
  OverlayViewF,
  PolygonF
} from "@react-google-maps/api";

import { GeozoneNameBox, GeozoneNameDownArrow } from "../../Map.styled";
import { isEmpty, isObject, isArray } from "lodash";
import { POLYGON_OPTIONS } from "app/data/constants";
import { transformGeoJSONCoordsToPaths } from "app/data/helpers";

interface RenderGeozoneProps {
  allGeozones: any[];
}

const RenderGeozones: FC<RenderGeozoneProps> = ({ allGeozones }) => {
  const getPolygonCenter = (
    coordinates: any[]
  ): { lat: number; lng: number } => {
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach((coord) => {
      bounds.extend(new google.maps.LatLng(coord.lat, coord.lng));
    });
    return {
      lat: bounds.getCenter().lat(),
      lng: bounds.getCenter().lng()
    };
  };

  const GeozoneLabel: FC<{
    position: { lat: number; lng: number };
    name: string;
  }> = ({ position, name }) => {
    return (
      <OverlayViewF
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Fragment>
          <GeozoneNameBox>{name || "N/A"}</GeozoneNameBox>
          <GeozoneNameDownArrow />
        </Fragment>
      </OverlayViewF>
    );
  };

  const structuredGeozones = (geozone: any) => {
    if (!geozone || !geozone.geoJson) return null;

    let geoJSON;
    try {
      geoJSON =
        typeof geozone.geoJson === "string"
          ? JSON.parse(geozone.geoJson)
          : geozone.geoJson;
    } catch (error) {
      console.error("Failed to parse geoJson:", error);
      return null;
    }

    if (!isObject(geoJSON)) return null;
    //@ts-ignore
    const geoJSONProperties = geoJSON?.properties || geoJSON?.Properties;

    if (!isEmpty(geoJSONProperties)) {
      const APIRadius = geoJSONProperties.radius || geoJSONProperties.Radius;
      const centerPoint =
        geoJSONProperties.centerPoint || geoJSONProperties.CenterPoint;

      if (!APIRadius || !centerPoint || !isArray(centerPoint)) return null;

      const center = {
        lat: centerPoint[0] || 0,
        lng: centerPoint[1] || 0
      };

      return (
        <Fragment key={geozone.uniqueId}>
          <CircleF
            options={{
              ...POLYGON_OPTIONS
            }}
            radius={APIRadius}
            center={center}
          />
          {/* TODO CAN BE ADDED LATER */}
          {/* <GeozoneLabel position={center} name={geozone.name} /> */}
        </Fragment>
      );
    } else {
      const geometryCoords =
        //@ts-ignore
        geoJSON.geometry?.coordinates || geoJSON.Geometry?.Coordinates;
      if (!geometryCoords) return null;

      const coordinates = transformGeoJSONCoordsToPaths(geometryCoords);

      if (!isArray(coordinates) || coordinates.length === 0) return null;

      const center = getPolygonCenter(coordinates);

      return (
        <Fragment key={geozone.uniqueId}>
          <PolygonF
            paths={coordinates}
            options={{
              ...POLYGON_OPTIONS
            }}
          />
          {/* TODO CAN BE ADDED LATER */}
          {/* <GeozoneLabel position={center} name={geozone.name} /> */}
        </Fragment>
      );
    }
  };

  if (!allGeozones || !isArray(allGeozones) || allGeozones.length === 0) {
    return null;
  }

  return <Fragment>{allGeozones.map(structuredGeozones)}</Fragment>;
};

export default RenderGeozones;
