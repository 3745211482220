export const geozoneRadiusOptions = [
  { label: "100 Meter", value: 100 },
  { label: "250 Meter", value: 250 },
  { label: "500 Meter", value: 500 },
  { label: "1 KM", value: 1000 },
  { label: "2 KM", value: 2000 },
  { label: "5 KM", value: 5000 },
  { label: "10 KM", value: 10000 },
  { label: "25 KM", value: 25000 },
  { label: "50 KM", value: 50000 }
];
