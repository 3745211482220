import { createSlice } from "@reduxjs/toolkit";
import { getWhiteLabelConfigurationThunk } from "./whiteLabelsActions";
import { types } from "@vilocnv/allsetra-core";

export interface IWhiteLabelsState {
  loading: boolean;
  whiteLabel: types.IWhiteLabel | null;
  isFetched: boolean;
}

const initialState: IWhiteLabelsState = {
  loading: false,
  whiteLabel: null,
  isFetched: false
};

const whiteLabelsSlice = createSlice({
  name: "whiteLabels",
  initialState,
  reducers: {
    resetWhiteLabelsSate: (state) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    // Get All Users Action Cases
    builder.addCase(getWhiteLabelConfigurationThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      getWhiteLabelConfigurationThunk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.isFetched = true;
        state.whiteLabel = action.payload;
      }
    );

    builder.addCase(getWhiteLabelConfigurationThunk.rejected, (state) => {
      state.loading = false;
    });
  }
});

export * from "./whiteLabelsActions";
export const {} = whiteLabelsSlice.actions;

export default whiteLabelsSlice.reducer;
