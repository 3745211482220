// @ts-nocheck
import { FC } from "react";
import { useStepDrawerController } from "hooks";
import StepperDrawerLayout, {
  IStepItem
} from "components/layouts/StepperDrawerLayout/StepperDrawerLayout";

// STEPS
import EmailVerificationForm from "components/forms/FietsgestolenForms/EmailVerificationForm";
import ObjectSelectionForm from "components/forms/FietsgestolenForms/ObjectSelectionForm";
import ReportTheftSuccessSection from "components/sections/FietsgestolenSections/ReportTheftSuccessSection";
import CodeVerificationForm from "components/forms/FietsgestolenForms/CodeVerificationForm";

const INITIAL_STEPS: Array<IStepItem> = [
  { index: 0, name: "E-mail verificatie", isActive: true, isCompleted: false },
  { index: 1, name: "Code verificatie", isActive: false, isCompleted: false },
  { index: 2, name: "Object selection", isActive: false, isCompleted: false },
  { index: 3, name: "Succes", isActive: false, isCompleted: false }
];

const FietsgestolenForm: FC = () => {
  const { steps, activeStepIndex, onCompletedStepClick, gotoNextStep } =
    useStepDrawerController(INITIAL_STEPS);

  return (
    <StepperDrawerLayout
      size={"extraLarge"}
      appLogoSize={"extraLarge"}
      isMobileDrawerOpen={false}
      appLogoType={"fietsgestolen"}
      steps={steps}
      onCompletedStepClick={onCompletedStepClick}
    >
      {activeStepIndex === 0 && (
        <EmailVerificationForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 1 && (
        <CodeVerificationForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 2 && (
        <ObjectSelectionForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 3 && (
        <ReportTheftSuccessSection gotoNextStep={gotoNextStep} />
      )}
    </StepperDrawerLayout>
  );
};

export default FietsgestolenForm;
