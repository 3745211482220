import React, { FC } from "react";
import { useMapState } from "./MapStateContext";
import { TrafficLayer as ReactGoogleMapsTrafficLayer } from "@react-google-maps/api";

const TrafficLayer: FC = () => {
  const { showTraffic } = useMapState();

  return <>{showTraffic && <ReactGoogleMapsTrafficLayer />}</>;
};

export default React.memo(TrafficLayer);
