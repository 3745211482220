import { BadgeColorSchemeType, TableColumn } from "@vilocnv/allsetra-core";
import { DownloadGreen, DownloadBlue, DownloadRed } from "assets/icons";
import { DownloadButtonWrapper } from "components/cards/ReportCards/ExportedReportsHistoryRowCard/ExportedReportsHistoryRowCard.styled";
import DownloadFileButton from "components/ui/DownloadFileButton";
import StatusBadge from "components/ui/StatusBadge";
import moment from "moment";

export type fileType = 0 | 1 | 2 | 3;

export const EXPORTED_REPORT_HISTORY_TABLE_COLUMNS = (
  translator: any
): TableColumn<any>[] => [
  {
    name: translator("tableHeading.reportType", {
      ns: "tableHeadingsTranslation"
    }),
    selector: (row: any) => row.label
  },
  {
    name: translator("tableHeading.downloads", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.generationTime", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.exportStatus", {
      ns: "tableHeadingsTranslation"
    })
  }
];

export const REPORT_TYPE = {
  2: "Mileage Report",
  3: "Working Hours Report"
};

export const getBadgeColor = (type: fileType): BadgeColorSchemeType => {
  const colors = {
    0: "success",
    1: "info",
    2: "error",
    3: "error"
  };
  return colors[type] as BadgeColorSchemeType;
};

export const getIconColor = (type: fileType) => {
  const icons = {
    0: <DownloadGreen />,
    1: <DownloadBlue />,
    2: <DownloadRed />,
    3: <DownloadRed />
  };

  return icons[type];
};

export const getFileName = (
  fileType: string,
  reportType: 2 | 3,
  row: { created: string }
) => {
  const fileExtension =
    fileType === "PDF Graphical" ? "pdf" : fileType.toLowerCase();
  const graphicalPrefix = fileType === "PDF Graphical" ? "Graphical " : "";

  const createdTime = moment(row.created).format("DD-MM-YY") || "";

  return `${graphicalPrefix}${REPORT_TYPE[reportType]} ${createdTime}.${fileExtension}`;
};

export const getFileExtension = (type: fileType) => {
  const extentions = {
    0: "CSV",
    1: "XLSX",
    2: "PDF",
    3: "PDF Graphical"
  };

  return extentions[type];
};

export const EXPORTED_REPORT_HISTORY_HEADERS = [
  { label: "Report Type", key: "Time" },
  { label: "Download", key: "Location" },
  { label: "Generation Time", key: "Accuracy" },
  { label: "Export Status", key: "Accurate" }
];

export const downloadReportFile = (
  payload: { data: any },
  fileName: string
) => {
  const url = window.URL.createObjectURL(new Blob([payload.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const EXPORTED_REPORT_HISTORY_INNER_TABLE_COLUMNS = (
  handleDownloadClick: any
) => {
  return [
    {
      //@ts-ignore
      selector: (row: any) => REPORT_TYPE[row.reportDefinition.reportType]
    },
    {
      cell: (row: any) => (
        <DownloadButtonWrapper direction={"row"}>
          {row.files.map((file: { id: string; type: fileType }) => (
            <DownloadFileButton
              key={file.id}
              row={row}
              file={file}
              handleDownloadClick={handleDownloadClick}
            />
          ))}
        </DownloadButtonWrapper>
      )
    },
    {
      selector: (row: { created: string }) =>
        moment(row.created).format("DD.MM.YYYY, HH:mm")
    },
    {
      selector: (row: { status: number }) => <StatusBadge status={row.status} />
    }
  ];
};
