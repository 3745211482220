import { FC } from "react";
import {
  StepButtonContainer,
  StepHeadingContainer,
  StepScreenButton,
  StepScreenContainer,
  StepSubtitle,
  StepTitle
} from "./StepScreenLayout.styled";
import { useTheme } from "@mui/material";
import { Button } from "@vilocnv/allsetra-core";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import { CtaLoginText } from "pages/activationPortal/activationPortal.styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface StepScreenLayoutProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  subtitle: string;
  disabled?: boolean;
  buttonText?: string;
  loading?: boolean;
  showUnauthenticatedTemplate?: boolean;
  isFietsgestolen?: boolean;
  onButtonSubmit: () => void;
}

const StepScreenLayout: FC<StepScreenLayoutProps> = ({
  children,
  title,
  subtitle,
  onButtonSubmit,
  disabled,
  buttonText,
  isFietsgestolen = false,
  loading,
  showUnauthenticatedTemplate = false
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "activationPortal"]);

  const handleClick = () => {
    navigate("/");
  };

  return (
    <StepScreenContainer isFietsgestolen={isFietsgestolen}>
      <StepHeadingContainer>
        <StepTitle>{title}</StepTitle>
        <StepSubtitle color="var(--gray-600, #646D7B)">
          {subtitle}
          {showUnauthenticatedTemplate && (
            <UnauthenticatedTemplate>
              <CtaLoginText>
                {t("buttons.alreadyOurCustomer", {
                  ns: "activationPortal"
                })}{" "}
                <button onClick={handleClick}>Log In</button>{" "}
                {t("buttons.toSkipThisStep", { ns: "activationPortal" })}
              </CtaLoginText>
            </UnauthenticatedTemplate>
          )}
        </StepSubtitle>
      </StepHeadingContainer>
      {children}
      <StepButtonContainer isFietsgestolen={isFietsgestolen}>
        {isFietsgestolen ? (
          <Button
            disabled={disabled}
            onClick={onButtonSubmit}
            theme={theme}
            loading={loading}
            size="large"
          >
            {buttonText}
          </Button>
        ) : (
          <StepScreenButton
            disabled={disabled}
            onClick={onButtonSubmit}
            theme={theme}
            loading={loading}
            isFietsgestolen={true}
          >
            {buttonText || "Next"}
          </StepScreenButton>
        )}
      </StepButtonContainer>
    </StepScreenContainer>
  );
};

export default StepScreenLayout;
