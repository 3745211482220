import axiosInstance from "app/axiosInstance";

class DashboardService {
  static getAllRoles = async () => {
    return await axiosInstance.get("/roles");
  };

  static getAllCurrencies = async () => {
    return await axiosInstance.get("/currencies");
  };

  static getAllIcons = async () => {
    return await axiosInstance.get("/icons");
  };

  // iconCategory
  // 0 is for general icons
  // 1 is for geofence icons
  static getIconsByCategory = async (iconCategory: 0 | 1) => {
    return await axiosInstance.get(`/icons/by-category/${iconCategory}`);
  };

  static getAllCountries = async () => {
    return await axiosInstance.get("/countries");
  };

  static getAllPaymentMethods = async () => {
    return await axiosInstance.get("/PaymentMethods");
  };

  static getGooglePlaces = async (location: string) => {
    return await axiosInstance.get(`/utility/geocode?location=${location}`);
  };

  // Dashboard Accounts APIs
  static getInitialAccount = async () => {
    return await axiosInstance.post(`/accounts/users/initial`);
  };

  static searchMinimalAccounts = async (data: any) => {
    return await axiosInstance.post(`/accounts/minimalsearch`, data);
  };
}

export default DashboardService;
