import React from "react";

interface IconProps {
  size?: number; // Size in pixels
}

export const StartLocation: React.FC<IconProps> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#00B2A3" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#D5D9E8" />
      <path
        d="M13.2957 9.37142L13.2951 9.37082C12.9408 9.0171 12.5 8.83334 11.9998 8.83334C11.4996 8.83334 11.0589 9.01712 10.705 9.37112C10.3507 9.72539 10.1665 10.1662 10.1665 10.6667C10.1665 11.167 10.3506 11.6077 10.705 11.9616C11.0588 12.3159 11.4995 12.5 11.9998 12.5C12.5003 12.5 12.9411 12.3158 13.2954 11.9616C13.6494 11.6076 13.8332 11.1669 13.8332 10.6667C13.8332 10.1665 13.6494 9.72575 13.2957 9.37142ZM8.61411 7.18426L8.61419 7.18419C9.59726 6.27733 10.7189 5.83334 11.9998 5.83334C13.2807 5.83334 14.4024 6.27733 15.3855 7.18419L15.3856 7.18426C16.3403 8.06459 16.8332 9.25108 16.8332 10.8C16.8332 11.7398 16.4805 12.7929 15.7143 13.969C14.9486 15.1439 13.7751 16.4386 12.1757 17.8525C12.1563 17.8679 12.1295 17.8841 12.0909 17.8985C12.0543 17.9122 12.0248 17.9167 11.9998 17.9167C11.9749 17.9167 11.9453 17.9122 11.9087 17.8985C11.8702 17.8841 11.8434 17.8679 11.8239 17.8525C10.2246 16.4386 9.05105 15.1439 8.2854 13.969C7.51919 12.7929 7.1665 11.7398 7.1665 10.8C7.1665 9.25108 7.6594 8.06459 8.61411 7.18426Z"
        fill="#F9FBFF"
        stroke="#F9FBFF"
      />
    </svg>
  );
};
