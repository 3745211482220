import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "@vilocnv/allsetra-core";
import { Settings } from "app/data/services";

export const getSpecificSettingThunk = createAsyncThunk(
  "settings/getSpecificSettingThunk",
  async () => {
    try {
      const response = await Settings.getSpecificSetting();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const updateSettingsThunk = createAsyncThunk(
  "settings/updateSettingsThunk",
  async (data: any) => {
    try {
      const response = await Settings.updateSettings(data);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  "settings/resetPasswordThunk",
  async (data: any) => {
    try {
      const response = await Settings.resetPassword(data);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const getAllLanguagesThunk = createAsyncThunk(
  "settings/getAllLanguagesThunk",
  async () => {
    try {
      const response = await Settings.getAllLanguages();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllSafetyQuestionsThunk = createAsyncThunk(
  "settings/getAllSafetyQuestionsThunk",
  async () => {
    try {
      const response = await Settings.getAllSafetyQuestions();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getCountryListThunk = createAsyncThunk(
  "settings/getCountryListThunk",
  async () => {
    try {
      const response = await Settings.getAllCountries();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
