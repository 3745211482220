import { styled, Box, Grid } from "@mui/material";

export const CtaText = styled("p")({
  color: "#76848F",
  textAlign: "center",
  fontSize: "14px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "116%",
  marginTop: "24px",

  "& span": {
    color: "#00B2A3",
    cursor: "pointer"
  }
});

export const LogoWrapper = styled(Box)(({ theme }) => ({
  marginTop: 16,
  marginLeft: 40,
  marginRight: 24,
  [theme.breakpoints.down("sm")]: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10
  }
}));

export const TrianglesWrapper = styled(Box)(({ theme }) => ({
  textAlign: "right",
  marginBottom: "-15px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "0"
  }
}));

export const FietsgestolenContainer = styled(Box)(({ theme }) => ({
  padding: "0 24px",
  zIndex: 2,
  position: "relative",
  marginTop: "-92px",
  [theme.breakpoints.down("sm")]: {
    marginTop: 0
  },
  [theme.breakpoints.between("sm", "lg")]: {
    // Styles for screen sizes greater than "sm" but less than "lg"
    marginTop: "-50px"
  }
}));

export const MainGridWrapper = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  height: "100vh"
}));

export const FietsgeMainWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: "16px",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0
  }
}));

export const InnerWrapper = styled(Box)(({ theme }) => ({
  marginTop: "-10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "20px"
  }
}));

export const FirstSection = styled(Box)(({ theme }) => ({
  marginLeft: 40,
  marginTop: 30,
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    marginTop: 20
  },
  [theme.breakpoints.between("sm", "lg")]: {
    marginLeft: 0
  }
}));

export const FirstSectionTitle = styled(Box)(({ theme }) => ({
  color: "#1D1F2B",
  fontWeight: 600,
  fontSize: 40,
  lineHeight: "46px",
  letterSpacing: 1,
  [theme.breakpoints.between("sm", "lg")]: {
    fontSize: 24,
    lineHeight: "24px"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    lineHeight: "24px"
  }
}));

export const FirstSectionDescription = styled(Box)({
  color: "#646D7B",
  fontWeight: 300,
  fontSize: 16,
  lineHeight: "21.76px",
  marginTop: 24
});

export const SecondSection = styled(Box)(({ theme }) => ({
  marginTop: 71,
  marginLeft: 40,
  padding: 32,
  background: "#FFFAF8",
  [theme.breakpoints.down("sm")]: {
    marginTop: 20,
    marginLeft: 0,
    padding: 32
  },
  [theme.breakpoints.between("sm", "lg")]: {
    marginLeft: 0
  }
}));

export const AllsetraShieldSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  height: "100%",
  justifyContent: "center",
  paddingRight: "72px",
  [theme.breakpoints.down("sm")]: {
    paddingRight: 0,
    marginBottom: "50px",
    marginTop: "50px"
  },
  [theme.breakpoints.between("sm", "lg")]: {
    paddingRight: "0"
  }
}));

export const ShieldSectionTitle = styled(Box)(({ theme }) => ({
  marginTop: 40,
  color: "#1D1F2B",
  fontWeight: 600,
  fontSize: 40,
  lineHeight: "46px",
  [theme.breakpoints.between("sm", "lg")]: {
    fontSize: 24,
    lineHeight: "24px"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    lineHeight: "24px"
  }
}));

export const ShieldSectionDescription = styled(Box)({
  marginTop: 8,
  marginBottom: 40,
  color: "#646D7B",
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "24.48px"
});

export const SecondSectionTitle = styled(Box)({
  color: "#CC1010",
  fontWeight: 500,
  fontSize: 20,
  lineHeight: "23px"
});

export const SecondSectionDescription = styled(Box)({
  marginTop: 20,
  marginBottom: 24,
  color: "#646D7B",
  fontWeight: 300,
  fontSize: 16,
  lineHeight: "21.76px"
});

export const PlusWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  zIndex: 1,
  [theme.breakpoints.down("sm")]: {
    display: "none"
  }
}));

export const CircleWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  zIndex: 1,
  left: "40%",
  [theme.breakpoints.down("sm")]: {
    display: "none"
  }
}));

export const LogoInnerWrapper = styled(Box)(({ theme }) => ({
  marginBottom: "40px",
  [theme.breakpoints.between("sm", "lg")]: {
    marginBottom: "50px"
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "0"
  }
}));
