import { FC, useMemo, useState, Fragment } from "react";
import { Grid, Stack } from "@mui/material";
import { Checkbox } from "@vilocnv/allsetra-core";
import {
  OverviewLeftContainer,
  OverviewRightContainer
} from "./ActivationOverviewSection.styled";
import StepScreenLayout from "components/layouts/StepScreenLayout";
import PersonalInfoOverview from "./children/PersonalInfoOverview";
import SubscriptionOverview from "./children/SubscriptionOverview";
import PaymentDetailsOverview from "./children/PaymentDetailsOverview";

//Data
import { useAppDispatch, useAppSelector } from "hooks";
import { selectActivationPortalState } from "app/data/selectors/activationPortalSelectors";
import { isEmpty } from "lodash";
import {
  activateSubscriptionThunk,
  resetSubscribeFormData
} from "app/features";
import { useTranslation } from "react-i18next";
import {
  formatPhoneNumberWithCountryCode,
  mapAlarmPersons
} from "app/data/helpers";

interface Props {
  gotoNextStep: () => void;
}

const ActivationOverviewSection: FC<Props> = ({ gotoNextStep }) => {
  const dispatch = useAppDispatch();

  //Local State
  const [checkboxValue, setCheckboxValue] = useState(false);

  //Global States
  const {
    subscribeFormData,
    installations,
    verificationCode,
    subscriptions,
    loading,
    securityQuestions,
    fromDashboard
  } = useAppSelector(selectActivationPortalState);

  const { t } = useTranslation([
    "translation",
    "activationPortal",
    "formFieldsTranslation"
  ]);

  const handleOverview = async () => {
    const activationPayload = {
      ...subscribeFormData,
      userInfo: {
        ...subscribeFormData.userInfo,
        phoneNumber1: formatPhoneNumberWithCountryCode(
          subscribeFormData.userInfo?.countryCode1,
          subscribeFormData.userInfo?.phoneNumber1
        ),
        phoneNumber2: formatPhoneNumberWithCountryCode(
          subscribeFormData.userInfo?.countryCode2,
          subscribeFormData.userInfo?.phoneNumber2
        )
      },
      alarmPersons: !subscribeFormData.alarmPersons?.length
        ? []
        : mapAlarmPersons(subscribeFormData.alarmPersons),
      //@ts-ignore
      installationId: installations.installationId,
      activationCode: verificationCode,
      //@ts-ignore
      deviceId: installations.deviceInfo.uniqueId
    };

    const { type } = await dispatch(
      activateSubscriptionThunk(activationPayload)
    );

    if (type === "activationPortal/activateSubscriptionThunk/fulfilled") {
      dispatch(resetSubscribeFormData());
      gotoNextStep();
    }
  };

  const formValues = useMemo(
    () => (!isEmpty(subscribeFormData) ? subscribeFormData : {}),
    [subscribeFormData]
  );

  return (
    <StepScreenLayout
      title={t("titles.overview", {
        ns: "activationPortal"
      })}
      subtitle={t("descriptions.overview", {
        ns: "activationPortal"
      })}
      disabled={!checkboxValue}
      onButtonSubmit={handleOverview}
      buttonText={t("headings.activateSubscription", {
        ns: "activationPortal"
      })}
      loading={loading}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
          <OverviewLeftContainer>
            <Stack spacing={5}>
              {!fromDashboard && (
                <PersonalInfoOverview
                  order={1}
                  initialValues={formValues}
                  securityQuestions={securityQuestions}
                  translator={t}
                />
              )}
              <SubscriptionOverview
                order={fromDashboard ? 1 : 2}
                initialValues={formValues}
                subscriptions={subscriptions}
                translator={t}
              />
              {fromDashboard && (
                <Fragment>
                  <PaymentDetailsOverview
                    order={fromDashboard ? 2 : 3}
                    initialValues={formValues}
                    translator={t}
                  />
                  <h5>
                    {t("titles.conditions", {
                      ns: "activationPortal"
                    })}
                  </h5>
                  <Checkbox
                    value={checkboxValue}
                    onChange={() => setCheckboxValue(!checkboxValue)}
                    name="conditions"
                    label={t("descriptions.conditions", {
                      ns: "activationPortal"
                    })}
                  />
                </Fragment>
              )}
            </Stack>
          </OverviewLeftContainer>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
          {!fromDashboard && (
            <OverviewRightContainer>
              <PaymentDetailsOverview
                order={3}
                initialValues={formValues}
                translator={t}
              />
              <Stack spacing={2}>
                <h5>
                  {t("titles.conditions", {
                    ns: "activationPortal"
                  })}
                </h5>
                <Checkbox
                  value={checkboxValue}
                  onChange={() => setCheckboxValue(!checkboxValue)}
                  name="conditions"
                  label={t("descriptions.conditions", {
                    ns: "activationPortal"
                  })}
                />
              </Stack>
            </OverviewRightContainer>
          )}
        </Grid>
      </Grid>
    </StepScreenLayout>
  );
};

export default ActivationOverviewSection;
