import { useEffect, useRef } from "react";
import { resolveAddressThunk } from "app/features";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectReportsState } from "app/data/selectors";

const useResolveAddress = (data: any) => {
  const dispatch = useAppDispatch();
  const { resolvedAddresses, resolvedAddressLoading } =
    useAppSelector(selectReportsState);

  const hasResolvedAddresses = useRef(false);

  useEffect(() => {
    if (
      !hasResolvedAddresses.current &&
      data?.length &&
      !resolvedAddressLoading
    ) {
      const uniqueCoordinates = new Set();

      data.forEach((row: any) => {
        const startKey = `${row.startLatitude},${row.startLongitude}`;
        const endKey = `${row.endLatitude},${row.endLongitude}`;

        if (
          row.startLatitude > 0 &&
          row.startLongitude > 0 &&
          !uniqueCoordinates.has(startKey)
        ) {
          uniqueCoordinates.add(startKey);
          const existingStartAddress = resolvedAddresses.find(
            (item) =>
              item.latitude === row.startLatitude &&
              item.longitude === row.startLongitude
          );

          if (!existingStartAddress) {
            dispatch(
              resolveAddressThunk({
                latitude: row.startLatitude,
                longitude: row.startLongitude
              })
            );
          }
        }

        if (
          row.endLatitude > 0 &&
          row.endLongitude > 0 &&
          !uniqueCoordinates.has(endKey)
        ) {
          uniqueCoordinates.add(endKey);
          const existingEndAddress = resolvedAddresses.find(
            (item) =>
              item.latitude === row.endLatitude &&
              item.longitude === row.endLongitude
          );

          if (!existingEndAddress) {
            dispatch(
              resolveAddressThunk({
                latitude: row.endLatitude,
                longitude: row.endLongitude
              })
            );
          }
        }
      });

      hasResolvedAddresses.current = true;
    }
  }, [data, resolvedAddressLoading, resolvedAddresses, dispatch]);

  return { resolvedAddresses, resolvedAddressLoading };
};

export default useResolveAddress;
