import React, { FC } from "react";
import {
  SuccessMessageContainer,
  Title,
  Subtitle,
  LogoWrapper,
  TrianglesWrapper,
  VectorSVGs,
  SuccessList,
  ListText
} from "./ActivateTheftSuccessSection.styled";
import { AllsetraTriangles, PlusTwo, SuccessCheck } from "assets/icons";

const ActivateTheftSuccessSection: FC = () => {
  return (
    <React.Fragment>
      <VectorSVGs>
        <PlusTwo style={{ position: "absolute", right: 0, top: "25vh" }} />
      </VectorSVGs>

      <LogoWrapper>
        <TrianglesWrapper>
          <AllsetraTriangles />
        </TrianglesWrapper>
      </LogoWrapper>

      <SuccessMessageContainer>
        <Title>Activatie succesvol afgerond!</Title>
        <Subtitle>
          Jouw diefstalbeveiliging is nu succesvol geactiveerd.
        </Subtitle>
        <SuccessList>
          <SuccessCheck />
          <ListText>
            Je ontvangt een diefstalbeveiligingscertificaat per e-mail. Check je
            spambox als je geen mail hebt ontvangen.
          </ListText>
        </SuccessList>
        <SuccessList>
          <SuccessCheck />
          <ListText>
            Bewaar het diefstalbeveiligingscertificaat goed en stuur het door
            naar je verzekeraar.
          </ListText>
        </SuccessList>
      </SuccessMessageContainer>
    </React.Fragment>
  );
};

export default ActivateTheftSuccessSection;
