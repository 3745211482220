import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectWhiteLabelsLoading = (state: RootState) =>
  state.rootReducer.whiteLabelsReducer.loading;

export const selectWhiteLabelsConfiguration = (state: RootState) =>
  state.rootReducer.whiteLabelsReducer.whiteLabel;

export const selectWhiteLabelsIsFetched = (state: RootState) =>
  state.rootReducer.whiteLabelsReducer.isFetched;

export const selectWhiteLabelsState = createSelector(
  selectWhiteLabelsLoading,
  selectWhiteLabelsConfiguration,
  selectWhiteLabelsIsFetched,
  (loading, whiteLabel, isFetched) => ({ loading, whiteLabel, isFetched })
);
