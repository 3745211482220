import { FC, useMemo } from "react";
import { Table, TableColumn } from "@vilocnv/allsetra-core";
import { WORKING_HOURS_DATA_LAYER } from "app/data/constants";
import { useResolveAddress } from "hooks";
import { useTranslation } from "react-i18next";

interface WorkingHoursExpendableRowCardProps {
  data: any;
}

const customStyles = {
  cells: {
    style: {
      justifyContent: "center"
    }
  }
};

const WorkingHoursExpendableRowCard: FC<WorkingHoursExpendableRowCardProps> = ({
  data
}) => {
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const { resolvedAddresses } = useResolveAddress(data?.data || []);

  const tableColumns: TableColumn<any>[] = useMemo(
    () => WORKING_HOURS_DATA_LAYER(resolvedAddresses),
    [resolvedAddresses]
  );

  return (
    <Table
      data={data.data}
      pagination={false}
      noTableHead={true}
      columns={tableColumns}
      customStyles={customStyles}
      translator={t}
    />
  );
};

export default WorkingHoursExpendableRowCard;
