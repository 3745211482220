import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const selectUsersReducerLoading = (state: RootState) =>
  state.rootReducer.usersReducer.loading;

export const selectAllUsers = (state: RootState) =>
  state.rootReducer.usersReducer.users;

export const selectTotalUsers = (state: RootState) =>
  state.rootReducer.usersReducer.totalUsers;

export const selectSpecificUserLoading = (state: RootState) =>
  state.rootReducer.usersReducer.specificUserLoading;

export const selectSpecificUser = (state: RootState) =>
  state.rootReducer.usersReducer.specificUser;

export const selectUsersWithRoleDriver = (state: RootState) =>
  state.rootReducer.usersReducer.usersWithRoleDriver;

export const selectUserRoles = (state: RootState) =>
  state.rootReducer.usersReducer.userRoles;

export const selectUserRolesLoading = (state: RootState) =>
  state.rootReducer.usersReducer.userRolesLoading;

export const selectUsersState = createSelector(
  selectUsersReducerLoading,
  selectTotalUsers,
  selectAllUsers,
  selectSpecificUser,
  selectUsersWithRoleDriver,
  selectSpecificUserLoading,
  (
    loading,
    totalUsers,
    allUsers,
    specificUser,
    usersWithRoleDriver,
    specificUserLoading
  ) => ({
    loading,
    totalUsers,
    allUsers,
    specificUser,
    specificUserLoading,
    usersWithRoleDriver
  })
);

export const selectUserRolesState = createSelector(
  selectUserRoles,
  selectUserRolesLoading,
  (userRoles, userRolesLoading) => ({
    userRoles,
    userRolesLoading
  })
);
