import { FC } from "react";
import { Stack } from "@mui/material";
import { TwoColsLayout } from "@vilocnv/allsetra-core";
import {
  InfoDetailContainer,
  SubscriptionInfoContainer
} from "../../activationPortalSections.styled";
import { transformObjectForActivationInfoTable } from "app/data/helpers";

interface Props {
  initialValues: any;
  installations?: any;
  translator: any;
}

const SubscriptionInformation: FC<Props> = ({
  initialValues,
  installations,
  translator
}) => {
  const objectInformation =
    transformObjectForActivationInfoTable(installations);
  return (
    <Stack spacing={4}>
      <SubscriptionInfoContainer>
        {Object.keys(objectInformation).map((item, i) => (
          <TwoColsLayout
            title={i === 0 ? translator("titles.objectInfo") : ""}
            fullWidth
            hideDivider
            key={`${i}`}
          >
            <InfoDetailContainer>
              <h6>{item}</h6>
              <p>{objectInformation[item]}</p>
            </InfoDetailContainer>
          </TwoColsLayout>
        ))}
      </SubscriptionInfoContainer>
      <SubscriptionInfoContainer>
        <TwoColsLayout
          title={translator("titles.deviceInformation")}
          fullWidth
          hideDivider
        >
          <InfoDetailContainer>
            <h6>
              {translator("deviceId.label", { ns: "formFieldsTranslation" })}
            </h6>
            <p>{initialValues.deviceId || ""}</p>
          </InfoDetailContainer>
        </TwoColsLayout>
        <TwoColsLayout fullWidth hideDivider>
          <InfoDetailContainer>
            <h6>
              {translator("deviceType.label", { ns: "formFieldsTranslation" })}
            </h6>
            <p>{initialValues.deviceType || ""}</p>
          </InfoDetailContainer>
        </TwoColsLayout>
      </SubscriptionInfoContainer>

      <SubscriptionInfoContainer>
        <TwoColsLayout
          title={translator("titles.serviceInformation")}
          fullWidth
          hideDivider
        >
          <InfoDetailContainer>
            <h6>
              {translator("serviceTypeName.label", {
                ns: "formFieldsTranslation"
              })}
            </h6>
            <p>{initialValues.serviceTypeName || ""}</p>
          </InfoDetailContainer>
        </TwoColsLayout>
        <TwoColsLayout fullWidth hideDivider>
          <InfoDetailContainer>
            <h6>
              {translator("serviceTypeDetails.label", {
                ns: "formFieldsTranslation"
              })}
            </h6>
            <p>{initialValues.serviceTypeDetails || ""}</p>
          </InfoDetailContainer>
        </TwoColsLayout>
      </SubscriptionInfoContainer>
    </Stack>
  );
};

export default SubscriptionInformation;
