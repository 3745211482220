import { FC } from "react";
import { useMapState } from "../Map/v2/GoogleMap/MapStateContext";
import useDashboardMap from "components/maps/DashboardMap/useDashboardMap";
import { MapFilterForm } from "@vilocnv/allsetra-core";
import MapLeftTop from "../Map/v1/children/MapLeftTop";

interface Props {
  showCustomSearchField?: boolean;
  isLoading?: boolean;
}

const DashboardMapTopLeftSection: FC<Props> = ({
  showCustomSearchField = false,
  isLoading = false
}) => {
  const { onObjectChange, showSearch, showFilter } = useMapState();

  const {
    filterOpen,
    setFilterOpen,
    mapFiltersSubmitHanlder,
    accountGroups,
    objectTypes,
    activeAccountKeys,
    accountKeysLoading,
    objectTypesLoading,
    accountGroupsLoading,
    theme,
    mapFilters,
    t,
    submitting
  } = useDashboardMap();

  const { markers, setIsSelectOpen } = useMapState();

  return (
    <>
      <MapFilterForm
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        onSubmit={mapFiltersSubmitHanlder}
        groups={accountGroups}
        types={objectTypes}
        keys={activeAccountKeys}
        dataLoading={
          accountKeysLoading || objectTypesLoading || accountGroupsLoading
        }
        theme={theme}
        initialValues={mapFilters}
        translator={t}
        submitting={submitting}
      />
      <MapLeftTop
        markers={markers}
        showSearch={showSearch}
        showFilter={showFilter}
        onObjectChange={onObjectChange}
        onFilterClick={() => setFilterOpen(true)}
        setIsSelectOpen={setIsSelectOpen}
        showCustomSearchField={showCustomSearchField}
        isLoading={isLoading}
      />
    </>
  );
};

export default DashboardMapTopLeftSection;
