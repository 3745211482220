import React, { FC } from "react";
import { useMapState } from "./MapStateContext";
import OneGeozone from "components/maps/common/Geozone";

const Geozone: FC = () => {
  const { geozones, radius, objectsMarker } = useMapState();

  return (
    <>
      {geozones && (
        <OneGeozone
          geozone={geozones}
          radius={radius}
          objectsMarker={objectsMarker}
        />
      )}
    </>
  );
};

export default React.memo(Geozone);
