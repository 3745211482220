import { FC } from "react";
import { Stack } from "@mui/material";
import { SubscriptionPlanCard } from "@vilocnv/allsetra-core";
import {
  getPricePerMonthText,
  getPricePerYear,
  getTaxInclusivePrice
} from "app/data/helpers";
import { cardColors } from "app/data/constants/activationPortalConstants";

interface Props {
  setSelectedPlan: (value: string) => void;
  subscriptions: Array<any>;
  selectedPlan: string | null;
  tabIndex?: number;
}

const SubscriptionPlans: FC<Props> = ({
  setSelectedPlan,
  subscriptions,
  selectedPlan,
  tabIndex
}) => {
  return (
    <Stack spacing={3}>
      {subscriptions ? (
        subscriptions.map((subscriptionCard: any, index: number) => {
          const pricePerYear = getPricePerYear(
            subscriptionCard.valuePerMonth,
            subscriptionCard.currency
          );

          const pricePerMonthText = getPricePerMonthText(
            subscriptionCard.durationInMonths,
            subscriptionCard.subscriptionType
          );

          const taxInclusivePrice = getTaxInclusivePrice(
            subscriptionCard.valuePerMonth,
            subscriptionCard.currency
          );

          return (
            <div
              key={index}
              onClick={() => setSelectedPlan(subscriptionCard.uniqueId)}
            >
              <SubscriptionPlanCard
                title={subscriptionCard.name}
                price={pricePerYear}
                color={cardColors[index]}
                description={subscriptionCard.details}
                helperText={pricePerMonthText}
                isSelected={subscriptionCard.uniqueId === selectedPlan}
                taxInclusivePrice={taxInclusivePrice}
                tabIndex={tabIndex}
              />
            </div>
          );
        })
      ) : (
        <div>No subscription available</div>
      )}
    </Stack>
  );
};

export default SubscriptionPlans;
