import { Stack, Typography } from "@mui/material";
import { SelectInputField } from "@vilocnv/allsetra-core";
import { SmallText } from "components/cards/ObjectDetailsCard/ObjectDetailsCard.styled";
import { FC } from "react";

interface Props {
  data: any | null;
  dataType: string;
}

const DataValuesAndNumber: FC<Props> = ({ data, dataType }) => {
  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#1D1F2B",
            textTransform: "capitalize"
          }}
        >
          {dataType}
        </Typography>
        <SmallText>
          {data.length ?? "0"} {dataType}
        </SmallText>
      </Stack>
      <SelectInputField
        name="showData"
        multiple
        searchable
        disabled
        label=""
        options={data}
        fullWidth
        optionLabelKey="name"
        optionValueKey="name"
        value={data.map((data: any) => data.name)}
        sx={{
          "& .MuiAutocomplete-popupIndicator": {
            display: "none"
          },
          "& .MuiAutocomplete-inputRoot": {
            pointerEvents: "none"
          }
        }}
      />
    </Stack>
  );
};

export default DataValuesAndNumber;
