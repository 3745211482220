import { FC } from "react";
import { useMapState } from "../Map/v2/GoogleMap/MapStateContext";
import { TopRightSection } from "../Map/Map.styled";
import MapRightTop from "../Map/v1/children/MapRightTop";

const DashboardMapTopRightSection: FC = () => {
  const {
    objectsMarker,
    isDashboardMap,
    showSatelliteMode,
    setShowSatelliteMode,
    showClusters,
    showTraffic,
    setShowTraffic,
    showObjectNames,
    setShowObjectNames,
    showGeozones,
    setShowGeozones,
    handleEnableClusterClick,
    handleShowGeozoneClick
  } = useMapState();

  return (
    <>
      <TopRightSection />
      <MapRightTop
        showSatelliteMode={showSatelliteMode}
        setShowSatelliteMode={setShowSatelliteMode}
        showClusters={showClusters}
        showTraffic={showTraffic}
        setShowTraffic={setShowTraffic}
        handleClusterClick={handleEnableClusterClick}
        showObjectNames={showObjectNames}
        setShowObjectNames={setShowObjectNames}
        objectsMarker={objectsMarker}
        showGeozones={showGeozones}
        setShowGeozones={setShowGeozones}
        handleShowGeozoneClick={handleShowGeozoneClick}
        isDashboardMap={isDashboardMap}
      />
    </>
  );
};

export default DashboardMapTopRightSection;
