import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectScheduleReportsReducerLoading = (state: RootState) =>
  state.rootReducer.scheduleReportsReducer.loading;

export const selectAllSchedules = (state: RootState) =>
  state.rootReducer.scheduleReportsReducer.allSchedules;

export const selectSpecificSchedule = (state: RootState) =>
  state.rootReducer.scheduleReportsReducer.specificSchedule;

export const selectReportFrequency = (state: RootState) =>
  state.rootReducer.scheduleReportsReducer.reportFrequency;

export const selectReportExportTypes = (state: RootState) =>
  state.rootReducer.scheduleReportsReducer.reportExportTypes;

export const selectScheduleReportsTotalRecords = (state: RootState) =>
  state.rootReducer.scheduleReportsReducer.totalRecords;

export const selectScheduleReportsState = createSelector(
  selectScheduleReportsReducerLoading,
  selectAllSchedules,
  selectSpecificSchedule,
  selectReportFrequency,
  selectReportExportTypes,
  selectScheduleReportsTotalRecords,
  (
    loading,
    allSchedules,
    specificSchedule,
    reportFrequency,
    reportExportTypes,
    totalRecords
  ) => {
    return {
      loading,
      allSchedules,
      specificSchedule,
      reportFrequency,
      reportExportTypes,
      totalRecords
    };
  }
);
