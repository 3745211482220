import { FC, useEffect, useState } from "react";
import ObjectTabsLayout from "components/common/objects/ObjectTabsLayout/ObjectTabsLayout";

// Data
import { useAppSelector } from "hooks";
import {
  selectDrawerSelectedAccountId,
  selectQueriedObjectsState
} from "app/data/selectors";
import ObjectRidesSection from "components/sections/objects/ObjectRidesSection/ObjectRidesSection";
import { useNavigate } from "react-router-dom";

const ObjectRides: FC = () => {
  const navigate = useNavigate();
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";
  const { specificObject } = useAppSelector(selectQueriedObjectsState);
  const [selectAccountId, setSelectedAccountId] = useState(
    drawerSelectedAccountId
  );

  useEffect(() => {
    if (drawerSelectedAccountId !== selectAccountId) {
      setSelectedAccountId(drawerSelectedAccountId);
      navigate("/dashboard/objects");
    }
  }, [drawerSelectedAccountId]);

  return (
    <main>
      <ObjectTabsLayout>
        <ObjectRidesSection specificObject={specificObject} />
      </ObjectTabsLayout>
    </main>
  );
};

export default ObjectRides;
