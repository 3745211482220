import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { KeyValueTable, types } from "@vilocnv/allsetra-core";
import ObjectDevicesCard from "components/cards/ObjectDevicesCard/ObjectDevicesCard";

interface Props {
  specificObject: types.IObject | null;
}

const DevicesTable: FC<Props> = ({ specificObject }) => {
  return (
    <Stack direction={"column"} spacing={2}>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#1D1F2B"
        }}
      >
        Devices
      </Typography>

      {specificObject?.devices?.length ? (
        specificObject?.devices.map((device, index) => (
          <ObjectDevicesCard key={index} device={device} />
        ))
      ) : (
        <KeyValueTable records={{ Devices: "None" }} />
      )}
    </Stack>
  );
};

export default DevicesTable;
