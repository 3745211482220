import { createSlice } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import {
  getAccountKeysByQueryThunk,
  getAvailableKeysByAccountThunk,
  getAllKeysByAccountThunk
} from "./keysManagerActions";
import { sortDataByAlphabet } from "app/data/helpers";

export interface IKeysManagerState {
  loading: boolean;
  totalRecords: number | null;
  accountKeys: Array<types.IKey>;

  accountAvailableKeysLoading: boolean;
  accountAvailableKeys: Array<types.IKey>;
}

const initialState: IKeysManagerState = {
  loading: false,
  totalRecords: null,
  accountKeys: [],

  accountAvailableKeysLoading: false,
  accountAvailableKeys: []
};

const keysManagerSlice = createSlice({
  name: "keysManager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // All Keys
    builder.addCase(getAllKeysByAccountThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllKeysByAccountThunk.fulfilled, (state, action) => {
      state.accountKeys = sortDataByAlphabet(action?.payload, "label");
      state.loading = false;
    });

    builder.addCase(getAllKeysByAccountThunk.rejected, (state) => {
      state.loading = false;
    });

    // Search Keys
    builder.addCase(getAccountKeysByQueryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAccountKeysByQueryThunk.fulfilled, (state, action) => {
      state.accountKeys = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.loading = false;
    });

    builder.addCase(getAccountKeysByQueryThunk.rejected, (state) => {
      state.accountKeys = [];
      state.totalRecords = 0;
      state.loading = false;
    });

    // Available Keys
    builder.addCase(getAvailableKeysByAccountThunk.pending, (state) => {
      state.accountAvailableKeysLoading = true;
    });

    builder.addCase(
      getAvailableKeysByAccountThunk.fulfilled,
      (state, action) => {
        state.accountAvailableKeys = action.payload || [];
        state.accountAvailableKeysLoading = false;
      }
    );

    builder.addCase(getAvailableKeysByAccountThunk.rejected, (state) => {
      state.accountAvailableKeysLoading = false;
    });
  }
});

export * from "./keysManagerActions";
export * from "./keysManagerQueries";
export const {} = keysManagerSlice.actions;

export default keysManagerSlice.reducer;
