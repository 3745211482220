import axiosInstance from "app/axiosInstance";

class ExportedReportHistoryService {
  static getAllExportedReportsHistory = async (accountId: string) => {
    return await axiosInstance.get(`/reports/${accountId}/history`);
  };

  static getSpecificExportedReportHistory = async (
    accountId: string,
    uniqueId: string
  ) => {
    return await axiosInstance.get(`/reports/${accountId}/history/${uniqueId}`);
  };

  static searchExportedReport = async (accountId: string, data: any) => {
    return await axiosInstance.post(
      `/reports/${accountId}/history/search`,
      data
    );
  };

  static deleteExportedReport = async (accountId: string, uniqueId: string) => {
    return await axiosInstance.delete(
      `/reports/${accountId}/history/${uniqueId}`
    );
  };

  static downloadReportFile = async (
    accountId: string,
    id: string,
    fileId: string,
    fileType: string
  ) => {
    let contentType = "application/pdf";

    if (fileType === "CSV") contentType = "text/csv";

    if (fileType === "XLSX")
      contentType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    return await axiosInstance.get(
      `/reports/${accountId}/history/downloadfile/${id}/${fileId}`,
      {
        headers: {
          "Content-Type": contentType,
          Accept: contentType
        },
        responseType: "arraybuffer"
      }
    );
  };
}

export default ExportedReportHistoryService;
