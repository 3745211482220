import { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SupportingDrawerLayout,
  GeozonesListing,
  types,
  DeleteConfirmationModal,
  toast,
  PageLoader
} from "@vilocnv/allsetra-core";
import { Box, useTheme } from "@mui/material";
import GeozonesMap from "components/maps/GeozonesMap/GeozonesMap";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectGeozonesState,
  selectDrawerSelectedAccountId
} from "app/data/selectors";
import {
  deleteGeozoneThunk,
  getAllGeozonesThunk,
  getAllObjectsThunk,
  setActiveGeozone,
  setActiveGeozoneId
} from "app/features";
import { SignalRService } from "app/data/services";
import { useTranslation } from "react-i18next";

const Geozones: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const {
    allGeozones,
    loading: allGeozonesLoading,
    geozoneAssignedObjects,
    activeGeozoneObjects
  } = useAppSelector(selectGeozonesState);

  console.log("geozoneAssignedObjects", {
    geozoneAssignedObjects,
    activeGeozoneObjects
  });

  // Local State
  const [showSearchTopbar, setShowSearchTopbar] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [drawingMode, setDrawingMode] = useState(false);
  const [drawingShape, setDrawingShape] = useState("circle");
  const [circleSize, setCircleSize] = useState(10000);
  const [creationMode, setCreationMode] = useState<"add" | "edit" | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedGeozoneId, setSelectedGeozoneId] = useState<string | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (state?.geozoneId) {
      setDrawerOpen(false);
      setShowSearchTopbar(true);
      setCreationMode("edit");
    } else {
      dispatch(setActiveGeozoneId(null));
    }
  }, [state]);

  useEffect(() => {
    dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
    dispatch(getAllObjectsThunk(drawerSelectedAccountId || ""));
  }, [drawerSelectedAccountId]);

  const resetToGeozonesListing = useCallback(() => {
    setShowSearchTopbar(false);
    setDrawerOpen(true);
    setDrawingMode(false);
  }, []);

  const onAddGeozone = useCallback(() => {
    setDrawerOpen(false);
    setShowSearchTopbar(true);
    setDrawingMode(true);
    setCreationMode("add");
  }, []);

  const onEditGeozone = useCallback((geozone: types.IGeozone) => {
    if (isEmpty(geozone)) return null;
    setDrawerOpen(false);
    setShowSearchTopbar(true);
    setCreationMode("edit");
    dispatch(setActiveGeozoneId(geozone?.uniqueId || ""));
  }, []);

  const openDeleteConfirmationModal = useCallback((geozone: types.IGeozone) => {
    setSelectedGeozoneId(geozone.uniqueId);
    setOpenDeleteModal(true);
  }, []);

  const onFulfilledRequest = async () => {
    setIsDeleting(false);
    setOpenDeleteModal(false);
    toast.success("Geozone has been deleted successfully.");
    await dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
  };

  const onDeleteGeozone = async () => {
    setIsDeleting(true);

    const { type } = await dispatch(
      deleteGeozoneThunk({
        accountId: drawerSelectedAccountId ?? "",
        accountGeofenceId: selectedGeozoneId ?? ""
      })
    );
    if (type === "geozones/deleteGeozoneThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onFulfilledRequest();
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.GeozoneRemovedFromAccountEvent
        ) {
          onFulfilledRequest();

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      setIsDeleting(false);
    }
  };

  const onCardClick = useCallback((geozone: types.IGeozone) => {
    dispatch(setActiveGeozone(geozone));
    navigate(`/dashboard/geozones/${geozone.uniqueId}`);
  }, []);

  return (
    <main>
      <SupportingDrawerLayout open={drawerOpen}>
        {allGeozonesLoading ? (
          <PageLoader sx={{ width: "100%" }} />
        ) : (
          <GeozonesListing
            geozones={allGeozones ?? []}
            onAddGeozone={onAddGeozone}
            onEditGeozone={onEditGeozone}
            onDeleteGeozone={openDeleteConfirmationModal}
            onCardClick={onCardClick}
            theme={theme}
            translator={t}
          />
        )}
      </SupportingDrawerLayout>
      <Box sx={{ marginLeft: drawerOpen ? "400px" : "0" }}>
        <GeozonesMap
          creationMode={creationMode}
          geozones={allGeozones ?? []}
          objects={creationMode === "edit" ? activeGeozoneObjects : []}
          showSearchTopbar={showSearchTopbar}
          drawingMode={drawingMode}
          setDrawingMode={setDrawingMode}
          resetToGeozonesListing={resetToGeozonesListing}
          drawingShape={drawingShape}
          setDrawingShape={setDrawingShape}
          circleSize={circleSize}
          setCircleSize={setCircleSize}
        />
      </Box>
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("typography.deleteGeozoneTitle")}
        subTitle={t("typography.deleteGeozoneSubtitle")}
        primaryBtnProps={{
          id: "geozone-delete",
          onClick: onDeleteGeozone,
          loading: isDeleting,
          text: t("buttonsText.delete")
        }}
        secondaryBtnProps={{
          text: t("buttonsText.cancel")
        }}
      />
    </main>
  );
};

export default Geozones;
