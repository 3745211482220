import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Box,
  styled
} from "@mui/material";

export const Accordion = styled(MuiAccordion)({
  expanded: {
    marginTop: "0px"
  }
});

export const AccordionSummary = styled(MuiAccordionSummary)({
  backgroundColor: "rgba(249, 251, 255, 1)",
  borderTop: "none",
  borderBottom: "none",
  flexDirection: "row-reverse"
});

export const AccordionDetailsBox = styled(Box)({
  width: "100%",
  maxWidth: "100%"
});

export const SummaryContentBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  paddingLeft: "8px"
});

export const RidesTextBox = styled(Box)({
  color: "rgba(0, 178, 163, 1)",
  fontWeight: 500
});
