import { createSlice } from "@reduxjs/toolkit";
import { getAllPointsOfInterestThunk } from "./pointsOfInterestActions";

interface IPointsOfInterest {
  loading: boolean;
  records: Array<any>;
}

const initialState: IPointsOfInterest = { loading: false, records: [] };

const pointsOfInterestSlice = createSlice({
  name: "pointsOfInterest",
  initialState,
  reducers: {
    resetPointsOfInterestState: (state) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPointsOfInterestThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllPointsOfInterestThunk.fulfilled, (state, action) => {
      state.records = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllPointsOfInterestThunk.rejected, (state) => {
      state.loading = false;
    });
  }
});

export * from "./pointsOfInterestActions";
export const { resetPointsOfInterestState } = pointsOfInterestSlice.actions;

export default pointsOfInterestSlice.reducer;
