import i18n from "app/integrations/i18n";
import { useEffect } from "react";

const useSetLanguage = () => {
  useEffect(() => {
    const browserLanguage = navigator.language;
    const lang = browserLanguage.split("-")[0];
    const supportedLangs = ["en", "nl"];
    const setLanguage = supportedLangs.includes(lang) ? lang : "nl";

    i18n.changeLanguage(setLanguage);
  }, []);
};

export default useSetLanguage;
