import { Box, styled } from "@mui/material";

export const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#F9FBFF",
  border: "1px solid #DFE5F4",
  borderRadius: "6px",
  padding: "2.5px",
  width: "245px",
  marginRight: "16px"
});

export const CustomButton = styled(Box)<{ active: boolean; theme: any }>(
  ({ active, theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: active ? theme.palette.primary.main : "transparent",
    color: active ? "#FFFF" : "#646D7B",
    height: "32px",
    borderRadius: "4px",
    padding: "5px",
    fontSize: "14px",
    fontWeight: 500,
    width: "55px",
    lineHeight: "15.68px",
    "&:hover": {
      cursor: "pointer"
    }
  })
);
