import React, { FC } from "react";
import { useTheme } from "@mui/material";
import {
  SuccessMessageContainer,
  Title,
  Subtitle,
  LogoWrapper,
  TrianglesWrapper,
  VectorSVGs
} from "./ReportTheftSuccessSection.styled";
import { AllsetraTriangles, PlusTwo } from "assets/icons";

const ReportTheftSuccessSection: FC = () => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <VectorSVGs>
        <PlusTwo style={{ position: "absolute", right: 0, top: "25vh" }} />
      </VectorSVGs>

      <LogoWrapper>
        <TrianglesWrapper>
          <AllsetraTriangles />
        </TrianglesWrapper>
      </LogoWrapper>

      <SuccessMessageContainer>
        <Title>Diefstal succesvol gemeld!</Title>
        <Subtitle>
          Jouw diefstal is{" "}
          <span style={{ color: theme.palette.primary.main }}>succesvol</span>{" "}
          gemeld bij ons opsporingsdienst ABS-Tracer.
        </Subtitle>
        <Subtitle style={{ marginTop: 10 }}>
          Wij gaan direct aan de slag om jouw gestolen fiets op te sporen en
          zullen zo spoedig mogelijk contact opnemen voor verdere opvolging.
        </Subtitle>
      </SuccessMessageContainer>
    </React.Fragment>
  );
};

export default ReportTheftSuccessSection;
