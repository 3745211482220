import { FC, Dispatch, SetStateAction } from "react";
import { useTheme } from "@mui/material";
import { Button } from "@vilocnv/allsetra-core";
import { GroupBy } from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { ButtonContainer, CustomButton } from "./ReportDateFormats.styled";

interface FilterButtonProps {
  dateFormat: string;
  setDateFormat: Dispatch<SetStateAction<GroupBy>>;
}

const ReportDateFormats: FC<FilterButtonProps> = ({
  dateFormat,
  setDateFormat
}) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <CustomButton
        id="reports-filter-day"
        theme={theme}
        active={dateFormat === "day"}
        onClick={() => setDateFormat("day")}
      >
        {t("dateFormats.day")}
      </CustomButton>
      <CustomButton
        id="reports-filter-week"
        theme={theme}
        active={dateFormat === "week"}
        onClick={() => setDateFormat("week")}
      >
        {t("dateFormats.week")}
      </CustomButton>
      <CustomButton
        id="reports-filter-month"
        theme={theme}
        active={dateFormat === "month"}
        onClick={() => setDateFormat("month")}
      >
        {t("dateFormats.month")}
      </CustomButton>
      <CustomButton
        id="reports-filter-year"
        theme={theme}
        active={dateFormat === "year"}
        onClick={() => setDateFormat("year")}
      >
        {t("dateFormats.year")}
      </CustomButton>
    </ButtonContainer>
  );
};

export default ReportDateFormats;
