import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import {
  ContentSectionLayout,
  FormikTimeFrameField
} from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";

const WorkingHoursForm: FC = () => {
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);
  const theme = useTheme();

  return (
    <Box>
      <ContentSectionLayout
        title={t("titles.userWorkingHours")}
        subTitle={t("descriptions.userWorkingHours")}
      >
        <FormikTimeFrameField
          theme={theme}
          label={t("defaultWorkingHours.label", {
            ns: "formFieldsTranslation"
          })}
          typeName="workingHoursType"
          name="workingHours.workingHoursSchedule"
          translator={t}
        />
      </ContentSectionLayout>
    </Box>
  );
};

export default WorkingHoursForm;
