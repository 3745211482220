import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast, types } from "@vilocnv/allsetra-core";
import { ExportedReportHistoryService } from "app/data/services";

export const getAllExportedReportsHistoryThunk = createAsyncThunk(
  "exportedReportsHistory/getAllExportedReportsHistory",
  async (accountId: string) => {
    try {
      const response =
        await ExportedReportHistoryService.getAllExportedReportsHistory(
          accountId
        );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificExportedReportThunk = createAsyncThunk(
  "exportedReportsHistory/getSpecificExportedReportThunk",
  async ({ accountId, uniqueId }: { accountId: string; uniqueId: string }) => {
    try {
      const response =
        await ExportedReportHistoryService.getSpecificExportedReportHistory(
          accountId,
          uniqueId
        );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const searchExportedReportThunk = createAsyncThunk(
  "exportedReportsHistory/searchExportedReportThunk",
  async ({
    accountId,
    params
  }: {
    accountId: string;
    params?: types.IRecordsAggregationBody;
  }) => {
    try {
      const response = await ExportedReportHistoryService.searchExportedReport(
        accountId,
        params
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deleteExportedReportThunk = createAsyncThunk(
  "exportedReportsHistory/deleteReportScheduleThunk",
  async ({ accountId, uniqueId }: { accountId: string; uniqueId: string }) => {
    try {
      const response = await ExportedReportHistoryService.deleteExportedReport(
        accountId,
        uniqueId
      );

      if (response.status !== 202) {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const downloadReportFileThunk = createAsyncThunk(
  "exportedReportsHistory/downloadReportFileThunk",
  async ({
    accountId,
    id,
    fileId,
    fileType
  }: {
    accountId: string;
    id: string;
    fileId: string;
    fileType: string;
  }) => {
    try {
      const response = await ExportedReportHistoryService.downloadReportFile(
        accountId,
        id,
        fileId,
        fileType
      );

      if (response.status !== 200) {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
