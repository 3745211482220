import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "@vilocnv/allsetra-core";
import { ActivationPortal } from "app/data/services";

export const getInstallationThunk = createAsyncThunk(
  "activationPortal/getInstallationThunk",
  async ({ activationCode }: { activationCode: string }) => {
    try {
      const response = await ActivationPortal.getInstallation(activationCode);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSubscriptionThunk = createAsyncThunk(
  "activationPortal/getSubscriptionThunk",
  async ({ activationCode }: { activationCode: string }) => {
    try {
      const response = await ActivationPortal.getSubscriptions(activationCode);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getInformationSecurityQuestionsThunk = createAsyncThunk(
  "activationPortal/getInformationSecurityQuestionsThunk",
  async () => {
    try {
      const response = await ActivationPortal.getInformationSecurityQuestions();

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const activateSubscriptionThunk = createAsyncThunk(
  "activationPortal/activateSubscriptionThunk",
  async (data: any) => {
    try {
      const response = await ActivationPortal.activateSubscription(data);

      if (response.status === 202) {
        toast.success("Subscription has been activated");
      }

      return response;
    } catch (e: any) {
      toast.error("Subscription could not be activated");
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllUserAccountsThunk = createAsyncThunk(
  "activationPortal/getAllUserAccountsThunk",
  async () => {
    try {
      const response = await ActivationPortal.getMyAccounts();
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
