import { useState } from "react";
import { IStepItem } from "components/layouts/StepperDrawerLayout/StepperDrawerLayout";

const useStepDrawerController = (initialSteps: Array<IStepItem>) => {
  const [steps, setSteps] = useState<Array<IStepItem>>(initialSteps);

  const [activeStepIndex, setActiveStepIndex] = useState<number>(
    initialSteps[0].index
  );

  const isLastStepActive = () => {
    const stepIndex = steps.findIndex((s) => s.isActive);
    return stepIndex === steps.length - 1;
  };

  const onCompletedStepClick = (step: IStepItem) => {
    const isLastStep = isLastStepActive();

    if (isLastStep) return;

    const stepIndex = steps.findIndex((s) => s.name === step.name);

    // Return if item is not found
    if (stepIndex === -1) return;

    const updatedSteps = steps.map((s, index) => {
      if (index === stepIndex) {
        s.isActive = true;
        s.isCompleted = false;
        setActiveStepIndex(s.index);
      } else if (index < stepIndex) {
        s.isActive = false;
        s.isCompleted = true;
      } else {
        s.isActive = false;
        s.isCompleted = false;
      }

      return s;
    });

    setSteps(updatedSteps);
  };

  const gotoNextStep = () => {
    const _activeStepIndex = steps.findIndex(
      (step) => step.index === activeStepIndex
    );

    // Return if item is not found
    if (_activeStepIndex === -1) return;

    const updatedSteps = [...steps];

    // Update the current step
    updatedSteps[_activeStepIndex].isActive = false;
    updatedSteps[_activeStepIndex].isCompleted = true;

    // If it is not the last step
    if (_activeStepIndex !== steps.length - 1) {
      updatedSteps[_activeStepIndex + 1].isActive = true;
      setActiveStepIndex(updatedSteps[_activeStepIndex + 1].index);
    }

    setSteps(updatedSteps);
  };

  return { steps, activeStepIndex, onCompletedStepClick, gotoNextStep };
};

export default useStepDrawerController;
