import { createAsyncThunk } from "@reduxjs/toolkit";
import { PointsOfInterest } from "app/data/services";

export const getAllPointsOfInterestThunk = createAsyncThunk(
  "pointsOfInterest/getAllPointsOfInterestThunk",
  async (accountId: string) => {
    try {
      const response = await PointsOfInterest.getAllPointsOfInterest(accountId);

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const createOrUpdatePointsOfInterestThunk = createAsyncThunk(
  "pointsOfInterest/createOrUpdatePointsOfInterestThunk",
  async ({ accountId, data }: { accountId: string; data: any }) => {
    try {
      const response = data.uniqueId
        ? await PointsOfInterest.updatePointOfInterest(accountId, data)
        : await PointsOfInterest.createPointOfInterest(accountId, data);

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deletePointOfInterestThunk = createAsyncThunk(
  "pointsOfInterest/deletePointOfInterestThunk",
  async ({ accountId, itemId }: { accountId: string; itemId: any }) => {
    try {
      const response = await PointsOfInterest.deletePointOfInterest(
        accountId,
        itemId
      );

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
