import { FC } from "react";
import { Theme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import InnerForm from "./children/InnerForm";
import { Modal, ModalProps } from "@vilocnv/allsetra-core";
import {
  reportsTagFilterFormInitialValues,
  reportsTagFilterFormValidationSchema
} from "app/data/helpers";
import { useTranslation } from "react-i18next";

export type TagFilterFormProps = Pick<ModalProps, "open" | "onClose"> & {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  theme: Theme;
  dataLoading?: boolean;
  groups: Array<any>;
  objectTypes: Array<any>;
  objects: Array<any>;
  submitting?: boolean;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
  isReportsHistoryPage?: boolean;
};

const TagFilterForm: FC<TagFilterFormProps> = ({
  open,
  onClose,
  onSubmit,
  theme,
  dataLoading = false,
  groups,
  objects,
  objectTypes,
  submitting = false,
  fetchOnDebounce,
  onChange,
  isReportsHistoryPage = false
}) => {
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  return (
    <Formik
      initialValues={reportsTagFilterFormInitialValues}
      validationSchema={reportsTagFilterFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, isValid, resetForm, values }) => (
        <Modal
          open={open}
          onClose={() => {
            resetForm();
            onClose();
          }}
          title="Filters"
          primaryBtnProps={{
            type: "submit",
            text: t("buttonsText.apply"),
            loading: isSubmitting || submitting,
            // @ts-ignore
            onClick: handleSubmit,
            disabled: !isValid
          }}
          secondaryBtnProps={{
            text: t("buttonsText.cancel"),
            onClick: onClose
          }}
          theme={theme}
          size="customSize"
        >
          <InnerForm
            groups={groups}
            objectTypes={objectTypes}
            objects={objects}
            dataLoading={dataLoading}
            translator={t}
            fetchOnDebounce={fetchOnDebounce}
            onChange={onChange}
            isReportsHistoryPage={isReportsHistoryPage}
            values={values}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default TagFilterForm;
