import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { Button, FormikInputField, toast } from "@vilocnv/allsetra-core";
import BasicPageLayout from "components/layouts/BasicPageLayout";
import { CtaText } from "pages/activationPortal/activationPortal.styled";

// Data
import { useAppSelector } from "hooks";
import { selectWhiteLabelsState } from "app/data/selectors";
import {
  resetPasswordInitialValues,
  resetPasswordValidationSchema
} from "app/data/helpers";
import { IResetPassword } from "app/data/types";
import { AuthService } from "app/data/services";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import useSetLanguage from "hooks/useSetLanguage";

const ResetPassword: FC = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  useSetLanguage();

  const { whiteLabel, loading } = useAppSelector(selectWhiteLabelsState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  // Local State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);

  const submitHandler = (values: IResetPassword) => {
    setIsLoading(true);

    AuthService.authVerifyEmail({
      email: values.email,
      whitelabelId: whiteLabel?.uniqueId ?? null
    })
      .then((res) => {
        if (res.status === 202) {
          setEmail(values.email);
        } else {
          toast.error("Server side error occured.");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Server side error occured.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BasicPageLayout
      title={t("titles.resetYourPassword")}
      whiteLabelBgImage={whiteLabel?.backgroundImageUrl}
      loading={loading}
      {...(!isEmpty(email)
        ? {
            subtitle: `We’ve sent a verification email to ${email}. Please follow the link in the email to proceed with resetting.`
          }
        : null)}
    >
      <Formik
        initialValues={resetPasswordInitialValues}
        validationSchema={resetPasswordValidationSchema}
        onSubmit={submitHandler}
        validateOnMount
        enableReinitialize
      >
        {({ isValid, handleSubmit }) => (
          <Stack gap={2}>
            {isEmpty(email) && (
              <Fragment>
                <FormikInputField
                  theme={theme}
                  type="email"
                  label={t("email.label", {
                    ns: "formFieldsTranslation"
                  })}
                  placeholder={t("email.label", {
                    ns: "formFieldsTranslation"
                  })}
                  name="email"
                  colorTheme={isDownMd ? "light" : "dark"}
                />
                <Button
                  theme={theme}
                  disabled={!isValid}
                  loading={isLoading}
                  // @ts-ignore
                  onClick={handleSubmit}
                >
                  {t("buttonsText.send")}
                </Button>
              </Fragment>
            )}
            <CtaText>
              {t("typography.backTo")}{" "}
              <span onClick={() => navigate("/")}>Login page</span>
            </CtaText>
          </Stack>
        )}
      </Formik>
    </BasicPageLayout>
  );
};

export default ResetPassword;
