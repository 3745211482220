import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveMapIcon: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 7.5C11.6667 8.42 10.92 9.16667 10 9.16667C9.08 9.16667 8.33333 8.42 8.33333 7.5C8.33333 6.58 9.08 5.83333 10 5.83333C10.92 5.83333 11.6667 6.58 11.6667 7.5Z"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 7.5C15 11.6667 10 18.3333 10 18.3333C10 18.3333 5 11.6667 5 7.5C5 4.73917 7.23917 2.5 10 2.5C12.7608 2.5 15 4.73917 15 7.5Z"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActiveMapIcon;
