import { Table } from "@vilocnv/allsetra-core";
import { FC, memo } from "react";
import CO2ReportFinalData from "../CO2ReportFinalData/CO2ReportFinalData";
import { CO2_REPORT_DATE_RENDERING } from "app/data/constants";
import { useTranslation } from "react-i18next";

interface CO2ReportExpendableRowCardProps {
  data: any;
}

const CO2ReportExpendableRowCard: FC<CO2ReportExpendableRowCardProps> = ({
  data
}) => {
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  return (
    <Table
      columns={CO2_REPORT_DATE_RENDERING}
      data={data.records}
      expandableRows
      expandOnRowClicked
      expandableRowsComponent={CO2ReportFinalData}
      pagination={false}
      noTableHead={true}
      translator={t}
    />
  );
};

export default memo(CO2ReportExpendableRowCard);
