import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const selectObjectsReducerLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.loading;

export const selectAllTheObjects = (state: RootState) =>
  state.rootReducer.objectsReducer.allObjects;

export const selectAllBasicObjects = (state: RootState) =>
  state.rootReducer.objectsReducer.basicObjects;

export const selectObjectsQueried = (state: RootState) =>
  state.rootReducer.objectsReducer.objects;

export const selectSideMenuObjectsQueried = (state: RootState) =>
  state.rootReducer.objectsReducer.sideMenuObjects;

export const selectObjectsListingView = (state: RootState) =>
  state.rootReducer.objectsReducer.objectsListingView;

export const selectSideMenuObjectsPageNumber = (state: RootState) =>
  state.rootReducer.objectsReducer.sideMenuObjectsPageNumber;

export const selectSideMenuObjectsLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.sideMenuObjectsLoading;

export const selectSideMenuObjectsTotalRecords = (state: RootState) =>
  state.rootReducer.objectsReducer.totalSideMenuRecords;

export const selectObjectsTotalRecords = (state: RootState) =>
  state.rootReducer.objectsReducer.totalRecords;

export const selectSpecificObjectLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.specificObjectLoading;

export const selectSpecificObject = (state: RootState) =>
  state.rootReducer.objectsReducer.specificObject;

export const selectObjectActiveTabIndex = (state: RootState) =>
  state.rootReducer.objectsReducer.objectActiveTabIndex;

export const selectObjectRidesLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.ridesLoading;

export const selectObjectRidesQueried = (state: RootState) =>
  state.rootReducer.objectsReducer.objectRides;

export const selectObjectRidesRowCount = (state: RootState) =>
  state.rootReducer.objectsReducer.objectRidesRowCount;

export const selectObjectSpecificRide = (state: RootState) =>
  state.rootReducer.objectsReducer.specificRide;

export const selectObjectRideLocationsLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.objectRideLocationsLoading;

export const selectObjectRideLocations = (state: RootState) =>
  state.rootReducer.objectsReducer.objectRideLocations;

export const selectObjectAlarmsConfigLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.alarmsConfigLoading;

export const selectObjectAlarmsConfig = (state: RootState) =>
  state.rootReducer.objectsReducer.alarmsConfig;

export const selectActiveObjectId = (state: RootState) =>
  state.rootReducer.objectsReducer.activeObjectId;

export const selectObjectFeaturesLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.objectFeaturesLoading;

export const selectObjectFeaturesList = (state: RootState) =>
  state.rootReducer.objectsReducer.objectFeaturesList;

export const selectObjectFeaturesConfig = (state: RootState) =>
  state.rootReducer.objectsReducer.objectFeaturesConfig;

// Markers State
export const selectObjectMarkerLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.markerLoading;

export const selectObjectSelectedMarker = (state: RootState) =>
  state.rootReducer.objectsReducer.selectedMarker;

export const selectObjectMapMarkers = (state: RootState) =>
  state.rootReducer.objectsReducer.markers;

export const selectObjectMapShouldRenderMarkers = (state: RootState) =>
  state.rootReducer.objectsReducer.shouldRenderMarkers;

export const selectActiveObjectAlarmsConfig = createSelector(
  selectObjectAlarmsConfigLoading,
  selectObjectAlarmsConfig,
  (alarmsConfigLoading, alarmsConfig) => ({
    alarmsConfigLoading,
    alarmsConfig
  })
);

export const selectQueriedObjectsState = createSelector(
  selectObjectsReducerLoading,
  selectObjectsQueried,
  selectObjectsTotalRecords,
  selectSpecificObject,
  selectSideMenuObjectsQueried,
  selectObjectsListingView,
  selectSideMenuObjectsPageNumber,
  selectSideMenuObjectsLoading,
  selectSideMenuObjectsTotalRecords,
  (
    loading,
    objects,
    totalRecords,
    specificObject,
    sideMenuObjects,
    objectsListingView,
    sideMenuObjectsPageNumber,
    sideMenuObjectsLoading,
    totalSideMenuRecords
  ) => ({
    loading,
    objects,
    totalRecords,
    specificObject,
    sideMenuObjects,
    objectsListingView,
    sideMenuObjectsPageNumber,
    sideMenuObjectsLoading,
    totalSideMenuRecords
  })
);

export const selectObjectsState = createSelector(
  selectAllTheObjects,
  selectObjectsReducerLoading,
  selectAllBasicObjects,
  (allObjects, loading, basicObjects) => ({
    allObjects,
    loading,
    basicObjects
  })
);

export const selectSpecificObjectState = createSelector(
  selectSpecificObjectLoading,
  selectSpecificObject,
  selectActiveObjectId,
  selectObjectMarkerLoading,
  selectObjectSelectedMarker,
  (
    specificObjectLoading,
    specificObject,
    activeObjectId,
    markerLoading,
    selectedMarker
  ) => ({
    specificObjectLoading,
    specificObject,
    activeObjectId,
    markerLoading,
    selectedMarker
  })
);

export const selectObjectRidesState = createSelector(
  selectObjectRidesLoading,
  selectObjectRidesQueried,
  selectObjectRidesRowCount,
  selectObjectSpecificRide,
  selectObjectRideLocationsLoading,
  selectObjectRideLocations,
  (
    ridesLoading,
    objectRides,
    objectRidesRowCount,
    specificRide,
    objectRideLocationsLoading,
    objectRideLocations
  ) => ({
    ridesLoading,
    objectRides,
    objectRidesRowCount,
    specificRide,
    objectRideLocationsLoading,
    objectRideLocations
  })
);

export const selectObjectFeaturesState = createSelector(
  selectObjectFeaturesLoading,
  selectObjectFeaturesList,
  selectObjectFeaturesConfig,
  (objectFeaturesLoading, objectFeaturesList, objectFeaturesConfig) => ({
    objectFeaturesLoading,
    objectFeaturesList,
    objectFeaturesConfig
  })
);

export const selectObjectMapState = createSelector(
  selectObjectMarkerLoading,
  selectObjectMapMarkers,
  selectObjectMapShouldRenderMarkers,
  selectObjectSelectedMarker,
  (markerLoading, markers, shouldRenderMarkers, selectedMarker) => ({
    markerLoading,
    markers,
    shouldRenderMarkers,
    selectedMarker
  })
);
