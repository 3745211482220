import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectSettingsReducerLoading = (state: RootState) =>
  state.rootReducer.settingsReducer.loading;

export const selectSpecificSettings = (state: RootState) =>
  state.rootReducer.settingsReducer.specificSetting;

export const selectAllLanguages = (state: RootState) =>
  state.rootReducer.settingsReducer.languages;

export const selectLanguagesLoading = (state: RootState) =>
  state.rootReducer.settingsReducer.languagesLoading;

export const selectAllSafetyQuestions = (state: RootState) =>
  state.rootReducer.settingsReducer.safetyQuestions;

export const selectAllSafetyQuestionsLoading = (state: RootState) =>
  state.rootReducer.settingsReducer.safetyQuestionsLoading;

export const selectCountryList = (state: RootState) =>
  state.rootReducer.settingsReducer.countries;

export const selectCountryListLoading = (state: RootState) =>
  state.rootReducer.settingsReducer.countryListLoading;

export const selectLanguageState = createSelector(
  selectAllLanguages,
  selectLanguagesLoading,
  (languages, languagesLoading) => ({
    languages,
    languagesLoading
  })
);

export const selectSettingsState = createSelector(
  selectSettingsReducerLoading,
  selectSpecificSettings,
  selectLanguageState,
  selectAllSafetyQuestions,
  selectAllSafetyQuestionsLoading,
  selectCountryList,
  selectCountryListLoading,
  (
    loading,
    specificSetting,
    { languages, languagesLoading },
    safetyQuestions,
    safetyQuestionsLoading,
    countries,
    countryListLoading
  ) => ({
    loading,
    specificSetting,
    languages,
    languagesLoading,
    safetyQuestions,
    safetyQuestionsLoading,
    countries,
    countryListLoading
  })
);
