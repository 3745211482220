import axios from "axios";
import { AzureMsal } from "./data/services";
import * as Sentry from "@sentry/react";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ACTIVATION_PORTAL_BASE_URL,
  responseType: "json"
});

instance.interceptors.request.use(
  async function (config) {
    config.headers["X-Subscription"] =
      process.env.REACT_APP_ACTIVATION_PORTAL_HEADER_SUBSCRIPTION;

    if (config.headers.isNotProtected) {
      return config;
    }

    try {
      const accessTokenResponse = await AzureMsal.acquireToken();

      if (accessTokenResponse) {
        config.headers["Authorization"] =
          `bearer ${accessTokenResponse.idToken}`;
      }
    } catch (error) {
      console.log(error);
    } finally {
      return config;
    }
  },
  function (error) {
    // Log error to Sentry
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Log error to Sentry
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export default instance;
