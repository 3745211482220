import * as Yup from "yup";
import {
  IAddFietsPersonalInfo,
  IAddStolenObject,
  IAddUserEmail,
  IAddVerificationCode
} from "../types";
import { phoneRegExp } from "./commonHelpers";

//email verification
export const EmailVerificationInitialValues: IAddUserEmail = {
  email: ""
};

export const EmailVerificationValidationSchema = Yup.object({
  email: Yup.string().email().required().label("E-mail")
});

//code verification
export const CodeVerificationInitialValues: IAddVerificationCode = {
  verificationCode: ""
};

export const CodeVerificationValidationSchema = Yup.object({
  verificationCode: Yup.string().required().label("Code")
});

//object
export const ObjectSelectionInitialValues: IAddStolenObject = {
  objectId: ""
};

export const ObjectSelectionValidationSchema = Yup.object({
  objectId: Yup.string().required().label("Fiets")
});

export const PersonalInfoInitialValues: IAddFietsPersonalInfo = {
  lastName: "",
  firstName: "",
  phone: "",
  email: "",
  street: "",
  number: "",
  extension: "",
  city: "",
  zipCode: "",
  brand: "",
  model: "",
  color: "",
  frameNumber: "",
  motorNumber: "",
  lockNumber1: "",
  lockNumber2: "",
  policyNumber: "",
  insuranceCompany: "",
  dealer: ""
};

export const PersonalInfoValidationSchema = Yup.object({
  lastName: Yup.string().required().label("Achternaam"),
  firstName: Yup.string().required().label("Voornaam"),
  phone: Yup.string()
    .required()
    .label("Telefoonnummer")
    .matches(phoneRegExp, "Telefoonnummer is niet geldig")
    .min(10, "Telefoonnummer is te kort")
    .max(14, "Telefoonnummer is te lang"),
  email: Yup.string().required().label("Email"),
  street: Yup.string().required().label("Straat"),
  number: Yup.string().required().label("Huisnummer"),
  extension: Yup.string().label("Extensie"),
  city: Yup.string().required().label("Stad"),
  zipCode: Yup.string().required().label("Postcode"),
  brand: Yup.string().required().label("Merk"),
  model: Yup.string().required().label("Model"),
  color: Yup.string().required().label("Kleur"),
  frameNumber: Yup.string().required().label("Framenummer fiets"),
  motorNumber: Yup.string().label("Motornummer"),
  lockNumber1: Yup.string().required().label("Slotnummer 1"),
  lockNumber2: Yup.string().required().label("Slotnummer 2"),
  policyNumber: Yup.string().label("Polisnummer"),
  insuranceCompany: Yup.string().label("Verzekeringsmaatschappij"),
  dealer: Yup.string().label("Winkel / Dealer")
});

// Route Names
export const getJouwfietsgestolenPathnames = () => {
  const hostname = window.location.hostname;
  const isDomainJouwfietsgestolen = hostname.includes("jouwfietsbeveiligen");

  return {
    isDomainJouwfietsgestolen,

    pathnames: {
      startActivatePathname: isDomainJouwfietsgestolen ? "/" : "/fietsgestolen",

      startReportTheftPathname: isDomainJouwfietsgestolen
        ? "/report-theft"
        : "/fietsgestolen/report-theft",

      reportTheftFormPathname: isDomainJouwfietsgestolen
        ? "/report-theft/form"
        : "/fietsgestolen/report-theft/form",

      activateTheftProtectionFormPathname: isDomainJouwfietsgestolen
        ? "/activate-theft-protection/form"
        : "/fietsgestolen/activate-theft-protection/form"
    }
  };
};

export const getFietsgestolenPathnames = () => {
  const hostname = window.location.hostname;
  const isDomainFietsgestolen = hostname.includes("fietsgestolen");

  const { isDomainJouwfietsgestolen, pathnames: jouwfietsgestolenPathnames } =
    getJouwfietsgestolenPathnames();

  return {
    isDomainFietsgestolen,

    pathnames: {
      startReportTheftPathname: isDomainJouwfietsgestolen
        ? jouwfietsgestolenPathnames.startReportTheftPathname
        : "/",
      reportTheftFormPathname: isDomainJouwfietsgestolen
        ? jouwfietsgestolenPathnames.reportTheftFormPathname
        : "/form"
    }
  };
};

export const fietsgestolenPortalDynamicRoutes = () => {
  const { isDomainJouwfietsgestolen, pathnames: jouwfietsgestolenPathnames } =
    getJouwfietsgestolenPathnames();

  const { isDomainFietsgestolen, pathnames: fietsgestolenPathnames } =
    getFietsgestolenPathnames();

  const isPrimrayHostname =
    !isDomainFietsgestolen && !isDomainJouwfietsgestolen;

  return {
    isPrimrayHostname,
    isDomainJouwfietsgestolen,
    isDomainFietsgestolen,
    pathnames: {
      indexPath:
        isDomainFietsgestolen || isDomainJouwfietsgestolen
          ? "/"
          : "/fietsgestolen",

      startReportTheftPathname: isPrimrayHostname
        ? "/fietsgestolen/report-theft"
        : fietsgestolenPathnames.startReportTheftPathname,

      reportTheftFormPathname: isPrimrayHostname
        ? "/fietsgestolen/report-theft/form"
        : fietsgestolenPathnames.reportTheftFormPathname,

      activateTheftProtectionFormPathname:
        jouwfietsgestolenPathnames.activateTheftProtectionFormPathname
    }
  };
};
