import { FC } from "react";

const LocationLink: FC<{ latitude: number; longitude: number }> = ({
  latitude,
  longitude
}) => (
  <a
    href={`https://www.google.com/maps?q=${latitude},${longitude}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {`${latitude.toFixed(6)}, ${longitude.toFixed(6)}`}
  </a>
);

export default LocationLink;
