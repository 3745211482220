import { FC, Fragment, useState } from "react";
import { DateTime } from "luxon";
import { isEmpty } from "lodash";
import { ObjectInfo, IconWrapper } from "./MultipleRidesGoogleMap.styled";
import { MarkerBlip } from "../Map/Map.styled";
import { MarkerF, OverlayViewF, OverlayView } from "@react-google-maps/api";
import { SpeedIconWhite, CalendarIconWhite } from "assets/icons";

export interface MultipleRidesMapMarkersProps {
  singleItem: any;
  mainItem: any;
  handleMarkerClick: (marker: any, mainItem: any, singleItem: any) => void;
}

const circle = require("../../../assets/icons/common/CircleIcon.svg").default;

const MultipleRidesMapMarkers: FC<MultipleRidesMapMarkersProps> = ({
  singleItem,
  mainItem,
  handleMarkerClick
}) => {
  const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState<number | null>(
    null
  );

  if (isEmpty(singleItem)) return null;

  const getPixelPositionOffset = () => ({
    x: 20,
    y: -29
  });

  const getFormattedDate = (date: string) =>
    date ? DateTime.fromISO(date).toFormat("MMM dd, HH:mm") : "N/A";

  const getRideIcon = (iconUrl: string, isStart: boolean) => {
    const color = isStart ? "green" : "red";
    return {
      url: `${iconUrl.split(".png")[0]}-${color}.png`,
      size: new window.google.maps.Size(34, 34)
    };
  };

  const startMarker = singleItem[0];
  const endMarker = singleItem[singleItem.length - 1];

  return (
    <>
      {/* Start Marker */}
      <MarkerF
        onClick={() => handleMarkerClick(startMarker, mainItem, singleItem)}
        position={{
          lat: startMarker?.latitude ?? 0,
          lng: startMarker?.longitude ?? 0
        }}
        icon={{
          url: getRideIcon(mainItem.objectIcon, true).url,
          scaledSize: getRideIcon(mainItem.objectIcon, true).size
        }}
        onMouseOver={() => setHoveredMarkerIndex(0)}
        onMouseOut={() => setHoveredMarkerIndex(null)}
      />
      {hoveredMarkerIndex === 0 && (
        <OverlayViewF
          position={{
            lat: startMarker.latitude,
            lng: startMarker.longitude
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <Fragment>
            <ObjectInfo>
              {`${mainItem?.objectName} ${getFormattedDate(startMarker.date)}`}
            </ObjectInfo>
            <MarkerBlip></MarkerBlip>
          </Fragment>
        </OverlayViewF>
      )}

      {/* End Marker */}
      <MarkerF
        onClick={() => handleMarkerClick(endMarker, mainItem, singleItem)}
        position={{
          lat: endMarker?.latitude ?? 0,
          lng: endMarker?.longitude ?? 0
        }}
        icon={{
          url: getRideIcon(mainItem.objectIcon, false).url,
          scaledSize: getRideIcon(mainItem.objectIcon, false).size
        }}
        onMouseOver={() => setHoveredMarkerIndex(singleItem.length - 1)}
        onMouseOut={() => setHoveredMarkerIndex(null)}
      />
      {hoveredMarkerIndex === singleItem.length - 1 && (
        <OverlayViewF
          position={{
            lat: endMarker.latitude,
            lng: endMarker.longitude
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <Fragment>
            <ObjectInfo>
              {`${mainItem?.objectName} ${getFormattedDate(endMarker.date)}`}
            </ObjectInfo>
            <MarkerBlip></MarkerBlip>
          </Fragment>
        </OverlayViewF>
      )}
    </>
  );
};

export default MultipleRidesMapMarkers;
