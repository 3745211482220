import { Box, styled } from "@mui/material";

export const ObjectInfo = styled(Box)({
  backgroundColor: "#1D1F2BB8",
  color: "#fff",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "14.4px",
  padding: "8px",
  borderRadius: "4px",
  marginLeft: 10
});

export const MultipleRidesMapContainer = styled(Box)({
  height: "calc(100vh - 170px)",
  width: "100%",
  position: "relative",

  ".gm-style-iw.gm-style-iw-c": {
    background: "none",
    boxShadow: "none"
  },
  "button.gm-ui-hover-effect": {
    top: "6px !important",
    "& span": {
      backgroundColor: "#ffffff !important"
    }
  },
  ".gm-style-iw-d": {
    overflow: "hidden !important"
  },
  ".gm-style .gm-style-iw-tc::after": {
    background: "#192026E0"
  },
  ".gmnoprint .gm-style-mtc": {
    display: "none"
  },
  "button.gm-control-active.gm-fullscreen-control": {
    display: "none"
  }
});

export const ObjectListContainer = styled(Box)({
  position: "absolute",
  top: "60px",
  right: "10px",
  backgroundColor: "white",
  borderRadius: "4px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  overflowY: "auto",
  border: "1px solid #D5D9E8",
  zIndex: 400,
  padding: "12px 12px 0 12px"
});

export const ObjectList = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "11px"
});

export const ObjectText = styled(Box)({
  fontFamily: "Gilroy",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 400,
  marginTop: "2px"
});

export const ObjectColorBox = styled(Box)<{ color: string }>(({ color }) => ({
  height: "12px",
  width: "12px",
  borderRadius: "2px",
  backgroundColor: color,
  marginRight: "8px"
}));

export const IconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  fontSize: "12px",
  lineHeight: "14.4px",
  color: "#fff"
});
