import * as Yup from "yup";
import moment from "moment";
import { isEmpty } from "lodash";
import { types, BadgeColorSchemeType } from "@vilocnv/allsetra-core";
import { TimeLineItemProps } from "components/ui/LocationTimeline/LocationTimeline";
import { IRideBulkUpdateKeys, IRideBulkUpdateMode } from "../types";
import { DateTime } from "luxon";

export enum ObjectRideModeEnum {
  BUSINESS = 1,
  PRIVATE = 2,
  COMMUTE = 3
}

export const OBJECT_RIDE_MODES = (translator: any): Record<number, string> =>
  ({
    1: translator("objectRideModes.1"),
    2: translator("objectRideModes.2"),
    3: translator("objectRideModes.3")
  }) as const;

export const OBJECT_RIDE_MODES_OPTIONS = (translator: any) => {
  const objectRideModes = OBJECT_RIDE_MODES(translator);

  return Object.keys(objectRideModes).map((item: any) => ({
    value: item,
    label: objectRideModes[item]
  }));
};

export const OBJECT_RIDE_MODES_COLOR: Record<number, BadgeColorSchemeType> = {
  1: "info",
  2: "success",
  3: "warning"
} as const;

export const transformObjectRideDataForForm = (
  objectRide: types.IObjectRide
): types.IUpdateObjectRide => {
  return {
    uniqueId: objectRide.uniqueId,
    keyId: objectRide.key?.uniqueId || "",
    rideMode: objectRide.rideMode,
    comments: objectRide.comments,
    startAddressPointOfInterest: "",
    endAddressPointOfInterest: "",
    correctedEndAddress: "",
    correctedEndLatitude: 0,
    correctedEndLongitude: 0,
    correctedStartAddress: "",
    correctedStartLatitude: 0,
    correctedStartLongitude: 0
  };
};

export const updateObjectRideFormValidationSchema = Yup.object({
  keyId: Yup.string().label("Driver"),
  rideMode: Yup.string().label("Ride Mode").required(),
  comments: Yup.string().label("Comments").required()
});

export const transformObjectRideLocationsForTimeline = (
  objectRideLocations: Array<types.IObjectRideLocation>,
  objectRide: any
): Array<TimeLineItemProps> => {
  return objectRideLocations.length > 0
    ? objectRideLocations.map((item) => ({
        resolvedAddress: item.address,
        date: item?.date,
        latitude: item?.latitude - 0.00001,
        longitude: item?.longitude,
        startAddress: objectRide?.startAddress,
        endAddress: objectRide?.endAddress
      }))
    : [
        {
          resolvedAddress: objectRide?.startAddress,
          date: objectRide?.startDate,
          latitude: objectRide?.startLatitude - 0.00001,
          longitude: objectRide?.startLongitude,
          startAddress: objectRide?.startAddress,
          endAddress: objectRide?.endAddress
        },
        {
          resolvedAddress: objectRide?.startAddress,
          date: objectRide?.endDate,
          latitude: objectRide?.endLatitude - 0.00001,
          longitude: objectRide?.endLatitude,
          startAddress: objectRide?.startAddress,
          endAddress: objectRide?.endAddress
        }
      ];
};

export const getObjectRideTotalTimeInMinutes = (
  objectRide: types.IObjectRide
) => {
  return moment(objectRide.endDate).diff(objectRide.startDate, "minutes");
};

export const getRidesBulkUpdateKeyInitialValues = (
  rides: string[]
): IRideBulkUpdateKeys => ({
  rides,
  keyId: null
});

export const getRidesBulkUpdateModeInitialValues = (
  rides: string[]
): IRideBulkUpdateMode => ({
  rides,
  rideMode: null
});

export const ridesBulkUpdateKeyValidationSchema: Yup.Schema = Yup.object({
  keyId: Yup.string().label("Driver").required()
});

export const ridesBulkUpdateModeValidationSchema: Yup.Schema = Yup.object({
  rideMode: Yup.number().label("Mode").required()
});

export const getObjectRideKeyValueData = (locations: any[]) => {
  const data: any = {};
  locations?.forEach(
    (item) =>
      (data[`${DateTime.fromISO(item?.date).toFormat("dd MMM yyyy, HH:mm")} `] =
        `${item?.speed?.toFixed(2)} km/h`)
  );
  return data;
};
