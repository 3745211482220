import { FC, useEffect, useState } from "react";
import { Stack, Typography, Box } from "@mui/material";
import {
  CardContainer,
  LinkTextBox,
  ObjectIconBox,
  ObjectInfoRow,
  RowText,
  SmallText
} from "./ObjectListingCard.styled";
import { Link } from "react-router-dom";
import {
  HashIcon,
  LinkArrowIcon,
  PlateNoIcon,
  SpeedIcon,
  StartLocation
} from "assets/icons";
import { useAppSelector } from "hooks";
import { selectSpecificObjectState } from "app/data/selectors";
import { utils } from "@vilocnv/allsetra-core";

export interface ObjectDetailsCardProps {
  object: any;
  onObjectCardClick: any;
}

const ObjectListingCard: FC<ObjectDetailsCardProps> = ({
  object,
  onObjectCardClick
}) => {
  const [plateNumber, setPlateNumber] = useState<string>();
  const { activeObjectId } = useAppSelector(selectSpecificObjectState);

  const getPlateNumber = () => {
    for (let i = 0; i < object?.metadata.length; i++) {
      if (object?.metadata[i]?.field?.label.toLowerCase() === "license plate") {
        setPlateNumber(
          `${object?.metadata[i]?.value || ""} ${object?.metadata[i]?.field.valueUnit || ""} `
        );
        break;
      }
    }
  };

  useEffect(() => {
    getPlateNumber();
  }, []);

  return (
    <CardContainer
      isActive={activeObjectId === object?.uniqueId}
      onClick={() => onObjectCardClick(object.uniqueId)}
    >
      <Stack direction={"column"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <ObjectIconBox>
              <img
                style={{ width: "100%" }}
                // @ts-ignore
                src={object?.icon?.url}
                alt={"object-icon"}
              />
            </ObjectIconBox>
            <LinkTextBox>
              <Link
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  cursor: "pointer"
                }}
                to={`/dashboard/objects/details/${object.uniqueId}`}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    width: object?.name?.length > 40 ? "320px" : "auto"
                  }}
                >
                  {object?.name || "N/A"}
                </Typography>
                <LinkArrowIcon className="arrow-icon" />
              </Link>
            </LinkTextBox>
          </Stack>
          {/* // TODO can be added later */}
          {/* <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <SpeedIcon />
            <SmallText mt={0.3} ml={0.2} color={"#1D1F2B"}>
              {`${object?.lastReportedSpeed || 0} km/h`}
            </SmallText>
          </Box> */}
        </Stack>

        <Box>
          <Typography sx={{ fontSize: "14px", color: "#323946", mt: 0.5 }}>
            {object?.location?.resolvedAddress || "N/A"}
          </Typography>
        </Box>
        <ObjectInfoRow>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <StartLocation size={16} />
            <RowText>
              {utils.formatDate({ date: object?.lastUpdated, whenEmpty: "-" })}
            </RowText>
          </Box>
        </ObjectInfoRow>
        <ObjectInfoRow>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <HashIcon />
            <RowText>{object?.aNumber}</RowText>
          </Box>
          <Box width={"25px"}></Box>
          {plateNumber?.trim() ? (
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <PlateNoIcon />
              <RowText>{plateNumber}</RowText>
            </Box>
          ) : (
            <></>
          )}
        </ObjectInfoRow>
      </Stack>
    </CardContainer>
  );
};

export default ObjectListingCard;
