import { FC } from "react";
import { Box } from "@mui/material";

// CHILDREN
import GeneralInformationForm from "./children/GeneralInformationForm";
import FinancialInformationForm from "./children/FinancialInformationForm";
import LogisticsForm from "./children/LogisticsForm";
import ManagementForm from "./children/ManagementForm";

// DATA
import { useAppSelector, useDispatchOnMount } from "hooks";
import { getAllCountriesThunk } from "app/features";
import { selectDashboardCountriesState } from "app/data/selectors";
import WorkAddressLabelsForm from "./children/WorkAddressLabelsForm";

const AccountSettingsSection: FC = () => {
  const { countries, loading: countriesLoading } = useAppSelector(
    selectDashboardCountriesState
  );

  useDispatchOnMount(getAllCountriesThunk, countries.length ? undefined : true);

  return (
    <Box mt={4} mx={4}>
      <GeneralInformationForm
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <WorkAddressLabelsForm />
      <FinancialInformationForm
        countries={countries}
        countriesLoading={countriesLoading}
      />
      <LogisticsForm />
      <ManagementForm />
    </Box>
  );
};

export default AccountSettingsSection;
