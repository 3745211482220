import React, { FC } from "react";
import { MarkerData } from "./types";
import {
  MapObjectCard,
  ObjectMetadataTags,
  utils
} from "@vilocnv/allsetra-core";
import {
  Skeleton,
  SkeletonWrapper
} from "components/maps/common/ObjectMarker.styled";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { isEmpty } from "lodash";
import { useAppSelector } from "hooks";
import { selectSpecificObjectState } from "app/data/selectors";
import { LinkArrowIcon } from "assets/icons";
import { MapObjectCardBox } from "../../Map.styled";

interface MarkerInfoWindowProps {
  marker: MarkerData;
  showLinkIcon?: boolean;
}

const MarkerInfoWindowContent: FC<MarkerInfoWindowProps> = ({
  marker,
  showLinkIcon
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { markerLoading, selectedMarker } = useAppSelector(
    selectSpecificObjectState
  );

  return (
    <MapObjectCardBox>
      <MapObjectCard
        label={marker?.name || t("typography.na")}
        id={marker.aNumber || t("typography.na")}
        loading={false}
        location={{
          isLoading: markerLoading,
          address: selectedMarker?.location?.resolvedAddress,
          latitude: selectedMarker?.location?.latitude,
          longitude: selectedMarker?.location?.longitude
        }}
        accuracy={marker?.accuracy || (t("typography.na") as any)}
        time={
          markerLoading
            ? "..."
            : utils.formatDate({
                date: selectedMarker?.location?.date
              })
        }
        handleObjectNameClick={() =>
          navigate({
            pathname: `/dashboard/objects/details/${marker.uniqueId}`
          })
        }
        translator={t}
        showLinkIcon={showLinkIcon}
        linkIcon={showLinkIcon ? <LinkArrowIcon /> : <></>}
      >
        {!isEmpty(selectedMarker) && !markerLoading ? (
          <ObjectMetadataTags
            translator={t}
            object={selectedMarker}
            direction={"row"}
          />
        ) : (
          <SkeletonWrapper>
            <Skeleton variant="rectangular" />
          </SkeletonWrapper>
        )}
      </MapObjectCard>
    </MapObjectCardBox>
  );
};

export default React.memo(MarkerInfoWindowContent);
