import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import {
  ContentSectionLayout,
  FormikSelectField,
  TwoColsLayout
} from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";

interface Props {
  languages: Array<any>;
  languagesLoading: boolean;
}

const InterfaceForm: FC<Props> = ({ languagesLoading, languages }) => {
  const theme = useTheme();

  const englishLanguage = languages.filter(
    (language) => language.languageId === 1033 || language.languageId === 1043
  );

  const { t } = useTranslation();

  return (
    <ContentSectionLayout
      title={t("titles.interface")}
      subTitle={t("descriptions.interface")}
      hideDivider
    >
      <Box>
        <TwoColsLayout hideDivider>
          <FormikSelectField
            theme={theme}
            label={t("language.label", {
              ns: "formFieldsTranslation"
            })}
            name="languageId"
            options={englishLanguage}
            optionLabelKey="languageName"
            optionValueKey="languageId"
            loading={languagesLoading}
          />
        </TwoColsLayout>
      </Box>
    </ContentSectionLayout>
  );
};

export default InterfaceForm;
