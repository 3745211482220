import { FC } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineOppositeContent
} from "@mui/lab";
import {
  LocationTimelineContainer,
  LocationTimelineWrapper,
  TimelineContentDetailsWrapper,
  TimelineContentWrapper,
  TimelineBox,
  CustomTimelineConnector
} from "./LocationTimeline.styled";
import { EndLocation, StartLocation } from "assets/icons";
import moment from "moment";
import { getRideAddress, getSplittedAddress } from "app/data/helpers";

export const timeLineColors = [
  "#1CA0BD",
  "#4560E4",
  "#3E34C1",
  "#6E44A8",
  "#BC3FB7"
];

export interface TimeLineItemProps {
  resolvedAddress: string;
  date: string;
  latitude: number;
  longitude: number;
  accuracy?: number | null;
  altitude?: number | null;
  startAddress?: string;
  endAddress?: string;
  altitudeAccuracy?: any;
  locationProvider?: {
    locationType: string;
    provider: string;
  };
}

export interface LocationTimelineProps {
  timeLineItems: Array<TimeLineItemProps>;
}

const LocationTimeline: FC<any> = ({ timeLineItems }) => {
  return (
    <LocationTimelineContainer>
      {timeLineItems && (
        <Timeline>
          {timeLineItems.map(
            (item: TimeLineItemProps, index: number, array: any) => (
              <LocationTimelineWrapper
                key={`${index}`}
                color={timeLineColors[index % timeLineColors.length]}
                color2={timeLineColors[index % timeLineColors.length]}
                height={index + 1 === array.length ? "65px" : "120px"}
              >
                <TimelineItem>
                  <TimelineOppositeContent sx={{ flex: 0.1 }}>
                    {moment(item?.date).format("HH:mm")}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineBox>
                      {index === 0 ? <StartLocation /> : <EndLocation />}
                    </TimelineBox>

                    {!(index + 1 === array.length) && (
                      <CustomTimelineConnector />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>
                    <TimelineContentWrapper>
                      <h3>
                        {getSplittedAddress(
                          0,
                          getRideAddress(index, item, array)
                        )}
                        <br />
                        {getSplittedAddress(
                          1,
                          getRideAddress(index, item, array)
                        ) +
                          getSplittedAddress(
                            2,
                            getRideAddress(index, item, array)
                          )}
                      </h3>
                      <TimelineContentDetailsWrapper></TimelineContentDetailsWrapper>
                    </TimelineContentWrapper>
                  </TimelineContent>
                </TimelineItem>
              </LocationTimelineWrapper>
            )
          )}
        </Timeline>
      )}
    </LocationTimelineContainer>
  );
};

export default LocationTimeline;
