import { FC } from "react";
import { Stack, Box } from "@mui/material";
import { InfoDetailContainer } from "../../activationPortalSections.styled";
import { IActivationPortalInitialValues } from "app/data/types";

const PaymentDetailsOverview: FC<
  IActivationPortalInitialValues & { translator: any }
> = ({ initialValues, order = 3, translator }) => {
  return (
    <Stack spacing={3}>
      <h5>
        {order}.{" "}
        {translator("titles.paymentDetails", {
          ns: "activationPortal"
        })}
      </h5>
      <Box>
        <InfoDetailContainer>
          <h6>
            {translator("accountHolderName.label", {
              ns: "formFieldsTranslation"
            })}
          </h6>
          <p>{initialValues.accountHolderName || ""}</p>
        </InfoDetailContainer>

        <InfoDetailContainer>
          <h6>
            {translator("bankAccountNumber.label", {
              ns: "formFieldsTranslation"
            })}
          </h6>
          <p>{initialValues.bankAccountNumber || ""}</p>
        </InfoDetailContainer>
      </Box>
    </Stack>
  );
};

export default PaymentDetailsOverview;
