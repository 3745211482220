import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectPointsOfInterestLoading = (state: RootState) =>
  state.rootReducer.pointsOfInterestReducer.loading;

export const selectPointsOfInterestRecords = (state: RootState) =>
  state.rootReducer.pointsOfInterestReducer.records;

export const selectPointsOfInterestState = createSelector(
  selectPointsOfInterestLoading,
  selectPointsOfInterestRecords,
  (loading, records) => ({ loading, pointsOfInterest: records })
);
