// @ts-nocheck
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, useStepDrawerController } from "hooks";
import StepperDrawerLayout, {
  IStepItem
} from "components/layouts/StepperDrawerLayout/StepperDrawerLayout";
import { selectWhiteLabelsState } from "app/data/selectors";

// STEPS
import ActivationInstallationForm from "components/forms/activationPortalForms/ActivationInstallationForm";
import ActivationInformationForm from "components/forms/activationPortalForms/ActivationInformationForm";
import ActivationSubscriptionSection from "components/sections/activationPortalSections/ActivationSubscriptionSection";
import ActivationPaymentForm from "components/forms/activationPortalForms/ActivationPaymentForm";
import ActivationOverviewSection from "components/sections/activationPortalSections/ActivationOverviewSection";
import ActivationSuccessSection from "components/sections/activationPortalSections/ActivationSuccessSection";
import { resetSubscribeFormData, setSubscribeFormData } from "app/features";
import { selectActivationPortalState } from "app/data/selectors/activationPortalSelectors";

const ActivationForm: FC = () => {
  const { t } = useTranslation(["activationPortal"]);
  const dispatch = useAppDispatch();

  const { whiteLabel } = useAppSelector(selectWhiteLabelsState);

  const { subscribeFormData } = useAppSelector(selectActivationPortalState);

  const INITIAL_STEPS: Array<IStepItem> = useMemo(
    () => [
      {
        index: 0,
        name: t("titles.installationDetails", {
          ns: "activationPortal"
        }),
        isActive: true,
        isCompleted: false
      },
      {
        index: 1,
        name: t("titles.information", {
          ns: "activationPortal"
        }),
        isActive: false,
        isCompleted: false
      },
      {
        index: 2,
        name: t("titles.subscription", {
          ns: "activationPortal"
        }),
        isActive: false,
        isCompleted: false
      },
      {
        index: 3,
        name: t("titles.payment", {
          ns: "activationPortal"
        }),
        isActive: false,
        isCompleted: false
      },
      {
        index: 4,
        name: t("titles.overview", {
          ns: "activationPortal"
        }),
        isActive: false,
        isCompleted: false
      },
      {
        index: 5,
        name: t("titles.success", {
          ns: "activationPortal"
        }),
        isActive: false,
        isCompleted: false
      }
    ],
    [t]
  );

  const { steps, activeStepIndex, onCompletedStepClick, gotoNextStep } =
    useStepDrawerController(INITIAL_STEPS);

  useEffect(() => {
    dispatch(resetSubscribeFormData());
  }, []);

  return (
    <StepperDrawerLayout
      size={"large"}
      isMobileDrawerOpen={false}
      appLogoType={"customer"}
      steps={steps}
      onCompletedStepClick={onCompletedStepClick}
      whiteLabel={whiteLabel}
    >
      {activeStepIndex === 0 && (
        <ActivationInstallationForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 1 && (
        <ActivationInformationForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 2 && (
        <ActivationSubscriptionSection gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 3 && (
        <ActivationPaymentForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 4 && (
        <ActivationOverviewSection gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 5 && (
        <ActivationSuccessSection gotoNextStep={gotoNextStep} />
      )}
    </StepperDrawerLayout>
  );
};

export default ActivationForm;
