import { FC } from "react";
import useDashboardMap from "./useDashboardMap";
import { MapStateProvider } from "../Map/v2/GoogleMap/MapStateContext";
import GoogleMap from "../Map/v2/GoogleMap";
import DashboardMapTopLeftSection from "./DashboardMapTopLeftSection";
import DashboardMapTopRightSection from "./DashboardMapTopRightSection";
import TrafficLayer from "../Map/v2/GoogleMap/TrafficLayer";
import MarkersClusterer from "../Map/v2/GoogleMap/MarkersClusterer";
import Markers from "../Map/v2/GoogleMap/Markers";
import Geozone from "../Map/v2/GoogleMap/Geozone";
import Geozones from "../Map/v2/GoogleMap/Geozones";
import { useAppSelector } from "hooks";
import { selectObjectMapState } from "app/data/selectors";

const DashboardMap: FC = () => {
  const { allObjects, allObjectsLoading } = useDashboardMap();
  const { markers } = useAppSelector(selectObjectMapState);

  return (
    <MapStateProvider
      height="100vh"
      objects={allObjects}
      isLoading={allObjectsLoading}
      disableNavigator={true}
      skipCurrentLocation={true}
      showSearch={true}
      showFilter={true}
      isDashboardMap={true}
      objectsMarker={false}
      markers={markers}
    >
      <GoogleMap>
        <DashboardMapTopLeftSection />
        <DashboardMapTopRightSection />
        <TrafficLayer />
        <MarkersClusterer />
        <Markers />
        <Geozone />
        <Geozones />
      </GoogleMap>
    </MapStateProvider>
  );
};

export default DashboardMap;
