import { IActivationDrawerMenuItem } from "../types";

export const STEP_SCREENS_DRAWER_MENU_ITEMS: Array<IActivationDrawerMenuItem> =
  [
    { id: 1, name: "Installation details", path: "//activation-portal/steps" },
    {
      id: 2,
      name: "Information",
      path: "/activation-portal/steps/information"
    },
    {
      id: 3,
      name: "Subscription",
      path: "/activation-portal/steps/subscription"
    },
    { id: 4, name: "Payment", path: "/activation-portal/steps/payment" },
    { id: 5, name: "Overview", path: "/activation-portal/steps/overview" },
    { id: 6, name: "Success", path: "/activation-portal/steps/success" }
  ];

export const currencySigns: any = { EUR: "€", USD: "$" };

export const cardColors: Array<any> = ["#E28E10", "#39169E", "#148E20"];

export const SUBSCRIPTION_TYPES: any = {
  PER_YEAR: "Per year",
  PER_MONTH: "Per month",
  PER_12_MONTHS: "Per 12 months",
  PRE_PAID: "Prepaid"
};
