import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportsService, UtilityService } from "app/data/services";

export const generateReportThunk = createAsyncThunk(
  "reports/generateReportThunk",
  async (data: any) => {
    try {
      const response = await ReportsService.generateReport(data);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const resolveAddressThunk = createAsyncThunk(
  "reports/resolveAddressThunk",
  async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    try {
      const response = await UtilityService.getAddressFromLatLng(
        latitude,
        longitude
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const exportReportThunk = createAsyncThunk(
  "reports/exportReportThunk",
  async (data: any) => {
    try {
      const response = await ReportsService.exportReport(data);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
