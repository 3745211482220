import * as Yup from "yup";
import {
  IAccountInformation,
  IActivationInformation,
  IActivationPortal
} from "../types";
import {
  alphanumericRegex,
  cityRegex,
  emailRegExp,
  houseNumberRegex,
  lastNameRegex,
  nameRegex,
  phoneRegExp,
  postCodeRegex,
  safetyQsAnsRegex,
  streetRegex,
  vatRegex
} from "./commonHelpers";
import {
  SUBSCRIPTION_TYPES,
  currencySigns
} from "../constants/activationPortalConstants";
import { isEmpty, sortBy } from "lodash";
import { ObjectFieldsEnum } from "./objectsDynamicFieldsHelpers";

const TAX_RATE = 0.21;

export const activationObjectDataFormatter = (data: Array<any>) => {
  let objectBrand;
  let objectModel;
  let licensePlate;

  data.map((data: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data.field === "Brand"
      ? (objectBrand = data.value)
      : data.field === "Model"
        ? (objectModel = data.value)
        : (licensePlate = data.value);
  });

  return { objectBrand, objectModel, licensePlate };
};

export const getSecurityQuestion = (
  securityQuestions: Array<any>,
  safeQuestionId: string
) => {
  const securityQuestion =
    securityQuestions &&
    securityQuestions?.find((question) => question?.uniqueId === safeQuestionId)
      ?.question;
  return { securityQuestion };
};

//installation
export const installationInitialValues = {
  installationId: "",
  objectBrand: "",
  objectModel: "",
  licensePlate: ""
};

export const installationsValidationSchema = (translator: any) =>
  Yup.object({
    installationId: Yup.string()
      .required(
        translator("validationMessage.accountIdRequired", {
          ns: "activationPortal"
        })
      )
      .label(
        translator("validationMessage.installationId", {
          ns: "activationPortal"
        })
      ),
    objectBrand: Yup.string()
      .trim()
      .label(
        translator("validationMessage.objectBrand", {
          ns: "activationPortal"
        })
      ),
    objectModel: Yup.string()
      .trim()
      .label(
        translator("validationMessage.objectModel", {
          ns: "activationPortal"
        })
      ),
    licensePlate: Yup.string()
      .trim()
      .label(
        translator("validationMessage.licensePlate", {
          ns: "activationPortal"
        })
      )
  });

export const installationDataFormatter = (installation: any) => {
  const { licensePlate, objectBrand, objectModel } =
    activationObjectDataFormatter(installation.metadata);

  const payload = {
    installationId: installation.installationId,
    objectBrand,
    objectModel,
    licensePlate
  };

  return payload;
};

//information
export const informationInitialValues: IActivationInformation = {
  companyInfo: {
    name: "-",
    street: "",
    number: "",
    postCode: "",
    city: "",
    country: "Nederland (de)",
    vatNumber: ""
  },
  userInfo: {
    prefix: "",
    firstName: "",
    lastName: "",
    phoneNumber1: "",
    countryCode1: "+31",
    phoneNumber2: "",
    countryCode2: "+31",
    email: "",
    safeQuestionId: "",
    safeQuestionAnswer: ""
  },

  alarmPersons: []
};

//
export const accountFormInitialValues: IAccountInformation = {
  accountId: ""
};

export const informationValidationSchema = (translator: any) =>
  Yup.object({
    companyInfo: Yup.object({
      name: Yup.string()
        .required(
          translator("validationMessage.companyNameRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.name", {
            ns: "activationPortal"
          })
        ),
      street: Yup.string()
        .matches(
          streetRegex,
          translator("validationMessage.streetRequired", {
            ns: "activationPortal"
          })
        )
        .trim()
        .required(
          translator("validationMessage.streetRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.street", {
            ns: "activationPortal"
          })
        ),
      number: Yup.string()
        .trim()
        .matches(
          houseNumberRegex,
          translator("validationMessage.invalidFormat", {
            ns: "activationPortal"
          })
        )
        .required(
          translator("validationMessage.houseNumberRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.number", {
            ns: "activationPortal"
          })
        ),
      postCode: Yup.string()
        .matches(
          postCodeRegex,
          translator("validationMessage.invalidFormat", {
            ns: "activationPortal"
          })
        )
        .required(
          translator("validationMessage.postcodeRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.postCode", {
            ns: "activationPortal"
          })
        ),
      city: Yup.string()
        .matches(
          cityRegex,
          translator("validationMessage.invalidFormat", {
            ns: "activationPortal"
          })
        )
        .required(
          translator("validationMessage.cityRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.city", {
            ns: "activationPortal"
          })
        ),
      extension: Yup.string()
        .matches(
          alphanumericRegex,
          translator("validationMessage.invalidFormat", {
            ns: "activationPortal"
          })
        )
        .trim()
        .label(
          translator("validationMessage.extension", {
            ns: "activationPortal"
          })
        ),
      country: Yup.string()
        .required(
          translator("validationMessage.countryRequired", {
            ns: "activationPortal"
          })
        )
        .trim()
        .label(
          translator("validationMessage.country", {
            ns: "activationPortal"
          })
        ),
      vatNumber: Yup.string()
        .matches(
          vatRegex,
          translator("validationMessage.invalidFormat", {
            ns: "activationPortal"
          })
        )
        .trim()
        .label(
          translator("validationMessage.vatNumber", {
            ns: "activationPortal"
          })
        )
    }),

    userInfo: Yup.object({
      prefix: Yup.string()
        .trim()
        .label(
          translator("validationMessage.prefix", {
            ns: "activationPortal"
          })
        ),
      firstName: Yup.string()
        .matches(
          nameRegex,
          translator("validationMessage.firstNameNotValid", {
            ns: "activationPortal"
          })
        )
        .required(
          translator("validationMessage.firstNameRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.firstName", {
            ns: "activationPortal"
          })
        ),
      lastName: Yup.string()
        .matches(
          lastNameRegex,
          translator("validationMessage.lastNameNotValid", {
            ns: "activationPortal"
          })
        )
        .trim()
        .required(
          translator("validationMessage.lastNameRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.lastName", {
            ns: "activationPortal"
          })
        ),
      phoneNumber1: Yup.string()
        .trim()
        .required(
          translator("validationMessage.phoneNumber1Required", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.phoneNumber1", {
            ns: "activationPortal"
          })
        )

        .matches(
          phoneRegExp,
          translator("validationMessage.phoneNumberNotValid", {
            ns: "activationPortal"
          })
        )

        .min(
          9,
          translator("validationMessage.phoneNumberShort", {
            ns: "activationPortal"
          })
        )
        .max(
          14,
          translator("validationMessage.phoneNumberLong", {
            ns: "activationPortal"
          })
        ),

      countryCode1: Yup.string()
        .required(
          translator("validationMessage.countryCodeRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.countryCode1", {
            ns: "activationPortal"
          })
        ),
      phoneNumber2: Yup.string()
        .trim()
        .label(
          translator("validationMessage.phoneNumber2", {
            ns: "activationPortal"
          })
        )
        .matches(
          phoneRegExp,
          translator("validationMessage.phoneNumberNotValid", {
            ns: "activationPortal"
          })
        )
        .min(
          10,
          translator("validationMessage.phoneNumberShort", {
            ns: "activationPortal"
          })
        )
        .max(
          14,
          translator("validationMessage.phoneNumberLong", {
            ns: "activationPortal"
          })
        ),
      email: Yup.string()
        .required(
          translator("validationMessage.emailRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.email", {
            ns: "activationPortal"
          })
        )

        .matches(
          emailRegExp,
          translator("validationMessage.invalidEmailFormat", {
            ns: "activationPortal"
          })
        ),
      safeQuestionId: Yup.string()
        .trim()
        .required(
          translator("validationMessage.safetyQuestionRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.safeQuestionId", {
            ns: "activationPortal"
          })
        ),
      safeQuestionAnswer: Yup.string()
        .trim()
        .matches(
          safetyQsAnsRegex,
          translator("validationMessage.invalidFormat", {
            ns: "activationPortal"
          })
        )
        .required(
          translator("validationMessage.safetyQuestionAnswerRequired", {
            ns: "activationPortal"
          })
        )
        .label(
          translator("validationMessage.safeQuestionAnswer", {
            ns: "activationPortal"
          })
        )
    }),

    alarmPersons: Yup.array().of(
      Yup.object({
        firstName: Yup.string()
          .required(
            translator("validationMessage.firstNameRequired", {
              ns: "activationPortal"
            })
          )
          .label(
            translator("validationMessage.firstName", {
              ns: "activationPortal"
            })
          ),
        lastName: Yup.string()
          .trim()
          .required(
            translator("validationMessage.lastNameRequired", {
              ns: "activationPortal"
            })
          )
          .label(
            translator("validationMessage.lastName", {
              ns: "activationPortal"
            })
          ),
        phoneNumber1: Yup.string()
          .trim()
          .required(
            translator("validationMessage.phoneNumber1Required", {
              ns: "activationPortal"
            })
          )
          .label(
            translator("validationMessage.phoneNumber1", {
              ns: "activationPortal"
            })
          )
          .matches(
            phoneRegExp,
            translator("validationMessage.phoneNumberNotValid", {
              ns: "activationPortal"
            })
          )
          .max(
            14,
            translator("validationMessage.phoneNumberLong", {
              ns: "activationPortal"
            })
          ),
        countryCode1: Yup.string()
          .required(
            translator("validationMessage.countryCodeRequired", {
              ns: "activationPortal"
            })
          )
          .label(
            translator("validationMessage.countryCode1", {
              ns: "activationPortal"
            })
          ),
        phoneNumber2: Yup.string()
          .trim()
          .label(
            translator("validationMessage.phoneNumber2", {
              ns: "activationPortal"
            })
          )
          .matches(
            phoneRegExp,
            translator("validationMessage.phoneNumberNotValid", {
              ns: "activationPortal"
            })
          )

          .min(
            10,
            translator("validationMessage.phoneNumberShort", {
              ns: "activationPortal"
            })
          )
          .max(
            14,
            translator("validationMessage.phoneNumberLong", {
              ns: "activationPortal"
            })
          ),
        safeQuestionId: Yup.string()
          .trim()
          .required(
            translator("validationMessage.safetyQuestionRequired", {
              ns: "activationPortal"
            })
          )
          .label(
            translator("validationMessage.safeQuestionId", {
              ns: "activationPortal"
            })
          ),
        safeQuestionAnswer: Yup.string()
          .trim()
          .required(
            translator("validationMessage.safetyQuestionAnswerRequired", {
              ns: "activationPortal"
            })
          )
          .label(
            translator("validationMessage.safeQuestionAnswer", {
              ns: "activationPortal"
            })
          ),
        email: Yup.string()
          .trim()
          .required(
            translator("validationMessage.emailRequired", {
              ns: "activationPortal"
            })
          )
          .label(
            translator("validationMessage.email", {
              ns: "activationPortal"
            })
          )
          .matches(
            emailRegExp,
            translator("validationMessage.invalidEmailFormat", {
              ns: "activationPortal"
            })
          ),
        prefix: Yup.string()
          .trim()
          .label(
            translator("validationMessage.prefix", {
              ns: "activationPortal"
            })
          )
      })
    )
  });

//
export const accountFormSchema = (translator: any) =>
  Yup.object({
    accountId: Yup.string()
      .trim()
      .required(
        translator("validationMessage.accountIdRequired", {
          ns: "activationPortal"
        })
      )
      .label(
        translator("validationMessage.accountId", {
          ns: "activationPortal"
        })
      )
  });

//Subscription

export const subscriptionDataFormatter = (installation: any) => {
  const { licensePlate, objectBrand, objectModel } =
    activationObjectDataFormatter(installation.metadata);

  const payload = {
    objectBrand,
    objectModel,
    licensePlate,
    deviceId: installation?.deviceInfo?.serialNumber || "",
    deviceType: installation?.deviceInfo?.deviceTypeName || "",
    serviceTypeName: installation?.serviceInfo?.name || "",
    serviceTypeDetails: installation?.serviceInfo?.description || ""
  };

  return payload;
};

export const getPricePerYear = (
  valuePerMonth: number = 0,
  currency: string = "€"
) => {
  let valuePerMonthStr = valuePerMonth?.toFixed(2);
  valuePerMonthStr = valuePerMonthStr.replace(".", ",");

  return `${currencySigns[currency]}${valuePerMonthStr}`;
};

export const getTaxInclusivePrice = (
  valuePerMonth: number = 0,
  currency: string = "€"
) => {
  const taxPrice = valuePerMonth * (1 + TAX_RATE);

  let taxPriceStr = taxPrice.toFixed(2);
  taxPriceStr = taxPriceStr.replace(".", ",");

  return `${currencySigns[currency]}${taxPriceStr}`;
};

export const getPricePerMonthText = (
  months: number,
  subscriptionType: string
) => {
  if (SUBSCRIPTION_TYPES[subscriptionType]) {
    return `Contract duration is ${months} ${
      months === 1 ? "month" : "months"
    }, billed ${SUBSCRIPTION_TYPES[subscriptionType]}.`;
  } else {
    return `Invalid subscription type: ${subscriptionType}`;
  }
};

export const specificSubscriptionPlanInformation = ({
  subscriptions,
  subscriptionId
}: {
  subscriptions: Array<any>;
  subscriptionId: string;
}) => {
  const subscriptionPlan = subscriptions.find(
    (subscription: any) => subscription.uniqueId === subscriptionId
  );

  const pricePerYear = getPricePerYear(
    subscriptionPlan.valuePerMonth,
    subscriptionPlan.currency
  );

  return { pricePerYear, subscriptionPlan };
};

//Payment
export const paymentInitialValues = {
  accountHolderName: "",
  bankAccountNumber: "",
  authorizeAllsetra: false,
  termsAndConditions: false,
  privacyPolicy: false,
  directDebit: false
};

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
const isValidIBANNumber = (input: string) => {
  const CODE_LENGTHS: any = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20
  };
  const iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(
    /[A-Z]/g,
    function (letter) {
      return String(letter.charCodeAt(0) - 55);
    }
  );
  // final check
  return mod97(digits) === 1;
};

const mod97 = (value: any) => {
  let checksum = value.slice(0, 2),
    fragment;
  for (let offset = 2; offset < value.length; offset += 7) {
    fragment = String(checksum) + value.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
};

Yup.addMethod<Yup.StringSchema<string>>(
  Yup.string,
  "iban",
  function (errorMessage) {
    return this.test(`test-iban`, errorMessage, isValidIBANNumber);
  }
);

export const paymentValidationSchema = (translator: any) =>
  Yup.object({
    accountHolderName: Yup.string()
      .trim()
      .required(
        translator("validationMessage.accountHolderNameRequired", {
          ns: "activationPortal"
        })
      )
      .label(
        translator("validationMessage.accountHolderName", {
          ns: "activationPortal"
        })
      ),
    bankAccountNumber: Yup.string()
      .trim()
      .required(
        translator("validationMessage.bankAccountNumberRequired", {
          ns: "activationPortal"
        })
      )
      //@ts-ignore
      .iban("Invalid IBAN")
      .label(
        translator("validationMessage.bankAccountNumber", {
          ns: "activationPortal"
        })
      ),
    authorizeAllsetra: Yup.bool()
      .label(
        translator("validationMessage.authorizeAllsetra", {
          ns: "activationPortal"
        })
      )
      .oneOf([true], "Please accept the conditions"),
    termsAndConditions: Yup.bool()
      .label(
        translator("validationMessage.termsAndConditions", {
          ns: "activationPortal"
        })
      )
      .oneOf([true], "Please accept the conditions"),
    privacyPolicy: Yup.bool()
      .label(
        translator("validationMessage.privacyPolicy", {
          ns: "activationPortal"
        })
      )
      .oneOf([true], "Please accept Privacy Policy"),
    directDebit: Yup.bool()
      .label(
        translator("validationMessage.directDebit", {
          ns: "activationPortal"
        })
      )
      .oneOf([true], "Please accept Payment policy")
  });

//Overview
export const overviewInitialValues = {
  conditions: false
};

export const overviewValidation = (translator: any) =>
  Yup.object({
    conditions: Yup.bool()
      .label(
        translator("validationMessage.conditions", {
          ns: "activationPortal"
        })
      )
      .oneOf([true], "Please accept the conditions")
  });

export const overviewInformationStepData = (information: IActivationPortal) => {
  const address = `${information?.companyInfo?.street} ${information?.companyInfo?.number},
  ${information?.companyInfo?.postCode} ${information?.companyInfo?.city}, ${information?.companyInfo?.country}`;

  const contactName = `${information.userInfo?.prefix || ""} ${
    information.userInfo?.firstName
  } ${information.userInfo?.lastName}`;

  return { address, contactName };
};

export const overviewInformationAlarmPersonData = (
  prefix: string,
  firstName: string,
  lastName: string
) => {
  const alarmPersonName = `${prefix || ""} ${firstName} ${lastName}`;

  return { alarmPersonName };
};

export const transformObjectForActivationInfoTable = (
  object: any | null
): any => {
  if (!object) return {};

  const data: any = {};

  const metadata = sanitizeActivationPortalMetadataForDynamicFields(object);

  if (metadata.length) {
    metadata.map((meta: any) => (data[meta.field] = meta.value));
  } else {
    data["data"] = "No metadata available";
  }

  return data;
};

export const sanitizeActivationPortalMetadataForDynamicFields = (
  object: any
) => {
  const metadata = object.metadata;

  if (isEmpty(metadata)) return [];

  return sortBy(
    metadata.filter(
      (item: any) =>
        item.fieldInfo.customerPortal === ObjectFieldsEnum.isDynamicField ||
        item.fieldInfo.customerPortal === ObjectFieldsEnum.isBoth
    ),
    [(o) => o.field.label]
  );
};

type CountryCode = {
  value: string;
};

export const europeanCountryCodes: CountryCode[] = [
  { value: "+355" },
  { value: "+376" },
  { value: "+374" },
  { value: "+43" },
  { value: "+994" },
  { value: "+375" },
  { value: "+32" },
  { value: "+387" },
  { value: "+359" },
  { value: "+385" },
  { value: "+357" },
  { value: "+420" },
  { value: "+45" },
  { value: "+372" },
  { value: "+298" },
  { value: "+358" },
  { value: "+33" },
  { value: "+995" },
  { value: "+49" },
  { value: "+350" },
  { value: "+30" },
  { value: "+299" },
  { value: "+36" },
  { value: "+354" },
  { value: "+353" },
  { value: "+39" },
  { value: "+7" },
  { value: "+383" },
  { value: "+371" },
  { value: "+423" },
  { value: "+370" },
  { value: "+352" },
  { value: "+356" },
  { value: "+373" },
  { value: "+377" },
  { value: "+382" },
  { value: "+31" },
  { value: "+389" },
  { value: "+47" },
  { value: "+48" },
  { value: "+351" },
  { value: "+40" },
  { value: "+7" },
  { value: "+378" },
  { value: "+381" },
  { value: "+421" },
  { value: "+386" },
  { value: "+34" },
  { value: "+46" },
  { value: "+41" },
  { value: "+90" },
  { value: "+380" },
  { value: "+44" },
  { value: "+39" }
];

interface PhoneNumber {
  countryCode: string | null;
  phone: string;
}

export function separateCountryCode(phoneNumber: string): PhoneNumber {
  if (!phoneNumber) {
    return {
      countryCode: "",
      phone: ""
    };
  }
  const cleanedNumber = phoneNumber?.replace(/^\+/, "");

  for (let i = 1; i <= 3; i++) {
    const potentialCodeWithPlus = "+" + cleanedNumber.slice(0, i);
    if (
      europeanCountryCodes.some(
        (codeObj) => codeObj.value === potentialCodeWithPlus
      )
    ) {
      return {
        countryCode: potentialCodeWithPlus,
        phone: cleanedNumber.slice(i)
      };
    }
  }

  return {
    countryCode: "",
    phone: cleanedNumber
  };
}

export const formatPhoneNumberWithCountryCode = (
  countryCode: string,
  phoneNumber: string
) => {
  return `${countryCode}${phoneNumber}`;
};

export const mapAlarmPersons = (
  alarmPersons: Array<{
    countryCode1: string;
    phoneNumber1: string;
    countryCode2: string;
    phoneNumber2: string;
  }>
) => {
  return alarmPersons.map((person) => ({
    ...person,
    phoneNumber1: formatPhoneNumberWithCountryCode(
      person.countryCode1,
      person.phoneNumber1
    ),
    phoneNumber2: formatPhoneNumberWithCountryCode(
      person.countryCode2,
      person.phoneNumber2
    )
  }));
};
