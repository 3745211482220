import { FC, useEffect, useLayoutEffect, useState } from "react";
import ObjectDetailsSection from "components/sections/objects/ObjectDetailsSection/ObjectDetailsSection";
import ObjectTabsLayout from "components/common/objects/ObjectTabsLayout/ObjectTabsLayout";

// Data
import { useAppSelector } from "hooks";
import {
  selectActiveObjectAlarmsConfig,
  selectDrawerSelectedAccountId,
  selectSpecificObjectState
} from "app/data/selectors";
import { useNavigate } from "react-router-dom";

const ObjectDetails: FC = () => {
  const navigate = useNavigate();

  // Global State
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";
  const { specificObject } = useAppSelector(selectSpecificObjectState);
  const { alarmsConfig } = useAppSelector(selectActiveObjectAlarmsConfig);
  const [selectAccountId, setSelectedAccountId] = useState(
    drawerSelectedAccountId
  );

  useEffect(() => {
    if (drawerSelectedAccountId !== selectAccountId) {
      setSelectedAccountId(drawerSelectedAccountId);
      navigate("/dashboard/objects");
    }
  }, [drawerSelectedAccountId]);

  return (
    <main>
      <ObjectTabsLayout>
        <ObjectDetailsSection
          specificObject={specificObject}
          alarmsConfig={alarmsConfig}
        />
      </ObjectTabsLayout>
    </main>
  );
};

export default ObjectDetails;
