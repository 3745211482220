import { FC, Fragment, useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet";
import UnprotectedRoute from "components/routes/UnprotectedRoute/UnprotectedRoute";
import ProtectedRoute from "components/routes/ProtectedRoute/ProtectedRoute";
import FaviconManager from "components/common/FaviconManager";

// Data
import { useAppSelector, useDispatchOnMount } from "hooks";
import { getMuiCustomTheme } from "app/theme";
import { fietsgestolenPortalDynamicRoutes } from "app/data/helpers";
import {
  selectAccountFeaturesState,
  selectWhiteLabelsState
} from "app/data/selectors";
import { getWhiteLabelConfigurationThunk } from "app/features";

/// UNPROTECTED PAGES
import Auth from "pages/Auth";
import ResetPassword from "pages/auth/ResetPassword";
import NewPassword from "pages/auth/NewPassword";
import NotFound from "pages/NotFound";

/// DASHBOARD PROTECTED PAGES
import Maps from "pages/dashboard/Maps";
// import Alerts from "pages/dashboard/Alerts";
import Geozones from "pages/dashboard/Geozones";
import GeozoneDetails from "pages/dashboard/Geozones/GeozoneDetails";
import Objects from "pages/dashboard/Objects";
import Users from "pages/dashboard/Users";
import Accounts from "pages/dashboard/Accounts";
import AccountSettings from "pages/dashboard/Accounts/AccountSettings";
import Groups from "pages/dashboard/Groups";
import ObjectDetails from "pages/dashboard/Objects/ObjectDetails";
import ObjectRides from "pages/dashboard/Objects/ObjectRides";
import ObjectRideMap from "pages/dashboard/Objects/ObjectRideMap";
import ObjectSettings from "pages/dashboard/Objects/ObjectSettings";
import KeysManager from "pages/dashboard/KeysManager";

// ACTIVATION PORTAL PAGES
import StartActivation from "pages/activationPortal/StartActivation";
import ActivationVerifyCode from "pages/activationPortal/ActivationVerifyCode";
import ActivationForm from "pages/activationPortal/ActivationForm";

//FIETSGESTOLEN PAGES
import StartActivateTheft from "pages/Fietsgestolen/StartActivateTheft";
import StartReportTheft from "pages/Fietsgestolen/StartReportTheft";
import FietsgestolenForm from "pages/Fietsgestolen/FietsgestolenForm";
import SecureBikeForm from "pages/Fietsgestolen/SecureBikeForm";
import Settings from "pages/dashboard/Settings";

//REPORTS PAGES
import TagPosition from "pages/dashboard/Reports/TagPosition";
import MilageRegistration from "pages/dashboard/Reports/MilageRegistration";
import WorkingHours from "pages/dashboard/Reports/WorkingHours";
import GraphicalRides from "pages/dashboard/Reports/GraphicalRides";
import GraphicalWorkingHours from "pages/dashboard/Reports/GraphicalWorkingHours";
import ScheduleReports from "pages/dashboard/Reports/ScheduleReports";
import ExportedReportHistory from "pages/dashboard/Reports/ExportedReportHistory";
import CO2Reports from "pages/dashboard/Reports/CO2Reports";
import MultipleRides from "pages/dashboard/Reports/MultipleRides";

const App: FC = () => {
  const { whiteLabel, isFetched: whiteLabelIsFetched } = useAppSelector(
    selectWhiteLabelsState
  );
  const { features } = useAppSelector(selectAccountFeaturesState);

  const theme = useMemo(() => {
    return getMuiCustomTheme(whiteLabel);
  }, [whiteLabel]);

  const {
    isPrimrayHostname,
    isDomainFietsgestolen,
    pathnames: fietsgestolenPortalPathnames
  } = fietsgestolenPortalDynamicRoutes();

  useDispatchOnMount(
    getWhiteLabelConfigurationThunk,
    process.env.REACT_APP_WHITELABEL_ID,
    whiteLabelIsFetched ? undefined : true
  );

  const pageTitle = whiteLabel?.emailAccountName ?? "Allsetra Portal";

  return (
    <main>
      <Helmet>
        <FaviconManager whiteLabel={whiteLabel} />
        <title>{pageTitle}</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Routes>
          {isPrimrayHostname && (
            <Fragment>
              {/* AUTH ROUTES */}
              <Route
                path="/"
                element={<UnprotectedRoute redirectTo="/dashboard" />}
              >
                <Route index element={<Auth />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/new-password" element={<NewPassword />} />
              </Route>

              {/* DASHBOARD ROUTES */}
              <Route
                path="/dashboard"
                element={<ProtectedRoute redirectTo="/" />}
              >
                <Route index element={<Maps />} />
                {/* <Route path="/dashboard/alerts" element={<Alerts />} /> */}

                <Route path="/dashboard/geozones" element={<Geozones />} />

                <Route
                  path="/dashboard/geozones/:id"
                  element={<GeozoneDetails />}
                />

                <Route path="/dashboard/objects" element={<Objects />} />

                <Route
                  path="/dashboard/objects/details/:id"
                  element={<ObjectDetails />}
                />

                <Route
                  path="/dashboard/objects/rides/:id"
                  element={<ObjectRides />}
                />

                <Route
                  path="/dashboard/objects/:objectId/rides/map/:rideId"
                  element={<ObjectRideMap />}
                />

                <Route
                  path="/dashboard/objects/:id/settings"
                  element={<ObjectSettings />}
                />

                <Route path="/dashboard/users" element={<Users />} />

                <Route path="/dashboard/accounts" element={<Accounts />} />

                <Route
                  path="/dashboard/accounts/settings/:id"
                  element={<AccountSettings />}
                />

                <Route path="/dashboard/groups" element={<Groups />} />

                <Route
                  path="/dashboard/keys-manager"
                  element={<KeysManager />}
                />

                <Route path="/dashboard/settings" element={<Settings />} />

                {/* Reports */}
                <Route
                  path="/dashboard/reports"
                  element={
                    features.isRidesEnabled ? (
                      <MilageRegistration />
                    ) : (
                      <TagPosition />
                    )
                  }
                />

                <Route
                  path="/dashboard/reports/tag-position"
                  element={<TagPosition />}
                />
                <Route
                  path="/dashboard/reports/working-hours"
                  element={<WorkingHours />}
                />

                {features.isRidesEnabled ? (
                  <Fragment>
                    <Route
                      path="/dashboard/reports/mileage-registration"
                      element={<MilageRegistration />}
                    />

                    <Route
                      path="/dashboard/reports/graphical-rides"
                      element={<GraphicalRides />}
                    />
                  </Fragment>
                ) : (
                  <>
                    <Route
                      path="/dashboard/reports/tag-position"
                      element={<TagPosition />}
                    />
                  </>
                )}

                <Route
                  path="/dashboard/reports/graphical-working-hours"
                  element={<GraphicalWorkingHours />}
                />
                <Route
                  path="/dashboard/reports/exported-history"
                  element={<ExportedReportHistory />}
                />

                <Route
                  path="/dashboard/reports/co2-reports"
                  element={<CO2Reports />}
                />

                <Route
                  path="/dashboard/reports/multiple-rides"
                  element={<MultipleRides />}
                />

                {features.isAutomaticReportsEnabled ? (
                  <Route
                    path="/dashboard/reports/schedule-reports"
                    element={<ScheduleReports />}
                  />
                ) : (
                  <Route
                    path="/dashboard/reports"
                    element={
                      features.isRidesEnabled ? (
                        <MilageRegistration />
                      ) : (
                        <TagPosition />
                      )
                    }
                  />
                )}
              </Route>

              {/* ACTIVATION PORTAL ROUTES */}
              <Route path="/activation-portal">
                <Route index element={<StartActivation />} />
                <Route
                  path="/activation-portal/verify-code"
                  element={<ActivationVerifyCode />}
                />
                <Route
                  path="/activation-portal/form"
                  element={<ActivationForm />}
                />
              </Route>
            </Fragment>
          )}

          {/* FIETSGESTOLEN PORTAL ROUTES */}
          <Route path={fietsgestolenPortalPathnames.indexPath}>
            <Route
              index
              element={
                isDomainFietsgestolen ? (
                  <StartReportTheft />
                ) : (
                  <StartActivateTheft />
                )
              }
            />
            {!isDomainFietsgestolen && (
              <Route
                path={fietsgestolenPortalPathnames.startReportTheftPathname}
                element={<StartReportTheft />}
              />
            )}
            <Route
              path={fietsgestolenPortalPathnames.reportTheftFormPathname}
              element={<FietsgestolenForm />}
            />
            {!isDomainFietsgestolen && (
              <Route
                path={
                  fietsgestolenPortalPathnames.activateTheftProtectionFormPathname
                }
                element={<SecureBikeForm />}
              />
            )}
          </Route>

          <Route
            path="*"
            element={
              features.isRidesEnabled ? (
                <NotFound />
              ) : (
                <ProtectedRoute redirectTo="/" />
              )
            }
          />
        </Routes>
      </ThemeProvider>
    </main>
  );
};

export default App;
