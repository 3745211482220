import { FC, Fragment } from "react";
import {
  PageContainer,
  FormContainer,
  LeftContainer,
  Logo,
  RightContainer,
  SubTitle,
  Title,
  Circle164Container,
  Circle215LogoContainer,
  Circle577LogoContainer,
  LogoWrapper,
  InnerBox
} from "./BasicPageLayout.styled";
import { Circles164, Circles215, Circles577 } from "assets/icons";
import { useAppSelector } from "hooks";
import { selectWhiteLabelsState } from "app/data/selectors";
import { PageLoader } from "@vilocnv/allsetra-core";
import { isEmpty } from "lodash";

interface Props {
  title: string;
  subtitle?: string;
  whiteLabelBgImage?: string;
  loading?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const BasicPageLayout: FC<Props> = ({
  title,
  subtitle,
  whiteLabelBgImage,
  loading,
  children
}) => {
  const { whiteLabel } = useAppSelector(selectWhiteLabelsState);

  return (
    <PageContainer>
      <LeftContainer>
        {loading ? (
          <PageLoader />
        ) : (
          <InnerBox>
            <Fragment>
              {whiteLabel ? (
                <LogoWrapper>
                  <img
                    className="logo-object-fit"
                    src={whiteLabel.lightThemeLogoUrl}
                    alt="logo"
                  />
                </LogoWrapper>
              ) : (
                <Logo />
              )}
            </Fragment>
            <Title>{title}</Title>
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
            <FormContainer>{children}</FormContainer>
            <Circle164Container>
              <Circles164 />
            </Circle164Container>
          </InnerBox>
        )}
      </LeftContainer>
      <RightContainer whiteLabelBgImage={whiteLabelBgImage}>
        {isEmpty(whiteLabelBgImage) && (
          <Fragment>
            <Circle215LogoContainer>
              <Circles215 />
            </Circle215LogoContainer>
            <Circle577LogoContainer>
              <Circles577 />
            </Circle577LogoContainer>
          </Fragment>
        )}
      </RightContainer>
    </PageContainer>
  );
};

export default BasicPageLayout;
