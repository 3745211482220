import { FC, useEffect, useState } from "react";
import { PageLoader } from "@vilocnv/allsetra-core";
import ObjectSettingsForm from "components/forms/objects/ObjectSettingsForm/ObjectSettingsForm";

// DATA
import { useActiveObjectById, useAppSelector } from "hooks";
import { PageLoaderCentered } from "components/sections/objects/ObjectDetailsSection/ObjectDetailsSection.styled";
import {
  selectDrawerSelectedAccountId,
  selectIsDrawerCollapsed
} from "app/data/selectors";
import { useNavigate } from "react-router-dom";

const ObjectSettings: FC = () => {
  const navigate = useNavigate();
  // Global State
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";
  const { specificObject, loading } = useActiveObjectById();
  const isDrawerCollapsed = useAppSelector(selectIsDrawerCollapsed);
  const [selectAccountId, setSelectedAccountId] = useState(
    drawerSelectedAccountId
  );

  useEffect(() => {
    if (drawerSelectedAccountId !== selectAccountId) {
      setSelectedAccountId(drawerSelectedAccountId);
      navigate("/dashboard/objects");
    }
  }, [drawerSelectedAccountId]);

  return (
    <div>
      {loading ? (
        <PageLoaderCentered isDrawerCollapsed={isDrawerCollapsed}>
          <PageLoader />
        </PageLoaderCentered>
      ) : (
        <ObjectSettingsForm activeObject={specificObject} />
      )}
    </div>
  );
};

export default ObjectSettings;
