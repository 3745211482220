import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectReportsReducerLoading = (state: RootState) =>
  state.rootReducer.reportsReducer.loading;

export const selectAllReports = (state: RootState) =>
  state.rootReducer.reportsReducer.report;

export const selectAllResolvedAddress = (state: RootState) =>
  state.rootReducer.reportsReducer.resolvedAddresses;

export const selectResolvedAddressLoading = (state: RootState) =>
  state.rootReducer.reportsReducer.resolvedAddressLoading;

export const selectReportsFilterData = (state: RootState) =>
  state.rootReducer.reportsReducer.reportsFilterData;

export const selectReportsHasExceededLimit = (state: RootState) =>
  state.rootReducer.reportsReducer.hasExceededLimit;

export const selectReportsState = createSelector(
  selectReportsReducerLoading,
  selectAllReports,
  selectAllResolvedAddress,
  selectResolvedAddressLoading,
  selectReportsFilterData,
  selectReportsHasExceededLimit,
  (
    loading,
    report,
    resolvedAddresses,
    resolvedAddressLoading,
    reportsFilterData,
    reportsHasExceededLimit
  ) => {
    return {
      loading,
      report,
      resolvedAddresses,
      resolvedAddressLoading,
      reportsFilterData,
      reportsHasExceededLimit
    };
  }
);
