import { FC, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import {
  Table,
  types,
  useDispatchOnParams,
  Dropdown,
  utils
} from "@vilocnv/allsetra-core";
import BulkRidesUpdateDriverForm from "components/forms/objects/BulkRidesUpdateDriverForm/BulkRidesUpdateDriverForm";
import BulkRidesUpdateOrderForm from "components/forms/objects/BulkRidesUpdateOrderForm/BulkRidesUpdateOrderForm";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { OBJECT_RIDES_TABLE_COLUMNS } from "app/data/constants";
import {
  selectObjectRidesState,
  selectDrawerSelectedAccountId
} from "app/data/selectors";
import {
  getObjectRidesByQueryThunk,
  setObjectSpecificRide
} from "app/features";
import { useTranslation } from "react-i18next";
import { EditBoxIcon } from "assets/icons";

interface Props {
  specificObject: types.IObject | null;
}

const ObjectRidesSection: FC<Props> = ({ specificObject }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Global State
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) ?? "";

  const { objectRides, objectRidesRowCount, ridesLoading } = useAppSelector(
    selectObjectRidesState
  );

  // Local State
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectedRowsIds, setSelectedRowsIds] = useState<Array<string>>([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  // Local State Forms
  const [openBulkModeForm, setOpenBulkModeForm] = useState(false);
  const [openBulkDriverForm, setOpenBulkDriverForm] = useState(false);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(() => OBJECT_RIDES_TABLE_COLUMNS(t), [t]);

  useDispatchOnParams(getObjectRidesByQueryThunk, {
    args: {
      accountId: drawerSelectedAccountId,
      objectId: specificObject?.uniqueId || ""
    },
    additionalParams: {
      includeRidesWithoutDriver: true
    },
    orderBy: [{ field: "startDate", ascending: true }]
  });

  const hanldeViewOnMap = (ride: types.IObjectRide) => {
    dispatch(setObjectSpecificRide(ride));
    navigate(
      `/dashboard/objects/${specificObject?.uniqueId}/rides/map/${ride?.uniqueId}`
    );
  };

  const handleDropdownAnchorClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const onSelectedRowsChange = ({ selectedRows }: any) => {
    const ids = selectedRows?.map((item: any) => item.uniqueId) ?? [];
    setSelectedRowsIds(ids);
  };

  const handleClearRows = () => {
    setSelectedRowsIds([]);
    setToggleClearRows(!toggledClearRows);
  };

  const fetchDataAndResetSelectableRows = () => {
    dispatch(
      getObjectRidesByQueryThunk({
        accountId: drawerSelectedAccountId ?? "",
        objectId: specificObject?.uniqueId ?? "",
        params: {
          ...utils.getCommonParamsForApi(),
          includeRidesWithoutDriver: true,
          orderBy: [{ field: "startDate", ascending: true }]
        }
      })
    );
    setSelectedRowsIds([]);
    setToggleClearRows(true);
  };

  return (
    <Box mt={4}>
      <Table
        theme={theme}
        translator={t}
        columns={tableColumns}
        data={objectRides ?? []}
        progressPending={ridesLoading}
        paginationTotalRows={objectRidesRowCount}
        showDateRangeFilter={true}
        cellActions={[
          {
            name: t("buttonsText.viewOnmap"),
            onClick: hanldeViewOnMap
          }
        ]}
        primaryButton={{
          variant: "outlined",
          text: t("buttonsText.cancel"),
          size: "small",
          onClick: handleClearRows,
          disabled: !selectedRowsIds.length,
          theme
        }}
        secondaryButton={{
          disabled:
            ridesLoading || objectRides.length === 0 || !selectedRowsIds.length,
          variant: "contained",
          text: t("buttonsText.updateItems"),
          size: "small",
          endIcon: <EditBoxIcon />,
          onClick: (event: React.MouseEvent<HTMLButtonElement>) =>
            handleDropdownAnchorClick(event),
          theme
        }}
        selectableRows={true}
        onSelectedRowsChange={onSelectedRowsChange}
        clearSelectedRows={toggledClearRows}
      />
      <Dropdown
        anchorEl={dropdownAnchorEl}
        setAnchorEl={setDropdownAnchorEl}
        items={[
          {
            name: t("common.changeRideType"),
            onClick: () => setOpenBulkModeForm(true)
          },
          {
            name: t("common.changeDriver"),
            onClick: () => setOpenBulkDriverForm(true)
          }
        ]}
      />
      {/* Forms */}
      <BulkRidesUpdateOrderForm
        open={openBulkModeForm}
        onClose={() => setOpenBulkModeForm(false)}
        rides={selectedRowsIds}
        afterSubmitActionHandler={fetchDataAndResetSelectableRows}
      />
      <BulkRidesUpdateDriverForm
        open={openBulkDriverForm}
        onClose={() => setOpenBulkDriverForm(false)}
        rides={selectedRowsIds}
        afterSubmitActionHandler={fetchDataAndResetSelectableRows}
      />
    </Box>
  );
};

export default ObjectRidesSection;
