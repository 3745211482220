import { FC } from "react";
import { Button, FormikInputField } from "@vilocnv/allsetra-core";
import { Stack, useTheme } from "@mui/material";
import { ButtonsRow, DaysTitle } from "./DateAndTimeFilters.styled";
import { useTranslation } from "react-i18next";
import { daysOfWeekOptions } from "app/data/helpers";
import { useFormikContext } from "formik";

interface Props {
  values: any;
}

const DateAndTimeFilters: FC<Props> = ({ values }) => {
  const theme = useTheme();
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);
  const { setFieldValue } = useFormikContext();

  const handleDaysChange = (value: string) => {
    const daysSet = new Set(values.daysOfWeek);
    if (daysSet.has(value)) daysSet.delete(value);
    else daysSet.add(value);
    setFieldValue("daysOfWeek", Array.from(daysSet));
  };

  return (
    <Stack spacing={2}>
      <DaysTitle>
        {t("selectDays.label", {
          ns: "formFieldsTranslation"
        })}
      </DaysTitle>
      <ButtonsRow>
        {daysOfWeekOptions.map((day: any) => (
          <Button
            key={`${day.label}`}
            text={t("daysListShort." + day.label)}
            size="small"
            variant={
              values?.daysOfWeek?.includes(day.value) ? "contained" : "outlined"
            }
            theme={theme}
            onClick={() => handleDaysChange(day.value)}
            sx={{
              borderRadius: 0,
              ...(day.label === "Mon" && {
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5
              }),
              ...(day.label === "Sun" && {
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                mr: 2
              })
            }}
          />
        ))}
      </ButtonsRow>

      <FormikInputField
        theme={theme}
        type={"time"}
        name="startTime"
        label={t("startTime.label", {
          ns: "formFieldsTranslation"
        })}
        fullWidth
      />
      <FormikInputField
        theme={theme}
        type={"time"}
        name="endTime"
        label={t("endTime.label", {
          ns: "formFieldsTranslation"
        })}
        fullWidth
      />
    </Stack>
  );
};

export default DateAndTimeFilters;
