import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "@vilocnv/allsetra-core";
import FietsgestolenService from "app/data/services/FietsgestolenService";

export const verifyEmailThunk = createAsyncThunk(
  "fietsgestolen/verifyEmailThunk",
  async (data: any) => {
    try {
      const response = await FietsgestolenService.verifyEmail(data);

      if (response.status === 202) {
        toast.success("Verification code has been sent");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const verifyCodeThunk = createAsyncThunk(
  "fietsgestolen/verifyCodeThunk",
  async (data: any) => {
    try {
      const response = await FietsgestolenService.checkVerificationCode(data);

      if (response.status === 200) {
        toast.success("Verification code successful!");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      if (e.response.status === 400) {
        toast.error("Invalid verification code!");
      }
      throw new Error(e);
    }
  }
);

export const listObjectsThunk = createAsyncThunk(
  "fietsgestolen/listObjectsThunk",
  async (data: any) => {
    try {
      const response = await FietsgestolenService.listAllObjects(data);

      if (response.status === 202) {
        toast.success("Objects listed");
      }

      return response.data;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const registerTheftThunk = createAsyncThunk(
  "fietsgestolen/registerTheftThunk",
  async (data: any) => {
    try {
      const response = await FietsgestolenService.registerTheft(data);

      if (response.status === 202) {
        toast.success("Theft Registered Successfully!");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const verifyBarcodeThunk = createAsyncThunk(
  "fietsgestolen/verifyBarcodeThunk",
  async (data: any) => {
    try {
      const response = await FietsgestolenService.verifyBarcode(data);

      if (response.status === 200) {
        toast.success("Barcode Verified Successfully!");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      if (e.response.status === 404) {
        toast.error("Invalid Code!");
      }
      throw new Error(e);
    }
  }
);

export const registerFietsThunk = createAsyncThunk(
  "fietsgestolen/registerFietsThunk",
  async (data: any) => {
    try {
      const response = await FietsgestolenService.registerFiets(data);

      if (response.status === 202) {
        toast.success("Registered Successfully!");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
