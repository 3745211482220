import { FC } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { FormikSelectField } from "@vilocnv/allsetra-core";
import { useAppSelector } from "hooks";
import { selectUserDataState } from "app/data/selectors";

const InnerForm: FC = () => {
  const { objectList } = useAppSelector(selectUserDataState);
  const theme = useTheme();
  const isDownSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack spacing={4}>
      <FormikSelectField
        theme={theme}
        label="Fiets"
        name="objectId"
        options={objectList}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        fullWidth={isDownSmall}
      />
    </Stack>
  );
};

export default InnerForm;
