import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectKeysManagerReducerLoading = (state: RootState) =>
  state.rootReducer.keysManagerReducer.loading;

export const selectAccountKeys = (state: RootState) =>
  state.rootReducer.keysManagerReducer.accountKeys;

export const selectAccountTotalRecords = (state: RootState) =>
  state.rootReducer.keysManagerReducer.totalRecords;

export const selectAccountAvailableKeysLoading = (state: RootState) =>
  state.rootReducer.keysManagerReducer.accountAvailableKeysLoading;

export const selectAccountAvailableKeys = (state: RootState) =>
  state.rootReducer.keysManagerReducer.accountAvailableKeys;

export const selectAccountKeysState = createSelector(
  selectKeysManagerReducerLoading,
  selectAccountTotalRecords,
  selectAccountKeys,
  selectAccountAvailableKeysLoading,
  selectAccountAvailableKeys,
  (
    loading,
    totalRecords,
    accountKeys,
    accountAvailableKeysLoading,
    accountAvailableKeys
  ) => ({
    loading,
    totalRecords,
    accountKeys,
    accountAvailableKeysLoading,
    accountAvailableKeys
  })
);

export const selectAccountAvailableKeysState = createSelector(
  selectAccountAvailableKeysLoading,
  selectAccountAvailableKeys,
  (accountAvailableKeysLoading, accountAvailableKeys) => ({
    accountAvailableKeysLoading,
    accountAvailableKeys
  })
);
