import { FC, useMemo } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import InnerForm from "./InnerForm";

// Data
import { useAppDispatch } from "hooks";
import {
  objectMileageCorrectionInitialValues,
  objectMileageCorrectionSchema
} from "app/data/helpers";
import { IObjectCorrection } from "app/data/types";
import { updateAccountMileageThunk } from "app/features";

interface Props {
  specificObject: any;
  accountId: string;
}

const MileageCorrectionForm: FC<Props> = ({ specificObject, accountId }) => {
  const dispatch = useAppDispatch();

  const initialValues = useMemo(() => {
    if (specificObject?.correctionDate && specificObject?.mileageCorrection) {
      return {
        newMileage: specificObject?.mileageCorrection,
        correctionDate: specificObject?.correctionDate.replace("Z", "")
      };
    } else {
      return objectMileageCorrectionInitialValues;
    }
  }, [specificObject]);

  const handleCorrectionFormSubmit = async (
    data: IObjectCorrection,
    formikHelpers: FormikHelpers<IObjectCorrection>
  ) => {
    formikHelpers.setSubmitting(true);

    await dispatch(
      updateAccountMileageThunk({
        data,
        accountId: accountId || "",
        objectId: specificObject?.uniqueId || ""
      })
    );

    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={objectMileageCorrectionSchema}
      onSubmit={handleCorrectionFormSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid, resetForm, isSubmitting }) => (
        <Form>
          <InnerForm
            isSubmitting={isSubmitting}
            isValid={isValid}
            handleSubmit={handleSubmit}
            resetForm={resetForm}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MileageCorrectionForm;
