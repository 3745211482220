import { FC, Fragment, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";
import SubscriptionPlans from "./children/SubscriptionPlans";
import SubscriptionInformation from "./children/SubscriptionInformation";

//Data
import { useAppDispatch, useAppSelector } from "hooks";
import { selectActivationPortalState } from "app/data/selectors/activationPortalSelectors";
import { PageLoader, useDispatchOnParams } from "@vilocnv/allsetra-core";
import { setSubscribeFormData, getSubscriptionThunk } from "app/features";
import { isEmpty } from "lodash";
import { subscriptionDataFormatter } from "app/data/helpers";
import { useTranslation } from "react-i18next";

interface Props {
  gotoNextStep: () => void;
}

const ActivationSubscriptionSection: FC<Props> = ({ gotoNextStep }) => {
  const dispatch = useAppDispatch();

  //Global States
  const {
    subscriptions,
    installations,
    loading,
    subscribeFormData,
    verificationCode
  } = useAppSelector(selectActivationPortalState);

  // Local State
  const [selectedPlan, setSelectedPlan] = useState<string | null>(
    subscribeFormData?.subscriptionId ? subscribeFormData?.subscriptionId : null
  );

  const { t } = useTranslation([
    "translation",
    "activationPortal",
    "formFieldsTranslation"
  ]);

  useDispatchOnParams(getSubscriptionThunk, {
    args: { activationCode: verificationCode }
  });

  const onNextHandler = () => {
    dispatch(setSubscribeFormData({ subscriptionId: selectedPlan }));
    gotoNextStep();
  };

  const formValues = useMemo(
    () =>
      !isEmpty(installations) ? subscriptionDataFormatter(installations) : {},
    [installations]
  );

  return (
    <Fragment>
      {loading ? (
        <PageLoader />
      ) : (
        <StepScreenLayout
          title={t("titles.subscription", {
            ns: "activationPortal"
          })}
          subtitle={t("descriptions.subscription", {
            ns: "activationPortal"
          })}
          disabled={!selectedPlan}
          onButtonSubmit={onNextHandler}
        >
          <Grid container spacing={12}>
            <Grid item xs={12} sm={12} md={12} lg={7}>
              <SubscriptionPlans
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                subscriptions={subscriptions}
                tabIndex={subscribeFormData.tabSelectedIndex}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
              <SubscriptionInformation
                initialValues={formValues}
                installations={installations}
                translator={t}
              />
            </Grid>
          </Grid>
        </StepScreenLayout>
      )}
    </Fragment>
  );
};

export default ActivationSubscriptionSection;
