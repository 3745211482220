import axiosInstance from "app/axiosInstance";
import { types } from "@vilocnv/allsetra-core";

class KeysManager {
  static getAllKeysByAccount = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/keys`);
  };

  static getAvailableKeysByAccount = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/keys/available`);
  };

  static getAccountKeysByQuery = async (
    data: types.IRecordsAggregationBody,
    accountId: string
  ) => {
    return await axiosInstance.post(`/accounts/${accountId}/keys/search`, data);
  };

  static getSpecificKeyByAccount = async (accountId: string, keyId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/keys/${keyId}`);
  };

  static createKeys = async (accountId: string, data: any) => {
    return await axiosInstance.post(`/accounts/${accountId}/keys`, data);
  };

  static deactivateKey = async (accountId: string, keyId: string) => {
    return await axiosInstance.delete(`/accounts/${accountId}/keys/${keyId}`);
  };

  static activateKey = async (
    accountId: string,
    keyId: string,
    label: string
  ) => {
    return await axiosInstance.post(
      `/accounts/${accountId}/keys/${keyId}/activate`,
      { label }
    );
  };
}

export default KeysManager;
