import { TableColumn, utils } from "@vilocnv/allsetra-core";
import { getResolvedAddress } from "app/data/helpers";
import {
  COMMON_REPORT_INNER_TABLE_COLUMNS,
  COMMON_REPORT_TABLE_COLUMNS
} from "./commonReportsConstants";
import LocationLink from "components/ui/links";

/**
 * MILAGE REGISTRATION
 */

export const MILAGE_REGISTRATION_DATE_RENDERING: TableColumn<any>[] = [
  { selector: (row: any) => row.label }
];

export const MILAGE_REGISTRATION_TABLE_COLUMNS = (
  translator: any
): TableColumn<any>[] => [
  {
    name: translator("tableHeading.ride", { ns: "tableHeadingsTranslation" }),
    selector: (row: any) => row?.name || "N/A",
    wrap: true
  },
  {
    name: translator("tableHeading.startTime", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.endTime", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.startMileage", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.endMileage", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.startAddress", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.endAddress", {
      ns: "tableHeadingsTranslation"
    })
  },
  ...COMMON_REPORT_TABLE_COLUMNS(translator),
  {
    name: translator("tableHeading.comments", {
      ns: "tableHeadingsTranslation"
    })
  }
];

export const MILAGE_REG_INNER_TABLE_COLUMNS = (resolvedAddress: Array<any>) => {
  return [
    { selector: (row: any) => row?.rideId || "-" },
    {
      selector: (row: any) => utils.formatDate({ date: row?.startDate }) || "-"
    },
    {
      selector: (row: any) =>
        row?.endDate !== null ? utils.formatDate({ date: row?.endDate }) : "-"
    },
    {
      cell: (row: any) =>
        row?.startMileage ? `${row?.startMileage.toFixed(1)} km` : "N/A"
    },
    {
      cell: (row: any) =>
        row?.endMileage ? `${row?.endMileage.toFixed(1)} km` : "N/A"
    },
    {
      cell: (row: any) => {
        const start = getResolvedAddress(row, resolvedAddress, "start");

        if (start) {
          return start;
        }

        if (row.startLatitude && row.startLongitude) {
          return (
            <LocationLink
              latitude={row.startLatitude}
              longitude={row.startLongitude}
            />
          );
        }

        return "N/A";
      }
    },
    {
      cell: (row: any) => {
        const end = getResolvedAddress(row, resolvedAddress, "end");

        if (end) {
          return end;
        }

        if (row.startLatitude && row.endLongitude) {
          return (
            <LocationLink
              latitude={row.endLatitude}
              longitude={row.endLongitude}
            />
          );
        }

        return "N/A";
      }
    },
    ...COMMON_REPORT_INNER_TABLE_COLUMNS(),
    {
      cell: (row: any) => row?.comments || "-"
    }
  ];
};

export const MILAGE_REG_HEADERS = [
  { label: "Object Name", key: "Object Name" },
  { label: "Ride", key: "Ride" },
  { label: "Start Mileage", key: "Start Mileage" },
  { label: "End Mileage", key: "End Mileage" },
  { label: "Start Time", key: "Start Time" },
  { label: "End Time", key: "End Time" },
  { label: "Start Address", key: "Start Address" },
  { label: "End Address", key: "End Address" },
  { label: "Key", key: "Key" },
  { label: "Ride Mode", key: "Ride Mode" },
  { label: "Distance", key: "Distance" },
  { label: "Ride Duration", key: "Ride Duration" },
  { label: "Comments", key: "Comments" }
];
