import { FC } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";
import InnerForm from "./children/InnerForm";
import {
  ObjectSelectionInitialValues,
  ObjectSelectionValidationSchema
} from "app/data/helpers/fietsgestolenHelpers";

import { AllsetraTriangles, PlusTwo } from "assets/icons";
import {
  LogoWrapper,
  TrianglesWrapper,
  VectorSVGs
} from "../FietsgestolenForms.styled";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectUserDataState } from "app/data/selectors";
import { IAddStolenObject } from "app/data/types";
import { registerTheftThunk } from "app/features";
import { toast } from "@vilocnv/allsetra-core";

interface Props {
  gotoNextStep: () => void;
}

const ObjectSelectionForm: FC<Props> = ({ gotoNextStep }) => {
  const dispatch = useAppDispatch();

  const { userEmail, verificationCodeFietsgestolen, registerTheftLoading } =
    useAppSelector(selectUserDataState);

  const onSubmitHandler = async (
    value: IAddStolenObject,
    formikHelpers: FormikHelpers<IAddStolenObject>
  ) => {
    formikHelpers.setSubmitting(true);

    const formattedData = {
      email: userEmail,
      verificationCode: verificationCodeFietsgestolen,
      objectId: value.objectId
    };

    const { type } = await dispatch(registerTheftThunk(formattedData));

    if (type === "fietsgestolen/registerTheftThunk/fulfilled") {
      formikHelpers.resetForm();
      gotoNextStep();
    }

    if (type === "fietsgestolen/registerTheftThunk/rejected") {
      toast.error("Error: Please try again!");
    }

    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={ObjectSelectionInitialValues}
      onSubmit={onSubmitHandler}
      validationSchema={ObjectSelectionValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <Form>
          <VectorSVGs>
            <PlusTwo style={{ position: "absolute", right: 0, top: "25vh" }} />
          </VectorSVGs>

          <LogoWrapper>
            <TrianglesWrapper>
              <AllsetraTriangles />
            </TrianglesWrapper>
          </LogoWrapper>

          <StepScreenLayout
            title="Selecteer je fiets"
            subtitle="Selecteer welke fiets is gestolen."
            disabled={!isValid}
            onButtonSubmit={handleSubmit}
            buttonText="Diefstal Melden"
            isFietsgestolen={true}
            loading={registerTheftLoading}
          >
            <InnerForm />
          </StepScreenLayout>
        </Form>
      )}
    </Formik>
  );
};

export default ObjectSelectionForm;
