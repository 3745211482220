export const POLYGON_OPTIONS = {
  fillColor: "lightblue",
  fillOpacity: 0.25,
  strokeColor: "#39b2a3",
  strokeOpacity: 1,
  strokeWeight: 3,
  clickable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
};

export const CIRCLE_OPTIONS = {
  fillColor: "lightblue",
  fillOpacity: 0.25,
  strokeColor: "#39b2a3",
  strokeOpacity: 1,
  strokeWeight: 3,
  clickable: true,
  editable: false,
  zIndex: 1
};

export const HIGHLIGHTS_COLORS = {
  strokeColor: "rgba(132, 90, 252, 1)",
  fillColor: "rgba(132, 90, 252, 0.16)"
};

export const POLYGON_HIGHLIGHTS_OPTIONS = {
  ...HIGHLIGHTS_COLORS,
  editable: true
};

export const CIRCLE_HIGHLIGHT_OPTIONS = {
  ...HIGHLIGHTS_COLORS,
  clickable: true,
  zIndex: 1
};

export const googleMapStyles = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.business",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.attraction",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.government",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.medical",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.park",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.place_of_worship",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.school",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "poi.sports_complex",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }]
  }
];
