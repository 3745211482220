import { FC, Fragment, useState, useEffect, useRef, Dispatch } from "react";
import {
  MapObjectCard,
  ObjectMetadataTags,
  PageLoader,
  types,
  utils
} from "@vilocnv/allsetra-core";
import { GeozonesMapContainer } from "../GeozonesMap/GeozonesMap.styled";

// Data
import { useAppSelector } from "hooks";
import { isEmpty } from "lodash";
import {
  getBoundsZoomLevel,
  getCenterCords,
  getElementSize,
  getParsedMetaDataForSpeed
} from "app/data/helpers";
import { selectGeozonesState } from "app/data/selectors";
import { useTranslation } from "react-i18next";
import SatelliteModeIcon from "../common/SatelliteModeIcon";
import { RideNotAvailableText } from "./ObjectRideGoogleMap.styles";
import GoogleMap from "../Map/v2/GoogleMap";
import { InfoWindowF, MarkerF, PolylineF } from "@react-google-maps/api";
import { useMapState } from "../Map/v2/GoogleMap/MapStateContext";

export interface ObjectRideGoogleMapProps {
  objectRideLocations: Array<types.IObjectRideLocation>;
  objectRide: types.IObjectRide | null;
  selectedLocation: types.IObjectRideLocation | null;
  setSelectedLocation: Dispatch<any>;
  onLocationItemClick: (data: types.IObjectRideLocation) => void;
}

const ObjectRideGoogleMap: FC<ObjectRideGoogleMapProps> = ({
  objectRideLocations,
  objectRide,
  selectedLocation,
  setSelectedLocation,
  onLocationItemClick
}) => {
  const { t } = useTranslation();
  const objectStartStopLocations = [
    {
      latitude: objectRide?.startLatitude,
      longitude: objectRide?.startLongitude,
      uniqueId: "0" + objectRide?.uniqueId,
      date: objectRide?.startDate,
      speed: objectRide?.averageSpeed
    },
    {
      latitude: objectRide?.endLatitude,
      longitude: objectRide?.endLongitude,
      uniqueId: "1" + objectRide?.uniqueId,
      date: objectRide?.endDate,
      speed: objectRide?.averageSpeed
    }
  ];
  const alarmContainerRef = useRef<HTMLDivElement>();
  const [isLoaded, setMapLoaded] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const { addressFromLatLng } = useAppSelector(selectGeozonesState);

  const {
    mapRef,
    loadError,
    onMapLoad,
    centerCoords,
    showSatelliteMode,
    setShowSatelliteMode
  } = useMapState();

  const handleLocationItemClick = (item: types.IObjectRideLocation) => {
    onLocationItemClick(item);
    if (item) {
      //@ts-ignore
      const { latitude, longitude } = item;
      mapRef?.panTo({ lat: latitude, lng: longitude });
    }
  };

  //for first time only
  useEffect(() => {
    const zoomLevel = getBoundsZoomLevel(
      // @ts-ignore
      objectRide?.isMigrated ? objectStartStopLocations : objectRideLocations,
      getElementSize(alarmContainerRef.current)
    );
    setZoomLevel(zoomLevel || 9);
    mapRef?.setZoom(zoomLevel || 9);

    const center = getCenterCords(
      // @ts-ignore
      objectRide?.isMigrated ? objectStartStopLocations : objectRideLocations,
      centerCoords
    );

    mapRef?.panTo(center);
    return () => {
      setSelectedLocation(null);
    };
  }, [objectRideLocations, mapRef]);

  useEffect(() => {
    if (zoomLevel) setMapLoaded(true);
  }, [zoomLevel]);

  useEffect(() => {
    if (selectedLocation) {
      //@ts-ignore
      const { latitude, longitude } = selectedLocation;
      mapRef?.panTo({ lat: latitude, lng: longitude });
    }
  }, [addressFromLatLng]);

  const icons = {
    start: require("../../../assets/icons/common/startLocation.svg").default,
    stop: require("../../../assets/icons/common/endLocation.svg").default
  };

  const getIcon = (index: number, array: any) => {
    if (index === 0) {
      return {
        url: icons.start,
        scaledSize: new window.google.maps.Size(32, 32),
        anchor: new google.maps.Point(25, 15)
      };
    } else if (index === array.length - 1) {
      return {
        url: icons.stop,
        scaledSize: new window.google.maps.Size(32, 32),
        anchor: new google.maps.Point(25, 25)
      };
    }
    return {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 5,
      fillColor: "#FFFFFF",
      fillOpacity: 1,
      strokeWeight: 1,
      strokeColor: "#959EB2"
    };
  };

  const getFormattedDate = (date: string) =>
    date ? utils.formatDate({ date }) : "N/A";

  const renderMarkers = () => {
    // @ts-ignore
    const result = objectRide?.isMigrated
      ? objectStartStopLocations
      : objectRideLocations;
    return result.map((item: any, i: number, array: any[]) => (
      <MarkerF
        onClick={() => handleLocationItemClick(item)}
        key={`${i}`}
        position={{
          lat: item?.latitude || 0,
          lng: item?.longitude || 0
        }}
        icon={getIcon(i, array)}
      >
        {selectedLocation?.uniqueId === item?.uniqueId && (
          <InfoWindowF
            position={{
              lat: selectedLocation?.latitude || 0,
              lng: selectedLocation?.longitude || 0
            }}
            onCloseClick={() => setSelectedLocation(null)}
          >
            <MapObjectCard
              label={objectRide?.object?.name || t("typography.na")}
              id={objectRide?.object?.aNumber || t("typography.na")}
              location={{
                isLoading: false,
                address: addressFromLatLng,
                latitude: selectedLocation?.latitude as number,
                longitude: selectedLocation?.longitude as number
              }}
              time={getFormattedDate(item?.date)}
              translator={t}
            >
              <ObjectMetadataTags
                translator={t}
                object={getParsedMetaDataForSpeed(item?.speed)}
                direction={"row"}
              />
            </MapObjectCard>
          </InfoWindowF>
        )}
      </MarkerF>
    ));
  };

  const renderPolylineF = () => {
    if (isEmpty(objectRideLocations)) return null;

    const path = objectRideLocations.map((item) => ({
      lat: item.latitude ?? 0,
      lng: item.longitude ?? 0
    }));

    return (
      <PolylineF
        path={path}
        options={{ strokeColor: "#488EEB", strokeWeight: 10 }}
      />
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (
    !objectRideLocations?.length &&
    !objectRide?.startLatitude &&
    !objectRide?.startLongitude &&
    !objectRide?.endLatitude &&
    !objectRide?.endLongitude
  ) {
    return (
      <RideNotAvailableText>
        {t("descriptions.rideDataNotAvialble")}
      </RideNotAvailableText>
    );
  }

  return (
    <Fragment>
      {isLoaded ? (
        <GeozonesMapContainer ref={alarmContainerRef}>
          <SatelliteModeIcon
            t={t}
            showSatelliteMode={showSatelliteMode}
            setShowSatelliteMode={setShowSatelliteMode}
          />
          <GoogleMap>
            {renderMarkers()}
            {renderPolylineF()}
          </GoogleMap>
        </GeozonesMapContainer>
      ) : (
        <PageLoader />
      )}
    </Fragment>
  );
};

export default ObjectRideGoogleMap;
