import React, { FC, useState } from "react";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";

import { AllsetraTriangles, PlusTwo } from "assets/icons";
import {
  CardImage,
  FietsgestolenArrow,
  LogoWrapper,
  TrianglesWrapper,
  VectorSVGs
} from "../FietsgestolenForms.styled";
import VerificationCodeInput from "components/ui/inputs/VerificationCodeInput/VerificationCodeInput";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectUserDataState } from "app/data/selectors";
import { setBarCode, verifyBarcodeThunk } from "app/features";

interface Props {
  gotoNextStep: () => void;
}

const BarcodeVerificationForm: FC<Props> = ({ gotoNextStep }) => {
  const [disabled, setDisabled] = useState(true);
  const [customBarCode, setCustomBarCode] = useState("");
  const dispatch = useAppDispatch();

  const { userEmail, verificationCodeFietsgestolen, barcodeLoading } =
    useAppSelector(selectUserDataState);

  const onSubmitHandler = async () => {
    const formattedData = {
      verificationCode: verificationCodeFietsgestolen,
      barcode: customBarCode,
      email: userEmail
    };

    const { type } = await dispatch(verifyBarcodeThunk(formattedData));

    if (type === "fietsgestolen/verifyBarcodeThunk/fulfilled") {
      dispatch(setBarCode(customBarCode));
      setCustomBarCode("");
      gotoNextStep();
    }
  };

  const handleOnComplete = (code: string) => {
    const correctCodeLength = 8;
    setCustomBarCode(code);
    if (code.length === correctCodeLength) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  return (
    <React.Fragment>
      <VectorSVGs>
        <PlusTwo style={{ position: "absolute", right: 0, top: "25vh" }} />
      </VectorSVGs>

      <LogoWrapper>
        <TrianglesWrapper>
          <AllsetraTriangles />
        </TrianglesWrapper>
      </LogoWrapper>

      <StepScreenLayout
        title="Activeren diefstalbeveiliging"
        subtitle="Voer de barcode in die je hebt ontvangen van je dealer. Dit staat op het bijbehorende pasje."
        disabled={disabled}
        onButtonSubmit={onSubmitHandler}
        buttonText="Activeren"
        isFietsgestolen={true}
        loading={barcodeLoading}
      >
        <div>
          <VerificationCodeInput onComplete={handleOnComplete} />
          <FietsgestolenArrow
            src={`${require("../../../../assets/images/fietsgestolenArrow.png")}`}
          />
          <CardImage
            src={`${require("../../../../assets/images/CardImage.png")}`}
          />
        </div>
      </StepScreenLayout>
    </React.Fragment>
  );
};

export default BarcodeVerificationForm;
