import { TableColumn } from "@vilocnv/allsetra-core";
import { formatTimeStamp } from "app/data/helpers";
import { Check, Cross } from "assets/icons";

export const TAG_POSITION_TABLE_COLUMNS = (
  translator: any
): TableColumn<any>[] => [
  {
    name: translator("tableHeading.time", { ns: "tableHeadingsTranslation" }),
    selector: (row: any) => row.label
  },
  {
    name: translator("tableHeading.location", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.accuracy", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: translator("tableHeading.accurate", {
      ns: "tableHeadingsTranslation"
    })
  },
  {
    name: "LAT LONG"
  }
];

export const TAG_POSITION_INNER_TABLE_COLUMNS: TableColumn<any>[] = [
  { selector: (row: any) => formatTimeStamp(row.date) },
  {
    selector: (row: any) => row.resolvedLocation
  },
  {
    selector: (row: any) => `${row.accuracy} m`
  },
  {
    cell: (row: any) => (row.isAccurate ? <Check /> : <Cross />)
  },
  {
    cell: (row: any) => `${row.latitude} ${row.longitude}`
  }
];

export const TAG_POSITION_HEADERS = [
  { label: "Time", key: "Time" },
  { label: "Location", key: "Location" },
  { label: "Accuracy", key: "Accuracy" },
  { label: "Accurate", key: "Accurate" },
  { label: "LAT LONG", key: "LatLong" }
];
