import { FC } from "react";
import { Theme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { Modal, ModalProps } from "@vilocnv/allsetra-core";
import {
  reportsWorkingHoursFormInitialValues,
  reportsWorkingHoursFormValidationSchema
} from "app/data/helpers";
import InnerForm from "./children/InnerForm";
import { useTranslation } from "react-i18next";

export type WorkingHoursFilterFormProps = Pick<
  ModalProps,
  "open" | "onClose"
> & {
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  theme: Theme;
  dataLoading?: boolean;
  groups: Array<any>;
  objectTypes: Array<any>;
  objects: Array<any>;
  keys: Array<any>;
  submitting?: boolean;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
};

const WorkingHoursFilterForm: FC<WorkingHoursFilterFormProps> = ({
  open,
  onClose,
  onSubmit,
  theme,
  dataLoading = false,
  groups,
  objects,
  objectTypes,
  keys,
  submitting = false,
  fetchOnDebounce,
  onChange
}) => {
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  return (
    <Formik
      initialValues={reportsWorkingHoursFormInitialValues}
      validationSchema={reportsWorkingHoursFormValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => (
        <Modal
          open={open}
          onClose={() => {
            onClose();
          }}
          title="Filters"
          primaryBtnProps={{
            type: "submit",
            text: t("buttonsText.apply"),
            loading: isSubmitting || submitting,
            // @ts-ignore
            onClick: handleSubmit,
            disabled: !isValid
          }}
          secondaryBtnProps={{
            text: t("buttonsText.cancel"),
            onClick: onClose
          }}
          theme={theme}
          size="customSize"
        >
          <InnerForm
            groups={groups}
            objectTypes={objectTypes}
            objects={objects}
            keys={keys}
            dataLoading={dataLoading}
            translator={t}
            fetchOnDebounce={fetchOnDebounce}
            onChange={onChange}
            values={values}
          />
        </Modal>
      )}
    </Formik>
  );
};

export default WorkingHoursFilterForm;
