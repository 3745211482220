import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import { resetQueriedObjects } from "app/features";

const useSelectSearch = (array: any[], objects: any[]) => {
  const dispatch = useAppDispatch();
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const [selectedLocalObjects, setSelectedLocalObjects] = useState<any[]>([]);

  useEffect(() => {
    const updateSelectedLocalObjects = () => {
      setSelectedLocalObjects((prev: any) => {
        const selectedItems = prev.filter((item: any) =>
          array?.some((value: any) => value === item?.uniqueId)
        );
        const combinedArray = [...selectedItems, ...objects];
        //@ts-ignore
        const uniqueItems = [...new Set(combinedArray)];
        return [...uniqueItems];
      });
    };

    // Call the function with the provided array
    updateSelectedLocalObjects();
  }, [array, objects]);

  useEffect(() => {
    dispatch(resetQueriedObjects());
  }, [drawerSelectedAccountId]);

  return selectedLocalObjects;
};

export default useSelectSearch;
