import { styled, Box } from "@mui/material";

export const DesignContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end"
});

export const SuccessMessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "46px",
  gap: "16px",
  minWidth: "70%",
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    marginTop: "36px"
  }
}));

export const LogoTitle = styled("p")(({ theme }) => ({
  color: "var(--gray-900, #1D1F2B)",
  fontSize: "14px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "132%",

  [theme.breakpoints.down("sm")]: {
    fontSize: "10px"
  }
}));

export const SuccessButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "40px"
}));

export const Title = styled("p")(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: "40px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "115%",
  letterSpacing: "0.4px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "26px"
  }
}));

export const Subtitle = styled("p")(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: "18px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "136%",

  [theme.breakpoints.down("md")]: {
    fontSize: "14px"
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px"
  }
}));
