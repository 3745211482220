import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  AssignedUsersCardContainer,
  CardText,
  StyledAccessTimeIcon
} from "./ObjectAssignedUsersCard.styled";
import { SmallText } from "../ObjectDetailsCard/ObjectDetailsCard.styled";
import { getHoursForAssignedUsers } from "app/data/helpers";

export interface ObjectAssignedUsersCardProps {
  user: any;
}

const ObjectAssignedUsersCard: FC<ObjectAssignedUsersCardProps> = ({
  user
}) => {
  return (
    <AssignedUsersCardContainer>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CardText color={"#212B33"} fontWeight={"500"} fontSize={"16px"}>
          {user?.firstName} {user?.lastName}
        </CardText>
        <CardText color={"#0269EC"} fontWeight={"300"} fontSize={"14px"}>
          {user?.phone ? `+ ${user.phone}` : "-"}
        </CardText>
      </Stack>

      <Stack direction={"row"} alignItems={"center"} spacing={1} mt={2}>
        <StyledAccessTimeIcon />
        <SmallText>{getHoursForAssignedUsers(user)}</SmallText>
      </Stack>
    </AssignedUsersCardContainer>
  );
};

export default ObjectAssignedUsersCard;
