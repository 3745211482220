import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardService } from "app/data/services";

export const getAllRolesThunk = createAsyncThunk(
  "dashboard/getAllRolesThunk",
  async () => {
    try {
      const response = await DashboardService.getAllRoles();

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllCurrenciesThunk = createAsyncThunk(
  "dashboard/getAllCurrenciesThunk",
  async () => {
    try {
      const response = await DashboardService.getAllCurrencies();

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllIconsThunk = createAsyncThunk(
  "dashboard/getAllIconsThunk",
  async () => {
    try {
      const response = await DashboardService.getAllIcons();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllCountriesThunk = createAsyncThunk(
  "dashboard/getAllCountriesThunk",
  async () => {
    try {
      const response = await DashboardService.getAllCountries();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllPaymentMethodsThunk = createAsyncThunk(
  "dashboard/getAllPaymentMethodsThunk",
  async () => {
    try {
      const response = await DashboardService.getAllPaymentMethods();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getGooglePlacesThunk = createAsyncThunk(
  "dashboard/getGooglePlacesThunk",
  async (location: string) => {
    try {
      const response = await DashboardService.getGooglePlaces(location);

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getIconsByCategoryThunk = createAsyncThunk(
  "dashboard/getIconsByCategoryThunk",
  async (iconCategory: 0 | 1) => {
    try {
      const response = await DashboardService.getIconsByCategory(iconCategory);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getInitialAccountThunk = createAsyncThunk(
  "dashboard/getInitialAccountThunk",
  async () => {
    try {
      const response = await DashboardService.getInitialAccount();

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const searchMinimalAccountsThunk = createAsyncThunk(
  "dashboard/searchMinimalAccountsThunk",
  async (data: any) => {
    try {
      const response = await DashboardService.searchMinimalAccounts(data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
