import { createAsyncThunk } from "@reduxjs/toolkit";
import { WhiteLabels } from "app/data/services";
import { toast, types } from "@vilocnv/allsetra-core";

export const getWhiteLabelConfigurationThunk = createAsyncThunk(
  "whiteLabels/getWhiteLabelConfigurationThunk",
  async (id: string) => {
    try {
      const response = await WhiteLabels.getWhiteLabelConfiguration(id);

      return response.status === 200 ? response.data : null;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
