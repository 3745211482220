import { FC, useEffect } from "react";
import { Stack, useTheme } from "@mui/material";
import { FormikInputField, FormikSelectField } from "@vilocnv/allsetra-core";
import { EXPORT_TYPE_OPTIONS } from "app/data/constants";
import { useAppSelector } from "hooks";
import { selectDashboardUserEmail } from "app/data/selectors";

interface Props {
  translator: any;
  reportFrequency?: Array<any>;
  reportExportTypes?: Array<any>;
  reportType?: number;
  reportExportTypeValue?: number;
  setFieldValue: (field: string, value: any) => void;
}

const InnerForm: FC<Props> = ({ translator, setFieldValue }) => {
  const dashboardUserEmail = useAppSelector(selectDashboardUserEmail);
  const theme = useTheme();

  useEffect(() => {
    if (dashboardUserEmail) {
      setFieldValue("emailAddress", dashboardUserEmail);
    }
  }, []);

  return (
    <Stack spacing={2}>
      <FormikInputField
        theme={theme}
        name="emailAddress"
        label={translator("email.label", {
          ns: "formFieldsTranslation"
        })}
        fullWidth
        required
      />
      <FormikSelectField
        theme={theme}
        label={translator("selectReportFormat.label", {
          ns: "formFieldsTranslation"
        })}
        name="exportType"
        options={EXPORT_TYPE_OPTIONS(translator) ?? []}
        optionLabelKey="name"
        optionValueKey="id"
        required
        fullWidth
      />
    </Stack>
  );
};

export default InnerForm;
