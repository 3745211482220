import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const selectObjectTypesReducerLoading = (state: RootState) =>
  state.rootReducer.objectTypesReducer.loading;

export const selectAllObjectTypes = (state: RootState) =>
  state.rootReducer.objectTypesReducer.objectTypes;

export const selectObjectTypesState = createSelector(
  selectObjectTypesReducerLoading,
  selectAllObjectTypes,
  (loading, objectTypes) => ({ loading, objectTypes })
);
