import { FC } from "react";
import VerificationInput from "react-verification-input";
import { VerificationInputWrapper } from "./VerificationCodeInput.styled";

export interface VerificationCodeInputProps {
  onChange?: (value: any) => void;
  onComplete?: (value: string) => void;
  value?: string;
}

const VerificationCodeInput: FC<VerificationCodeInputProps> = ({
  onChange,
  onComplete,
  value
}) => {
  const handleVerificationInputChange = (val: string) => {
    if (onChange) {
      onChange(val);
    }
  };

  const handleOnComplete = (val: string) => {
    if (onComplete) {
      onComplete(val);
    }
  };

  return (
    <VerificationInputWrapper>
      <VerificationInput
        value={value}
        onChange={handleVerificationInputChange}
        autoFocus
        placeholder=""
        length={8}
        validChars="A-Za-z0-9"
        onComplete={handleOnComplete}
        classNames={{
          container: "container",
          character: "character"
        }}
      />
    </VerificationInputWrapper>
  );
};

export default VerificationCodeInput;
