import React, { FC, ReactNode } from "react";
import { GoogleMap as ReactGoogleMap } from "@react-google-maps/api";
import { PageLoader } from "@vilocnv/allsetra-core";
import { MapContainer, MapWrapper, TilesWrapperBluer } from "../../Map.styled";
import { useMapState } from "./MapStateContext";
import { googleMapStyles } from "app/data/constants";
import {
  DashboardMapLoaderWrapper,
  DashboardMapWrapper
} from "components/maps/DashboardMap/DashboardMap.styled";
import useDashboardMap from "components/maps/DashboardMap/useDashboardMap";
import { CircularProgress } from "@mui/material";

const GoogleMap: FC<{ children?: ReactNode }> = ({ children }) => {
  const {
    showSatelliteMode,
    zoom,
    height,
    isLoaded,
    loadError,
    centerCoords,
    onMapLoad,
    isLoadingTiles,
    onTilesLoaded,
    isLoading
  } = useMapState();

  if (!isLoaded) return <PageLoader />;

  if (loadError) return <div>Map cannot be loaded right now, sorry.</div>;

  return (
    <MapWrapper>
      {isLoading && (
        <DashboardMapLoaderWrapper>
          <CircularProgress sx={{ color: "#1976d2" }} />
        </DashboardMapLoaderWrapper>
      )}
      <DashboardMapWrapper allObjectsLoading={isLoading}>
        <TilesWrapperBluer allObjectsLoading={isLoadingTiles}>
          <MapContainer height={height}>
            <ReactGoogleMap
              mapTypeId={
                showSatelliteMode
                  ? google.maps.MapTypeId.HYBRID
                  : google.maps.MapTypeId.ROADMAP
              }
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={centerCoords}
              zoom={zoom}
              onLoad={onMapLoad}
              options={{ styles: googleMapStyles }}
              onTilesLoaded={onTilesLoaded}
            >
              {children}
            </ReactGoogleMap>
          </MapContainer>
        </TilesWrapperBluer>
      </DashboardMapWrapper>
    </MapWrapper>
  );
};

export default React.memo(GoogleMap);
