import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Stack, useTheme } from "@mui/material";
import { Form, useFormikContext } from "formik";
import {
  Modal,
  FormikInputField,
  FormikPlacesSearchField
} from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { getGooglePlacesThunk } from "app/features";
import { selectDashboardGooglePlacesState } from "app/data/selectors";
import { IAddPointOfInterest, IPointOfInterest } from "app/data/types";

interface Props {
  open: boolean;
  onClose: () => void;
  isInEdit: boolean;
  editFormData: IPointOfInterest | null;
}

const InnerForm: FC<Props> = ({ open, onClose, isInEdit, editFormData }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { googlePredictatedPlacesLoading, googlePredictatedPlaces } =
    useAppSelector(selectDashboardGooglePlacesState);

  const {
    values,
    isSubmitting,
    dirty,
    isValid,
    setFieldValue,
    validateForm,
    handleSubmit
  } = useFormikContext<IAddPointOfInterest>();

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const debounceFetchPlaces = (searchedText: any) => {
    dispatch(getGooglePlacesThunk(searchedText));
  };

  useEffect(() => {
    validateForm();
  }, [values?.address]);

  return (
    <Form>
      <Modal
        open={open}
        onClose={onClose}
        title={
          isInEdit ? t("addWorkAddress.editTitle") : t("addWorkAddress.title")
        }
        primaryBtnProps={{
          type: "submit",
          text: t ? t("buttonsText.submit") : "Submit",
          loading: isSubmitting,
          disabled: isInEdit ? (!dirty ? isValid : !isValid) : !isValid,
          // @ts-ignore
          onClick: handleSubmit
        }}
        secondaryBtnProps={{
          text: t ? t("buttonsText.cancel") : "Cancel",
          onClick: onClose
        }}
        theme={theme}
      >
        <Stack spacing={2}>
          <FormikInputField
            theme={theme}
            label={t("name.label", {
              ns: "formFieldsTranslation"
            })}
            placeholder={t("name.placeholder", {
              ns: "formFieldsTranslation"
            })}
            name="name"
            required
          />
          <FormikPlacesSearchField
            //@ts-ignore
            placeholder={t("typography.searchLocations")}
            name="address"
            label={t("address.label", {
              ns: "formFieldsTranslation"
            })}
            debounceFetchPlaces={debounceFetchPlaces}
            googlePredictatedPlacesLoading={googlePredictatedPlacesLoading}
            googlePredictatedPlaces={googlePredictatedPlaces}
            initialSelectedPlace={isInEdit ? editFormData?.address : ""}
            onChange={(_, value) => {
              setFieldValue("lat", value.lat ?? 0);
              setFieldValue("long", value.long ?? 0);
              validateForm();
            }}
            loadingText={t("common.loading")}
          />
        </Stack>
      </Modal>
    </Form>
  );
};

export default InnerForm;
