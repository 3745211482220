import axiosInstance from "app/axiosInstance";

class UtilityService {
  static getAddressFromLatLng = async (latitude: number, longitude: number) => {
    return await axiosInstance.get(
      `/utility/reversegeocode?latitude=${latitude}&longitude=${longitude}`
    );
  };
}

export default UtilityService;
